import { apiSlice } from "../api/apiSlice";

export const workSessionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWorkSession: builder.query({
      query: () => "/work-sessions/active",
      providesTags: ["WORK_SESS"],
    }),
    startWorkSession: builder.mutation({
      query: (session) => ({
        url: "/work-sessions",
        method: "POST",
        body: session,
      }),
      invalidatesTags: ["WORK_SESS"],
    }),
    endWorkSession: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/work-sessions/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "WORK_SESS" },
        { type: "INCOME", id: `${result.projectId}-LIST` },
      ],
    }),
  }),
});

export const {
  useGetWorkSessionQuery,
  useStartWorkSessionMutation,
  useEndWorkSessionMutation,
} = workSessionSlice;
