import {
    Box,
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import ChartTablePanelSkeleton from "../../mui/components/project_details/ChartTablePanelSkeleton";
import {useEffect, useMemo, useState} from "react";
import moment from "moment";
import Chart from "react-apexcharts";
import TimeLogEntryTableRow from "./TimeLogEntryTableRow";
import LogTimeDialog from "./LogTimeDialog";
import {useTheme} from "@emotion/react";
import {groupByDate, sortByField} from "../../utils";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {useOutletContext} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import {useGetProjectTimeLogQuery} from "./timelogSlice";

export default function ProjectTimeLogPanel() {
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [project] = useOutletContext();

    const theme = useTheme();
    const {t} = useTranslation(["timelog", "translation", "error"]);

    const {data: timeLogs = [], isLoading, isError, error} = useGetProjectTimeLogQuery(project.id);

    let plottableData = useMemo(() => {
        let temp = timeLogs.map(s => {
            return {date: moment(s.spentAt).locale(i18n.resolvedLanguage).format("DD-MMM-YYYY"), value: s.duration}
        });
        return groupByDate(temp).sort().reverse();
    }, [timeLogs])

    useEffect(() => {
        if (isError) {
            enqueueSnackbar(t(`error:${error.data.message}`), {variant: "error"});
        }
    }, [isError])

    let chart = {
        options: {
            noData: {
                text: t("time.nodata"),
                style: {
                    fontSize: "18px",
                    fontFamily: "Roboto"
                }
            },
            chart: {
                id: "timelog-bar-chart",
            },
            xaxis: {
                categories: plottableData.length === 0 ? [] : plottableData.map(e => e.date),
            },
            tooltip: {
                y: {
                    formatter: (value, opts) => {
                        return `${value} ${t('translation:hours')}`
                    }
                },
            },
            // colors:[theme.palette.primary.light, theme.palette.secondary.dark],
            theme: {
                palette: 'palette1', // upto palette10
                mode: theme.palette.mode
            }

        },
        series: [{
            name: t("chart.series.name"),
            data: plottableData.length === 0 ? [] : plottableData.map(e => (e.value.toFixed(3))),
        }],
    }

    const onCloseAddDialog = () => {
        setAddDialogOpen(false);
    }

    const actual = <Grid container maxWidth={"xl"} spacing={4}>
        <Grid item xs={12}>
            <Box maxWidth={"lg"}>
                <Chart type={"bar"} height={"300"} options={chart.options} series={chart.series}/>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("column.spent_on")}</TableCell>
                            <TableCell>{t("column.duration")}</TableCell>
                            <TableCell>{t("column.task")}</TableCell>
                            <TableCell align={"center"}>
                                <Button onClick={e => setAddDialogOpen(true)}>{t("button.log_time")}</Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortByField("spentAt", timeLogs, "desc").map(entry => <TimeLogEntryTableRow entry={entry}
                                                                                                     projectId={project.id}
                                                                                                     key={entry.id}/>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <LogTimeDialog open={addDialogOpen} onClose={onCloseAddDialog} projectId={project.id}/>
        </Grid>
    </Grid>
    return isLoading ? <ChartTablePanelSkeleton/> : actual;
}