import {createSlice} from "@reduxjs/toolkit";
import {Buffer} from "buffer";

import {forgotPassword, login, register, resetPassword, verifyEmail} from "./authActions";
import {initUserGA} from "../../utils/ga-utils";
// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null;

const userInfo = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

const initialState = {
    loading: false,
    userInfo,
    userToken,
    error: null,
    success: false,
    isSuccess: {},
    isError: {},
    pending: true,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('userToken') // delete token from storage
            localStorage.removeItem('user') // delete token from storage
            state.loading = false
            state.userInfo = null
            state.userToken = null
            state.error = null
            state.success = false
        },
        tokenReceived: (state, token) => {
            state.userToken = token;
            localStorage.setItem('userToken', token);
        },
        userInfoReceived: (state, user) => {
            state.userInfo = user;
            localStorage.setItem("user", JSON.stringify(user));
        }
    },
    extraReducers: {
        // login user
        [login.pending]: (state) => {
            state.loading = true
            state.error = null
            state.success = false
        },
        [login.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.error = null
            authSlice.caseReducers.tokenReceived(state, payload.token)
            authSlice.caseReducers.userInfoReceived(state, payload.data)
            state.success = true
            if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID && payload.data && payload.data.id) {
                initUserGA(payload.data.id);
            }
        },
        [login.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
            state.success = false
        },
        // register user
        [register.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [register.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.success = true // registration successful
            state.error = null
            authSlice.caseReducers.tokenReceived(state, payload.token)
            authSlice.caseReducers.userInfoReceived(state, payload.data)
            if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID && payload.data && payload.data.id) {
                initUserGA(payload.data.id);
            }
        },
        [register.rejected]: (state, {payload}) => {
            state.loading = false
            state.error = payload
            state.success = false
        },
        [verifyEmail.pending]: (state) => {
            state.loading = true
            state.error = null
            state.isError['verifyEmail'] = null
            state.isSuccess['verifyEmail'] = null
            state.pending = false
        },
        [verifyEmail.fulfilled]: (state) => {
            state.loading = false
            state.error = null
            state.isError['verifyEmail'] = false
            state.isSuccess['verifyEmail'] = true
            state.pending = false
        },
        [verifyEmail.rejected]: (state, {payload}) => {
            state.loading = false
            state.isError['verifyEmail'] = true
            state.isSuccess['verifyEmail'] = false
            state.error = payload
            state.pending = false
        },
        [forgotPassword.pending]: (state) => {
            state.loading = true
            state.isError['forgotPassword'] = null
            state.isSuccess['forgotPassword'] = null
            state.pending = false
        },
        [forgotPassword.fulfilled]: (state) => {
            state.loading = false
            state.error = null
            state.isSuccess['forgotPassword'] = true
            state.pending = false
        },
        [forgotPassword.rejected]: (state, {payload}) => {
            state.loading = false
            state.isSuccess['forgotPassword'] = false
            state.error = payload
            state.pending = false
        },
        [resetPassword.pending]: (state) => {
            state.loading = true
            state.isError['resetPassword'] = null
            state.isSuccess['resetPassword'] = null
            state.pending = false
        },
        [resetPassword.fulfilled]: (state) => {
            state.loading = false
            state.error = null
            state.isSuccess['resetPassword'] = true
            state.pending = false
        },
        [resetPassword.rejected]: (state, {payload}) => {
            state.loading = false
            state.isSuccess['resetPassword'] = false
            state.error = payload
            state.pending = false
        }
    }
});

const isValid = (token) => {
    if (token === undefined || token === null || token.indexOf(".") <= 0) {
        return false;
    }

    try {
        let payload = JSON.parse(
            Buffer.from(token.split(".")[1], "base64").toString()
        );
        let expirationDate = payload.exp;

        if (expirationDate * 1000 - Date.now() < 1000) {
            return false;
        }
    } catch (ex) {
        // failed to parse token
        return false;
    }
    return true;
}

export const {logout, tokenReceived, userInfoReceived} = authSlice.actions;
export const selectCurrentUser = (state) => state.auth.userInfo;
export const selectIsAuthenticated = (state) => state.auth.userToken !== undefined && isValid(state.auth.userToken);

export default authSlice.reducer