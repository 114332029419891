import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";

import moment from "moment";
import LocaleAwareDatePicker from "../LocaleAwareDatePicker";
import { useTranslation } from "react-i18next";
import ClientAutoCompleteMenu from "../../../features/clients/ClientAutoCompleteMenu";
import { useEditProjectMutation } from "../../../features/projects/projectSlice";
import { enqueueSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";

export default function EditProjectDialog({
  project,
  statuses,
  open,
  onSubmit,
  onClose,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation(["project", "translation"]);
  const [status, setStatus] = useState(project.status.id);

  const statusSelectItems = statuses.map((status) => (
    <MenuItem key={status.id} value={status.id}>
      {t("project.status." + status.statusValue, status.label)}
    </MenuItem>
  ));

  const [editProject, { isLoading, isError, isSuccess, error }] =
    useEditProjectMutation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: project.name,
      description: project.description,
      status: project.status,
      priceRequested: project.priceRequested,
      estimatedHours: project.estimatedHours,
      deadline: moment(project.deadline),
      client: project.client,
    },
  });

  const onFormSubmit = async (data) => {
    await editProject({
      id: project.id,
      project: {
        name: data.name.length > 0 ? data.name : null,
        description: data.description.length > 0 ? data.description : null,
        status: status,
        priceRequested: data.priceRequested,
        estimatedHours: data.estimatedHours,
        deadline: data.deadline.utc(true),
        client: data.client? data.client.id : null,
        currency: project.currency
      },
    })
      .unwrap()
      .then((result) => {
        reset();
        onSubmit();
      })
      .catch((err) => {
        if (err.data.details && err.data.details.length > 0) {
          enqueueSnackbar(t(`error:${err.data.message}`), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      });
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"lg"}
      fullScreen={fullScreen}
      fullWidth
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <DialogTitle>{t("page.edit.title")}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} maxWidth={"lg"} sx={{ pt: 1 }}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("error:field.error.required"),
                },
              }}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <TextField
                  label={t("fields.name.label")}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  required
                  fullWidth
                  error={!!errors["name"]}
                  helperText={errors["name"] ? errors["name"].message : ""}
                />
              )}
            />

            <Controller
              name={"description"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("error:field.error.required"),
                },
              }}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <TextField
                  label={t("fields.description.label")}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  required
                  fullWidth
                  multiline
                  minRows={3}
                  error={!!errors["description"]}
                  helperText={
                    errors["description"] ? errors["description"].message : ""
                  }
                />
              )}
            />

            <Controller
              name="client"
              control={control}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <ClientAutoCompleteMenu
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />

            <Controller
              name={"deadline"}
              control={control}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <LocaleAwareDatePicker
                  label={t("fields.deadline.label")}
                  value={value}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />

            <FormControl
              fullWidth={fullScreen}
              sx={!fullScreen ? { width: 500 } : {}}
            >
              <InputLabel id="status-select-label">
                {t("fields.status.label")}
              </InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={status}
                label={t("fields.status.label")}
                onChange={(e) => setStatus(e.target.value)}
              >
                {statusSelectItems}
              </Select>
            </FormControl>


              <Controller
                name="estimatedHours"
                control={control}
                valueAsNumber={true}
                rules={{
                  required: {
                    value: true,
                    message: t("error:field.error.required"),
                  },
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    message: t("error:field.error.nan"),
                  },
                }}
                render={({ field: { name, value, onChange, onBlur } }) => (
                    <Tooltip title={t("fields.time.tooltip")}>
                  <TextField
                    inputProps={{ inputMode: "numeric" }}
                    label={t("fields.time.label")}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                    fullWidth
                    error={!!errors["estimatedHours"]}
                    helperText={
                      errors["estimatedHours"]
                        ? errors["estimatedHours"].message
                        : ""
                    }
                  />
                  </Tooltip>
                )}
              />


              <Controller
                name="priceRequested"
                control={control}
                valueAsNumber={true}
                rules={{
                  required: {
                    value: true,
                    message: t("error:field.error.required"),
                  },
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    message: t("error:field.error.nan"),
                  },
                }}
                render={({ field: { name, value, onChange, onBlur } }) => (
                    <Tooltip title={t("fields.price.tooltip")}>
                  <TextField
                    fullWidth
                    label={t("fields.price.label")}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                    InputProps={{ inputMode: "numeric" }}
                    error={!!errors["priceRequested"]}
                    helperText={
                      errors["priceRequested"]
                        ? errors["priceRequested"].message
                        : ""
                    }
                  />
                    </Tooltip>
                )}
              />

          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("translation:button.cancel")}</Button>
          <LoadingButton loading={isLoading} type="submit">
            {t("translation:button.save")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
