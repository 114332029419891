import {
  Box,
  Divider,
  Fab,
  Grid,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { Add } from "@mui/icons-material";
import Copyright from "../components/Copyright";
import ProjectListPanelByStatus from "../components/ProjectListPanelByStatus";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetProjectsQuery } from "../../features/projects/projectSlice";
import { enqueueSnackbar } from "notistack";

export default function Projects() {
  const {
    data: projects = [],
    isLoading,
    isError,
    error,
  } = useGetProjectsQuery();
  const { t } = useTranslation("project");

  let completedProjects = [];
  let waitingProjects = [];
  let activeProjects = [];

  projects.forEach((project) => {
    switch (project.status.statusValue) {
      case "COMPLETED":
        completedProjects.push(project);
        break;
      case "ON_HOLD":
        waitingProjects.push(project);
        break;
      default:
        activeProjects.push(project);
        break;
    }
  });

  const skeleton = (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Divider textAlign={"left"}>Active</Divider>
        <Skeleton variant={"text"} width={"100%"} />
        <Skeleton variant={"text"} width={"100%"} />
        <Skeleton variant={"text"} width={"100%"} />
        <Skeleton variant={"text"} width={"100%"} />
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign={"left"}>Completed</Divider>
        <Skeleton variant={"text"} width={"100%"} />
        <Skeleton variant={"text"} width={"100%"} />
        <Skeleton variant={"text"} width={"100%"} />
        <Skeleton variant={"text"} width={"100%"} />
      </Grid>
    </Grid>
  );

  if (isLoading) {
    return skeleton;
  }

  if (isError) {
    enqueueSnackbar(t("error:" + error.message, { variant: "error" }));
  }

  if (!projects || projects.length === 0) {
    return (
      <Box>
        <Typography variant={"h6"}>
            {t("project:no.projects.msg")}
          <Link
            to={"/projects/add"}
            color={"primary"}
            underline={"hover"}
            component={NavLink}
            sx={{ml: 1}}
          >
              {t("project:no.projects.cta")}
          </Link>
        </Typography>{" "}
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Typography variant="h4">{t("page.projects.title")}</Typography>
      </Box>

      <Box sx={{ mt: 5, borderBottom: 1, borderColor: "divider" }}>
        <Grid container spacing={6}>
          <ProjectListPanelByStatus
            title={t("project.section.active")}
            projects={activeProjects}
          />
          <ProjectListPanelByStatus
            title={t("project.section.waiting")}
            projects={waitingProjects}
          />
          <ProjectListPanelByStatus
            title={t("project.section.completed")}
            projects={completedProjects}
          />
        </Grid>
      </Box>
      <Fab
        sx={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
        aria-label={"add project"}
        color={"primary"}
        to={"/projects/add"}
        component={NavLink}
      >
        {<Add />}
      </Fab>
      <Copyright sx={{ m: 5 }} />
    </>
  );
}
