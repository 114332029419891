import { apiSlice } from "../api/apiSlice";

export const projectStatusSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectStatuses: builder.query({
      query: () => "/project-statuses",
      providesTags: [{ type: "PROJECT_STATUS", id: "LIST" }],
    }),
    addProjectStatus: builder.mutation({
      query: (status) => ({
        url: "/project-statuses",
        method: "POST",
        body: status,
      }),
      invalidatesTags: ["PROJECT_STATUS"],
    }),
    updateProjectStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/project-statuses/${id}`,
        method: "PUT",
        body: status,
      }),
      invalidatesTags: ["PROJECT_STATUS"],
    }),
    deleteProjectStatus: builder.mutation({
      query: (id) => ({
        url: `/project-statuses/${id}`,
        method: "DELETE",
        body: "",
      }),
      invalidatesTags: ["PROJECT_STATUS"],
    }),
  }),
});

export const {
  useGetProjectStatusesQuery,
  useAddProjectStatusMutation,
  useUpdateProjectStatusMutation,
  useDeleteProjectStatusMutation,
} = projectStatusSlice;
