import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import {
  joinBetaWishList,
  selectCurrentJoinedStatus,
} from "../../features/mailing-list/mailingSlice";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

export default function JoinMailingList({ style }) {
  const dispatch = useDispatch();
  const joined = useSelector(selectCurrentJoinedStatus);
  const { isLoading } = useSelector((state) => state.mailingList);

  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const joinWaitlist = async (data) => {
    await dispatch(joinBetaWishList(data.email))
      .unwrap()
      .then(({ data }) => {
        reset();
      })
      .catch((error) => {
        enqueueSnackbar(t("msg.beta.error"), {
          variant: "warning",
          preventDuplicate: true,
        });
      });
  };

  let onWaitList = (
    <>
      <Typography variant={"h6"}>{t("msg.thankyou")}</Typography>
      <Typography variant={"subtitle2"}>
        {t("msg.beta.waitlist_added")}
      </Typography>
    </>
  );

  const notOnWaitlist = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          component={"form"}
          onSubmit={handleSubmit(joinWaitlist)}
          noValidate
        >
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("error:field.error.required"),
              },
              validate: (value) =>
                !!value.trim() || t("error:field.error.required"),
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/,
                message: t("error:field.error.email"),
              },
            }}
            render={({ field: { name, value, onChange, onBlur } }) => (
              <TextField
                required
                fullWidth
                label={t("input.email")}
                name={name}
                autoComplete="email"
                autoFocus
                type={"email"}
                size={"small"}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors["email"]}
                helperText={errors["email"] ? errors["email"].message : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LoadingButton
                        loading={isLoading}
                        type={"submit"}
                        disabled={!isValid}
                        size={"small"}
                      >
                          {t("button.list.add")}
                      </LoadingButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </Grid>
    </Grid>
  );
  return <Box sx={style}>{joined ? onWaitList : notOnWaitlist}</Box>;
}
