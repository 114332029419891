import { apiSlice } from "../api/apiSlice";

export const supplierSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSuppliers: builder.query({
      query: () => "/suppliers",
      providesTags: [{ type: "SUPPLIER" }],
    }),
    getPrivateSuppliers: builder.query({
      query: () => "/suppliers?owned=true",
      providesTags: [{ type: "OWNED_SUPPLIER" }],
    }),
    getSupplierDetails: builder.query({
      query: (id) => `/suppliers/${id}`,
    }),
    addSupplier: builder.mutation({
      query: (payload) => ({
        url: "/suppliers",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [
        { type: "SUPPLIER" },
        { type: "OWNED_SUPPLIER" },
        { type: "ACTIVITY", id: "USER" },
      ],
    }),
    deleteSupplier: builder.mutation({
      query: (id) => ({
        url: `/suppliers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "SUPPLIER" },
        { type: "OWNED_SUPPLIER" },
        { type: "ACTIVITY", id: "USER" },
      ],
    }),
  }),
});

export const {
  useGetAllSuppliersQuery,
  useGetPrivateSuppliersQuery,
  useGetSupplierDetailsQuery,
  useAddSupplierMutation,
  useDeleteSupplierMutation,
} = supplierSlice;
