import { useTranslation } from "react-i18next";
import { useGetClientsQuery } from "./clientSlice";
import { useState } from "react";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  createFilterOptions,
} from "@mui/material";
import AddClientForm from "./AddClientForm";

export default function ClientAutoCompleteMenu({ initialValue, onChange }) {
  const { t } = useTranslation();
  const { data: clients = [], isLoading } = useGetClientsQuery("getClients");
  const noClient = {
    id: null,
    name: ""
  }

  const [value, setValue] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [initialName, setInitialName] = useState("");
  const filter = createFilterOptions();

  return (
    <>
      <Autocomplete
        id="client-selector"
        fullWidth
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        options={[...clients, noClient]}
        loading={isLoading}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {});
          } else if (newValue && newValue.inputValue) {
            setInitialName(newValue.inputValue);
            setShowDialog(true);
          } else {
            onChange(newValue);
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `${t("client:autocomplete.add.label")} "${
                params.inputValue
              }"`,
            });
          }

          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("client.menu.field.name")}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {showDialog ? (
        <AddClientForm
          initialName={initialName}
          open={true}
          handleClose={(client) => {
            setShowDialog(false);
            setValue(client);
            onChange(client);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
