import {
    Box, Button,
    Card,
    CardContent,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {enqueueSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUser, userInfoReceived} from "../../../features/auth/authSlice";
import {useDeleteAccountMutation, useUpdateProfileMutation} from "../../../features/auth/authApiSlice";
import {useConfirm} from "material-ui-confirm";
import {useNavigate} from "react-router-dom";

export default function AccountGeneralPanel() {
    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [email, setEmail] = useState(user.email);
    const [companyName, setCompanyName] = useState(user.companyName)
    const [companyCif, setCompanyCif] = useState(user.companyCif)
    const [preferredLanguage, setPreferredLanguage] = useState(user.preferredLanguage);
    const {t} = useTranslation(['account', 'translation', 'tooltips']);
    const [updateProfile, {isLoading}] = useUpdateProfileMutation();
    const [deleteAccount, {isLoading: isDeleteLoading}] = useDeleteAccountMutation();
    const confirm = useConfirm();
    const navigate = useNavigate();

    const handleDelete = () => {
        confirm({
            title: t("account:close.dialog.title"),
            description: t("account:close.dialog.body"),
            confirmationText: t("translation:button.yes"),
            cancellationText: t("translation:button.no"),
        }).then(async () => {
            await deleteAccount().unwrap()
                .then(d => {navigate("/logout")})
        })
    }

    const isDirty = useMemo(() => {
        return user.firstName === firstName && user.lastName === lastName && user.companyName === companyName && user.companyCif === companyCif && user.preferredLanguage === preferredLanguage;
    }, [user, firstName, lastName, preferredLanguage, companyCif, companyName]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {firstName, lastName, companyName, companyCif, preferredLanguage};
        try {
            let result = await updateProfile({id: user.id, payload: payload})
            i18n.changeLanguage(preferredLanguage.toLowerCase());
            dispatch(userInfoReceived(result.data));
            enqueueSnackbar(t("msg.notif.profile.updated"), {variant: "success"});
        } catch (err) {
            enqueueSnackbar(t("error.profile.update"), {variant: "error"});
        }
    };
    return (
        <Container>
            <Card>
                <CardContent component={"div"}>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit}
                        sx={{mt: 3}}
                    >
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={4}>
                                <Typography component={"h1"} variant={"h6"}>
                                    {t("section.profile.title")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Stack spacing={2}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="firstName"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label={t("translation:input.first_name")}
                                        autoFocus
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />

                                    <TextField
                                        autoComplete="family-name"
                                        name="lastName"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label={t("translation:input.last_name")}
                                        autoFocus
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />

                                    <Tooltip title={t("tooltips:email.readonly")}>
                                        <TextField
                                            required
                                            fullWidth
                                            disabled
                                            id="email"
                                            label={t("translation:input.email")}
                                            name="email"
                                            autoComplete="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Tooltip>

                                    <Tooltip title={t("input.pref.language", "Preferred Language")}>
                                        <FormControl fullWidth>
                                            <InputLabel id="language-select-label">
                                                {t("input.pref.language", "Preferred Language")}
                                            </InputLabel>
                                            <Select
                                                labelId="language-select-label"
                                                id="language-select"
                                                value={preferredLanguage}
                                                label={t("input.pref.language", "Preferred Language")}
                                                onChange={(e) => setPreferredLanguage(e.target.value)}
                                            >
                                                <MenuItem value={"RO"}>Romana</MenuItem>
                                                <MenuItem value={"EN"}>English</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Tooltip>

                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Typography component={"h1"} variant={"h6"}>
                                    {t("section.company.title")}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Stack spacing={2}>
                                    <TextField
                                        autoComplete="company-name"
                                        name="companyName"
                                        fullWidth
                                        id="companyName"
                                        label={t("input.company_name")}
                                        autoFocus
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                    />

                                    <TextField
                                        autoComplete="company-cif"
                                        name="companyCif"
                                        fullWidth
                                        id="companyCif"
                                        label={t("input.company_id")}
                                        autoFocus
                                        value={companyCif}
                                        onChange={(e) => setCompanyCif(e.target.value)}
                                    />

                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Box textAlign={"right"}>
                                        <LoadingButton
                                            sx={{mt: 3, mb: 2, mr: 1}}
                                            variant={"contained"}
                                            color={"error"}
                                            loading={isDeleteLoading}
                                            loadingPosition="center"
                                        onClick={handleDelete}>
                                            {t("translation:button.close.account")}
                                        </LoadingButton>

                                        <LoadingButton
                                            disabled={isDirty}
                                            type={"submit"}
                                            loading={isLoading}
                                            loadingPosition="center"
                                            variant="contained"
                                            sx={{mt: 3, mb: 2}}
                                        >
                                            {t("translation:button.save")}
                                        </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    );
}
