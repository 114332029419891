import {
  Button,
  Card,
  CardActions,
  CardContent,
  Link,
  List,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DividedListItem from "../../mui/components/DividedListItem";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  useDeleteContactMutation,
  useUpdateContactMutation,
} from "./clientSlice";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";

export default function ContactCard({ contact }) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(contact.name);
  const [email, setEmail] = useState(contact.email);
  const [phoneNo, setPhoneNo] = useState(contact.phoneNo);

  const [deleteContact, isLoading, isError] =
    useDeleteContactMutation("deleteContact");
  const [updateContact] = useUpdateContactMutation();

  if (isError) {
    enqueueSnackbar("error:contact.delete.failed", { variant: "error" });
  }

  const confirm = useConfirm();

  const persist = async () => {
    try {
      const result = await updateContact({
        clientId: contact.clientId,
        contactId: contact.id,
        payload: { name, email, phoneNo },
      });
      setEditMode(false);
    } catch (error) {
      enqueueSnackbar(t("error:contact.update.failed", { variant: "error" }));
    }
  };

  const handleDelete = () => {
    confirm({
      title: t("client:delete.contact.title"),
      description: t("client:delete.contact.body", { name: contact.name }),
      confirmationText: t("client:delete.yes"),
      cancellationText: t("client:delete.no"),
    })
      .then(async (data) => {
        await deleteContact({
          clientId: contact.clientId,
          contactId: contact.id,
        });
      })
      .catch((err) => {});
  };

  let cardContent = <></>;
  if (editMode) {
    cardContent = (
      <CardContent>
        <Stack spacing={2}>
          <TextField
            size="small"
            variant="filled"
            label={t("client:label.name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            size="small"
            variant="filled"
            label={t("client:label.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            size="small"
            variant="filled"
            label={t("client:label.phone")}
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
          />
        </Stack>
      </CardContent>
    );
  } else {
    cardContent = (
      <CardContent>
        <Typography variant="h4" component="div">
          {contact.name}
        </Typography>
        <List>
          <DividedListItem
            primary={t("client:label.email")}
            secondary={
              <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
            }
          />
          <DividedListItem
            primary={t("client:label.phone")}
            secondary={
              <Link href={`tel:${contact.phoneNo}`}>{contact.phoneNo}</Link>
            }
          />
        </List>
      </CardContent>
    );
  }

  return (
    <Card sx={{ borderRadius: 6 }}>
      {cardContent}
      <CardActions sx={{ ml: 1 }}>
        {editMode ? (
          <>
            <Button size="small" onClick={(e) => setEditMode(false)}>
              {t("button.cancel")}
            </Button>
            <Button size="small" onClick={persist}>
              {t("button.save")}
            </Button>
          </>
        ) : (
          <>
            <Button size="small" onClick={(e) => setEditMode(true)}>
              {t("button.edit.verb")}
            </Button>
            <Button size="small" onClick={handleDelete}>
              {t("button.delete.verb")}
            </Button>
          </>
        )}
      </CardActions>
    </Card>
  );
}
