import {Grid} from "@mui/material";
import PasswordField from "./PasswordField";
import React, {useState} from "react";
import ReactPasswordChecklist from "react-password-checklist";
import {useTranslation} from "react-i18next";

export default function SetPassword({onValidPassword}) {
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <PasswordField
                    id={"password"}
                    name={"password"}
                    label={t('input.password')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <PasswordField
                    id={"password2"}
                    name={"password2"}
                    label={t('input.password_confirm')}
                    value={passwordAgain}
                    onChange={(e) => setPasswordAgain(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <ReactPasswordChecklist
                    rules={["minLength", "specialChar", "number", "capital", "match"]}
                    minLength={8}
                    value={password}
                    valueAgain={passwordAgain}
                    onChange={(isValid) => {
                        onValidPassword(password, isValid);
                    }}
                    messages={{
                        minLength: t("msg.rules.minLength"),
                        specialChar: t("msg.rules.specialChar"),
                        number: t("msg.rules.number"),
                        capital: t('msg.rules.capital'),
                        match: t('msg.rules.match')
                    }}
                />
            </Grid>
        </React.Fragment>
    );
}
