import { matchPath, useLocation } from "react-router-dom";

const groupByDate = (input) => {
  // use the reduce function to iterate over the input array and accumulate the grouped items in an object
  let temp = input.reduce((acc, item) => {
    // get the date string from the date property
    let date = item.date;
    // check if the date already exists in the accumulator object
    if (acc[date]) {
      // if yes, add the value to the existing sum
      acc[date] += item.value;
    } else {
      // if not, create a new property with the date and set the value as the initial sum
      acc[date] = item.value;
    }
    // return the accumulator object
    return acc;
  }, {}); // pass an empty object as the initial value of the accumulator
  // use the Object.entries function to convert the temp object into an array of key-value pairs
  let entries = Object.entries(temp);
  // use the map function to iterate over the entries array and transform each pair into an object with date and value properties
  let result = entries.map(([date, value]) => {
    return { date, value };
  });
  // return the result array
  return result;
};

const sortByField = (fieldName, array, direction) => {
  if (!array || array.length === 0) {
    return array;
  }

  let sorted = array.slice();

  if (direction === "asc") {
    sorted.sort((a, b) => {
      return a[fieldName] - b[fieldName];
    });
  } else if (direction === "desc") {
    sorted.sort((a, b) => {
      return b[fieldName] - a[fieldName];
    });
  }
  return sorted;
};

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export { groupByDate, sortByField, useRouteMatch };
