import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {Link, Stack, Typography} from "@mui/material";
import moment from "moment";
import i18n from "i18next";
import {Trans, useTranslation} from "react-i18next";
import  {Link as RouterLink} from "react-router-dom";

export default function ActivityTimelineItem({event, first, last}) {
    const {t} = useTranslation();
    const eventPayload = JSON.parse(event.payload)  || {}

    const eventContent = {
        "PROJECT_ASSIGNED": (type, payload) => {
            const name = payload.name
            return (<Typography>
                <Trans t={t} i18nKey={"client:activity.project_assigned"}>
                    Proiectul <Link component={RouterLink} to={`/projects/${payload.id}`}>{{name}}</Link> a fost inceput
                </Trans>
                </Typography>)
        },
        "PROJECT_UNASSIGNED": (type, payload) => {
            const name = payload.name
            return (<Typography>
                <Trans t={t} i18nKey={"client:activity.project_unassigned"}>
                    Proiectul <Link component={RouterLink} to={`/projects/${payload.id}`}>{{name}}</Link> a fost inceput
                </Trans>
                </Typography>)
        },
        "CREATE": (type, payload) => {
            return <Typography>{t(`${type.toLowerCase()}:activity.create`, payload)}</Typography>
        },
        "DELETE": (type, payload) => {
            return <Typography>{t(`${type.toLowerCase()}:activity.delete`, payload)}</Typography>
        },
        "UPDATE": (type, payload) => {
            return (<Stack>
                <Typography>{t(`${type.toLowerCase()}:activity.update`)}</Typography>
                {payload && Object.keys(payload).length > 0 ?
                    (
                        payload.map(it => <Typography key={it.field} variant={"body2"}>
                            {t(`${type.toLowerCase()}:label.${it.field}`)} : {t('event:updatedDiff', {
                            from: it.old,
                            to: it.new
                        })}</Typography>)
                    )
                    : ""}
            </Stack>)
        }
    }

    return (
        <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
            >
                {moment(event.createdAt).locale(i18n.resolvedLanguage).local().format("DD-MMMM-yyyy hh:mm")}
            </TimelineOppositeContent>
            <TimelineSeparator>
                {last && !first ? "" : <TimelineConnector />}
                <TimelineDot />
                {first ? "" : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    {t(`event:${event.eventType.toString()}`)}
                </Typography>
                {eventContent[event.eventType] ? eventContent[event.eventType](event.itemType, eventPayload):
                    <Typography>{t(`${event.itemType.toLowerCase()}:activity.${event.eventType.toLowerCase()}`, {name: eventPayload.name})}</Typography>
                }
            </TimelineContent>
        </TimelineItem>
    )
}