import {apiSlice} from "../api/apiSlice";

export const incomeSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProjectIncome: builder.query({
            query: (projectId) => `/collections?projectId=${projectId}`,
            providesTags: (result, error, arg) => [{type: "INCOME", id: `${arg}-LIST`}]
        }),
        addIncome: builder.mutation({
          query: (payload) => ({
              url: "/collections",
              method: "POST",
              body: payload
          }),
            invalidatesTags: (result, error, arg) => [
                {type: "INCOME", id: `${result.projectId}-LIST`},
                {type: "PROJECT", id: result.projectId},
                {type: "ACTIVITY"},
            ]
        }),
        updateIncome: builder.mutation({
            query: ({id, income}) => ({
                url: `/collections/${id}`,
                method: "PUT",
                body: income
            }),
            invalidatesTags: (result, error, arg) => [
                {type: "INCOME", id: `${result.projectId}-LIST`},
                {type: "PROJECT", id: result.projectId},
                {type: "ACTIVITY"},
            ]
        }),
        deleteIncome: builder.mutation({
            query: (id) => ({
                url: `/collections/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, arg) => [
                {type: "INCOME", id: `${result.projectId}-LIST`},
                {type: "PROJECT", id: result.projectId},
                {type: "ACTIVITY"},
            ]
        })

    })
});

export const {
    useGetProjectIncomeQuery,
    useAddIncomeMutation,
    useUpdateIncomeMutation,
    useDeleteIncomeMutation

} = incomeSlice;