import {Timeline, timelineOppositeContentClasses} from "@mui/lab";
import {useGetActivityForItemQuery} from "./activitySlice";
import Loading from "../../mui/pages/Loading";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ActivityTimelineItem from "./ActivityTimelineItem";

export default function ActivityTimeline({itemType, itemId}) {
    const {data: items = [], isLoading, isSuccess} = useGetActivityForItemQuery({type: itemType, itemId: itemId});
    const {t} = useTranslation(itemType.toString().toLowerCase());
    let content = []
    if (isLoading) {
        return  <Loading />;
    } else if (isSuccess && items.length === 0) {
        return <Typography variant={"h5"}>{t('activity.none')}</Typography>
    } else if (items.length > 0) {
        content = items.map((item, i) => {
            return <ActivityTimelineItem key={item.id} event={item} last={(i + 1 === items.length)} first={i === 0}/>
        });
    }

    return (<Timeline
        sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
            },
        }}
    >
        {content.toReversed()}
    </Timeline>)
}