import {
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import LocaleAwareDatePicker from "../../mui/components/LocaleAwareDatePicker";
import { enqueueSnackbar } from "notistack";
import {
  useDeleteIncomeMutation,
  useUpdateIncomeMutation,
} from "./incomeSlice";
import { Controller, useForm } from "react-hook-form";

export default function CollectionTableRow({
  item,
  project,
  onEdit,
  onDelete,
}) {
  const [editing, setEditing] = useState(false);
  const confirm = useConfirm();

  const { t } = useTranslation(["expense", "translation", "error"]);

  const [deleteIncome] = useDeleteIncomeMutation();
  const [updateIncome] = useUpdateIncomeMutation();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      amount: item.amount,
      creditedAt: moment(item.creditedAt),
      purpose: item.purpose,
    },
  });

  const handleEdit = async (data) => {
    let payload = {
      amount: data.amount,
      purpose: data.purpose,
      creditedAt: data.creditedAt,
    };
    await updateIncome({ id: item.id, income: payload })
      .unwrap()
      .then((data) => {
        setEditing(false);
        reset();
      })
      .catch((err) => {
        enqueueSnackbar(t(`error:${err.data.message}`), {
          variant: "error",
        });
      });
  };

  const handleDelete = () => {
    confirm({
      title: t("income.delete.dialog.title"),
      description: t("income.delete.dialog.body", {
        value: item.amount,
        currency: project.currency,
      }),
      confirmationText: t("translation:button.yes"),
      cancellationText: t("translation:button.no"),
    }).then(async () => {
      await deleteIncome(item.id)
        .unwrap()
        .then((data) => {
          enqueueSnackbar(t("msg.notif.income.removed"), {
            variant: "success",
          });
        })
        .catch((err) => {
          enqueueSnackbar(t(`error:${err.data.message}`), {
            variant: "error",
          });
        });
    });
  };

  const editForm = (
    <TableRow>
      <TableCell colSpan={4}>
        <form noValidate onSubmit={handleSubmit(handleEdit)}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Controller
                    name="creditedAt"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t("error:field.error.required"),
                      },
                    }}
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <LocaleAwareDatePicker
                        label={t("column.received_on")}
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors["creditedAt"]}
                        helperText={
                          errors["creditedAt"]
                            ? errors["creditedAt"].message
                            : ""
                        }
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    control={control}
                    name="amount"
                    valueAsNumber={true}
                    rules={{
                      required: {
                        value: true,
                        message: t("error:field.error.required"),
                      },
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t("error:field.error.nan"),
                      },
                    }}
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <TextField
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label={t("column.value")}
                        error={!!errors["amount"]}
                        helperText={
                          errors["amount"] ? errors["amount"].message : ""
                        }
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography>{project.currency}</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </TableCell>

                <TableCell>
                  <Controller
                    name="purpose"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t("error:field.error.required"),
                      },
                      validate: (value) =>
                        !!value.trim() || t("error:field.error.required"),
                    }}
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <TextField
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        fullWidth
                        error={!!errors["purpose"]}
                        helperText={
                          errors["purpose"] ? errors["purpose"].message : ""
                        }
                        label={t("column.representing")}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <IconButton type="submit" disabled={!isValid}>
                    <SaveIcon />
                  </IconButton>
                  <IconButton onClick={(e) => setEditing(false)}>
                    <CancelIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>
      </TableCell>
    </TableRow>
  );

  return editing ? (
    editForm
  ) : (
    <TableRow>
      <TableCell>
        {moment(item.creditedAt)
          .locale(i18n.resolvedLanguage)
          .format("DD-MMM-YYYY")}
      </TableCell>
      <TableCell>
        {item.amount.toFixed(2)} {project.currency}
      </TableCell>
      <TableCell>{item.purpose}</TableCell>
      <TableCell align={"center"}>
        <IconButton onClick={(e) => setEditing(true)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
