import {apiSlice} from "../api/apiSlice";

export const anafSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        searchByCui: builder.mutation({
            query: (cui) => ({
                url: `/anaf?cui=${cui}`,
                method: 'GET'
        })
        })
    })
})


export const {useSearchByCuiMutation} = anafSlice