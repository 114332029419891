import {Navigate} from "react-router-dom";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {logout} from "../../features/auth/authSlice";

export default function Logout() {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(logout());
        enqueueSnackbar(t("msg.notif.signout"), {variant: "success"});
    });
    return <Navigate to={"/"} reloadDocument replace={true}/>;
}
