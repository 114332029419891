import {Box, Grid, Typography} from "@mui/material";
import ContactCard from "./ContactCard";
import {useTranslation} from "react-i18next";
import AddContactCard from "./AddContactCard";

export default function ClientContactCards({clientId, contacts}) {
    const {t} = useTranslation();
    return (
        <Box sx={{mt: 5}}>
            <Typography variant="h6">{t("client:section.contacts.title")}</Typography>
            <Grid container spacing={4} columns={4} sx={{mt: 4}}>
                {contacts.map((contact) => (
                    <Grid item key={contact.id}>
                        <ContactCard contact={contact}/>
                    </Grid>
                ))}
                <Grid item maxWidth={250}>
                    <AddContactCard clientId={clientId}/>
                </Grid>
            </Grid>
        </Box>
    );
}
