import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import SampleCashFlowImage from "../../img/sample-net-flow.png";
import SampleProjectDetails from "../../img/project-details.png";
import SampleExpenses from "../../img/expenses.png";
import SampleIncome from "../../img/income.png";
import SampleTimeTracking from "../../img/time-tracking.png";
import JoinMailingList from "../components/JoinMailingList";
import { useTranslation } from "react-i18next";
import Copyright from "../components/Copyright";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../../features/auth/authSlice";
import {Link as RouterLink, Navigate} from "react-router-dom";
import FeatureIcon from "../../features/homepage/FeatureIcon";
import {useState} from "react";


export default function HomePage() {
  const { t } = useTranslation();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [open, setOpen] = useState(false);

  return isAuthenticated ? (
    <Navigate to={"/home"} replace />
  ) : (
    <Container>
      <Box sx={{mb: 15}} textAlign={"center"}>
        <Typography textAlign={"center"} variant={"h3"} sx={{mb: 5}}>
          {t("home.title")}
        </Typography>
        <Stack direction={"row"} alignItems={"center"} display={"flex"} justifyContent={"center"} spacing={4}>
          <FeatureIcon type={"projects"} />
          <FeatureIcon type={"clients"} />
          <FeatureIcon type={"income"} />
          <FeatureIcon type={"expenses"} />
          <FeatureIcon type={"time"} />
        </Stack>
        <Box>
          <Grid container>
            <Grid item xs={0} md={4} />
            <Grid item xs={12} md={4}>
              <Button variant={"contained"} color={"primary"} size={"large"} fullWidth
                      component={RouterLink} to={"/sign-up"}
                      sx={{mt: 4, borderRadius: 6}}>{t("home.cta.label")}</Button>
            </Grid>
            <Grid item xs={0} md={4} />
          </Grid>
        </Box>
      </Box>

      <Grid container spacing={8}>
        <Grid
          item
          xs={12}
          md={5}
          textAlign={"left"}
          alignItems={"center"}
          display={"flex"}
          justifyContent={"center"}
        >
          <Stack spacing={2}>
            <Typography variant={"h4"}>
              {t("home.features.cashflow.title", "Managing your business, made simple")}
            </Typography>
            <Typography variant={"h6"}>{t("home.features.cashflow.subtitle")}</Typography>
          </Stack>
        </Grid>
        <Grid item md={7} sx={{ overflow: "hidden" }} xs={12}>
          <img src={SampleCashFlowImage} width={"100%"} />
        </Grid>

        <Grid item md={7}  sx={{ overflow: "hidden" }} xs={12}>
          <img src={SampleProjectDetails} width={"100%"} />
        </Grid>
        <Grid item md={5} xs={12}           textAlign={"left"}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"center"}>
          <Stack spacing={2}>
          <Typography variant={"h4"}>{t("home.features.details.title")}</Typography>
          <Typography variant={"h6"}>{t("home.features.details.subtitle")}</Typography>
          </Stack>
        </Grid>


        <Grid item md={5} xs={12}           textAlign={"left"}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"center"}>
          <Stack spacing={2}>
            <Typography variant={"h4"}>{t("home.features.expense.title")}</Typography>
            <Typography variant={"h6"}>{t("home.features.expense.subtitle")}</Typography>
          </Stack>
        </Grid>
        <Grid item md={7}  sx={{ overflow: "hidden" }} xs={12}>
          <Paper elevation={6}>
            <img src={SampleExpenses} width={"100%"} />
          </Paper>

        </Grid>

        <Grid item md={7}  sx={{ overflow: "hidden" }} xs={12}>
          <img src={SampleIncome} width={"100%"} />
        </Grid>
        <Grid item md={5} xs={12}           textAlign={"left"}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"center"}>
          <Stack spacing={2}>
            <Typography variant={"h4"}>{t("home.features.income.title")}</Typography>
            <Typography variant={"h6"}>{t("home.features.income.subtitle")}</Typography>
          </Stack>
        </Grid>

        <Grid item md={5} xs={12}           textAlign={"left"}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"center"}>
          <Stack spacing={2}>
            <Typography variant={"h4"}>{t("home.features.time.title")}</Typography>
            <Typography variant={"h6"}>{t("home.features.time.subtitle")}</Typography>
          </Stack>
        </Grid>
        <Grid item md={7}  sx={{ overflow: "hidden" }} xs={12}>
          <Paper elevation={6}>
          <img src={SampleTimeTracking} width={"100%"} />
          </Paper>
        </Grid>


      </Grid>

      <Copyright />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Stack spacing={4}>
            <Typography>{t("msg.beta.get_notified")}</Typography>
            <JoinMailingList />
          </Stack>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
