import {apiSlice} from "../api/apiSlice";

export const timelogSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProjectTimeLog: builder.query({
            query: (projectId) => ({
                url: `/timelog?projectId=${projectId}`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [
                {type: "TIMELOG", id: `${arg}-LIST`}
            ]
        }),
        addTimeLog: builder.mutation({
            query: (timeLog) => ({
                url: "/timelog",
                method: "POST",
                body: timeLog
            }),
            invalidatesTags: (result, error, arg) => [
                {type: "TIMELOG", id: `${arg.projectId}-LIST`},
                {type: "PROJECT", id: arg.projectId},
                {type: "ACTIVITY"},
            ]
        }),
        updateTimeLog: builder.mutation({
            query: ({projectId, id, timeLog}) => ({
                url: `/timelog/${projectId}/${id}`,
                method: "PUT",
                body: timeLog
            }),
            invalidatesTags: (result, error, arg) => [
                {type: "TIMELOG", id: `${arg.projectId}-LIST`},
                {type: "PROJECT", id: arg.projectId},
                {type: "ACTIVITY"},
            ]
        }),
        deleteTimeLog: builder.mutation({
            query: ({projectId, id}) => ({
                url: `/timelog/${projectId}/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, arg) => [
                {type: "TIMELOG", id: `${arg.projectId}-LIST`},
                {type: "PROJECT", id: arg.projectId},
                {type: "ACTIVITY"},
            ]
        })
    })
});

export const {
    useGetProjectTimeLogQuery,
    useAddTimeLogMutation,
    useUpdateTimeLogMutation,
    useDeleteTimeLogMutation
} = timelogSlice;