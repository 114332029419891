import {Grid, TextField, Tooltip} from "@mui/material";
import {useState} from "react";
import {useTranslation} from "react-i18next";

export default function ProjectEstimatesStep({project, onDataChange}) {
    const [timeEstimate, setTimeEstimate] = useState(project.estimatedHours);
    const [priceEstimate, setPriceEstimate] = useState(project.priceRequested);
    const {t} = useTranslation("project");

    const handleTimeEstimateChange = (event) => {
        const val = event.target.value;

        if (val.match(/[^0-9.]/)) {
            return event.preventDefault();
        }

        setTimeEstimate(val);
        onDataChange({estimatedHours: val});
    }

    const handlePriceEstimateChange = (event) => {
        const val = event.target.value;

        if (val.match(/[^0-9.]/)) {
            return event.preventDefault();
        }
        setPriceEstimate(val);
        onDataChange({priceRequested: val});
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Tooltip title={t("fields.time.tooltip")}>
                    <TextField inputProps={{inputMode: 'numeric'}}
                               label={t("fields.time.label")} value={timeEstimate} onChange={handleTimeEstimateChange}
                               required
                               fullWidth/>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <Tooltip
                    title={t("fields.price.tooltip")}>
                    <TextField fullWidth label={t("fields.price.label")} value={priceEstimate}
                               onChange={handlePriceEstimateChange} required
                               InputProps={{inputMode: 'numeric'}}/>
                </Tooltip>
            </Grid>
        </Grid>

    );
}