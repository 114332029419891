import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Outlet, useLocation} from "react-router-dom";
import {Button, CssBaseline, Link} from "@mui/material";
import {ConfirmProvider} from "material-ui-confirm";
import {createContext, Suspense, useEffect, useMemo, useState} from "react";
import Cookies from "js-cookie";
import {CookieConsent, getCookieConsentValue} from "react-cookie-consent";
import {initGA, initUserGA} from "../../utils/ga-utils";
import Loading from "../pages/Loading";
import {closeSnackbar, SnackbarProvider} from "notistack";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../features/auth/authSlice";

const ColorModeContext = createContext({
    toggleColorMode: () => {
    },
});
export {ColorModeContext};
export default function AppWrapper() {
    const storedMode = Cookies.get("theme_mode") || "light";
    const [mode, setMode] = useState(storedMode);
    const user = useSelector(selectCurrentUser)

    const handleAcceptCookie = () => {
        if (user && user.id) {
            initUserGA(user.id);
        } else {
            initGA();
        }
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        }
    }, []);

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    let newMode = prevMode === "light" ? "dark" : "light";
                    Cookies.set("theme_mode", newMode);
                    return newMode;
                });
            },
        }),
        []
    );
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                },
            }),
        [mode]
    );

    const pageTitles = {
        "/sign-in": "Foreman - login or sign-up",
        "/sign-up": "Foreman - login or sign-up",
        "/projects": "My Projects",
        "/projects/add": "Create new Project",
        "/projectDetails": "Project details",
        "/account": "My Account",
    };

    let location = useLocation();

    useEffect(() => {
        let path = location.pathname;
        if (
            location.pathname.startsWith("/projects") &&
            !location.pathname.startsWith("/projects/add")
        ) {
            path = "/projectDetails";
        }
        let title = pageTitles[path];
        if (title && title.length > 0) {
            document.title = title;
        } else {
            document.title = "Foreman";
        }
    }, [location, pageTitles]);

    return (
        <Suspense fallback={<Loading/>}>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <ConfirmProvider>
                            <SnackbarProvider hideIconVariant={true}>
                                    <CssBaseline/>
                                    <Outlet/>
                                    <CookieConsent
                                        enableDeclineButton
                                        onAccept={handleAcceptCookie}
                                    >
                                        This website uses cookies to enhance the user experience.
                                    </CookieConsent>
                            </SnackbarProvider>
                    </ConfirmProvider>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </Suspense>
    );
}
