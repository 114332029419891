import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../api/constants";
import Cookies from "js-cookie";

const COOKIE_NAME = "FRM_BETA_WAITLST";

const initialState = {
  isPending: true,
  isLoading: false,
  isError: null,
  isSuccess: null,
  isJoined: !!Cookies.get(COOKIE_NAME),
};

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const joinBetaWishList = createAsyncThunk(
  "/api/beta/waitlist",
  async (email, { rejectWithValue }) => {
    try {
      const data = await axios.post(
        `${BASE_URL}/api/beta/waitlist`,
        { email },
        config
      );
      Cookies.set(COOKIE_NAME, true);
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const mailingSlice = createSlice({
  name: "mailingList",
  initialState,
  extraReducers: {
    [joinBetaWishList.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
      state.isSuccess = false;
      state.isPending = false;
    },
    [joinBetaWishList.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isError = null;
      state.isSuccess = true;
      state.isJoined = true;
    },
    [joinBetaWishList.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = payload;
      state.success = false;
    },
  },
  reducers: {},
});

export const selectCurrentJoinedStatus = (state) => state.mailingList.isJoined;
export default mailingSlice.reducer;
