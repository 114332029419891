import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import LocaleAwareDatePicker from "../../mui/components/LocaleAwareDatePicker";
import { enqueueSnackbar } from "notistack";
import { useAddTimeLogMutation } from "./timelogSlice";
import { Controller, useForm } from "react-hook-form";

export default function LogTimeDialog({ open, projectId, onClose }) {
  const { t } = useTranslation(["timelog", "translation", "error"]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [addTimeLog, { isLoading }] = useAddTimeLogMutation();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      spentAt: moment(),
      duration: 0.0,
      notes: "",
    },
  });

  const onSubmit = async (data) => {
    let payload = {
      duration: data.duration,
      spentAt: data.spentAt.valueOf(),
      notes: data.notes,
      projectId,
    };

    await addTimeLog(payload)
      .unwrap()
      .then((data) => {
        handleClose();
      })
      .catch((error) => {
        enqueueSnackbar(t(`error:${error.data.message}`), {
          variant: "error",
        });
      });
  };

  const handleClose = () => {
    if (!isLoading) {
      reset();
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={isLoading}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle>{t("add.dialog.title")}</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2}>
            <Controller
              name="duration"
              control={control}
              valueAsNumber={true}
              rules={{
                required: {
                  value: true,
                  message: t("error:field.error.required"),
                },
                pattern: {
                  value: /^(0|[1-9]\d*)(\.\d+)?$/,
                  message: t("error:field.error.nan"),
                },
              }}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <TextField
                  label={t("column.duration")}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  error={!!errors["duration"]}
                  helperText={
                    errors["duration"] ? errors["duration"].message : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>{t("translation:hours")}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Controller
              name="spentAt"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("error:field.error.required"),
                },
              }}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <LocaleAwareDatePicker
                  label={t("column.spent_on")}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors["spentAt"]}
                  helperText={
                    errors["spentAt"] ? errors["spentAt"].message : ""
                  }
                />
              )}
            />

            <Controller
              name={"notes"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("error:field.error.required"),
                },
                validate: (value) =>
                  !!value.trim() || t("error:field.error.required"),
              }}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <TextField
                  label={t("column.task")}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  error={!!errors["notes"]}
                  helperText={errors["notes"] ? errors["notes"].message : ""}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            {t("translation:button.cancel")}
          </Button>
          <LoadingButton loading={isLoading} type="submit" disabled={!isValid}>
            {t("translation:button.save")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
