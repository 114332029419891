import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {forgotPassword} from "../../features/auth/authActions";

export default function ForgotPasswordDialog({
                                                 initialEmail,
                                                 visible,
                                                 handleClose,
                                             }) {
    const [open, setOpen] = useState(visible);
    const [email, setEmail] = useState(initialEmail);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {loading, isSuccess, isError} = useSelector(state => state.auth)

    useEffect(() => {
        const key = 'forgotPassword';
        if (isSuccess[key] || isError[key]) {
            enqueueSnackbar(t("msg.notif.forgot_email_sent", {email: email}), {variant: "success"});
            onClose();
        }
    }, [isSuccess, isError])

    const triggerResetFlow = () => {
        dispatch(forgotPassword({email}));
    };

    const isValid = useMemo(() => {
        return email.length > 0 && email.includes("@");
    }, [email]);

    const onClose = () => {
        setOpen(false);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t("dialog.title.reset_pwd")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("dialog.content.reset_pwd")}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t("input.email")}
                    type="email"
                    fullWidth
                    variant="standard"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button disabled={loading || !isValid} onClick={triggerResetFlow}>
                    Reset
                </Button>
            </DialogActions>
        </Dialog>
    );
}
