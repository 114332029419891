import { apiSlice } from "../api/apiSlice";

export const expenseSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectExpenses: builder.query({
      query: (projectId) => ({
        url: `/expenses/${projectId}`,
        method: "GET",
      }),
      providesTags: (response, error, projectId) => [
        { type: "EXPENSE", id: `${projectId}-LIST` },
      ],
    }),
    addExpense: builder.mutation({
      query: ({ projectId, expense }) => ({
        url: `/expenses`,
        method: "POST",
        body: expense,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "EXPENSE", id: `${arg.projectId}-LIST` },
        { type: "PROJECT", id: arg.projectId },
        {type: "ACTIVITY"},
      ],
    }),
    updateExpense: builder.mutation({
      query: ({ projectId, expenseId, expense }) => ({
        url: `/expenses/${projectId}/${expenseId}`,
        method: "PUT",
        body: expense,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "EXPENSE", id: `${arg.projectId}-LIST` },
        { type: "PROJECT", id: arg.projectId },
        {type: "ACTIVITY"},
      ],
    }),
    deleteExpense: builder.mutation({
      query: ({ projectId, expenseId }) => ({
        url: `/expenses/${projectId}/${expenseId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "EXPENSE", id: `${arg.projectId}-LIST` },
        { type: "PROJECT", id: arg.projectId },
        {type: "ACTIVITY"},
      ],
    }),
  }),
});

export const {
  useGetProjectExpensesQuery,
  useAddExpenseMutation,
  useUpdateExpenseMutation,
  useDeleteExpenseMutation,
} = expenseSlice;
