import {useState} from "react";
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip,} from "@mui/material";
import moment from "moment";
import {useTranslation} from "react-i18next";
import LocaleAwareDatePicker from "../LocaleAwareDatePicker";
import ClientAutoCompleteMenu from "../../../features/clients/ClientAutoCompleteMenu";

export default function ProjectDetailsStep({
                                               model,
                                               onDataChange,
                                               onValidityChange,
                                           }) {
    const [name, setName] = useState(model.name);
    const [description, setDescription] = useState(model.description);
    const [deadline, setDeadline] = useState(moment(model.deadline));
    const [currency, setCurrency] = useState(model.currency);
    const {t} = useTranslation("project");

    const handleNameChange = (e) => {
        let val = e.target.value;
        setName(val);
        onDataChange({name: val});
        onValidityChange(isValid());
    };

    const handleDescriptionChange = (e) => {
        let val = e.target.value;
        setDescription(val);
        onDataChange({description: val});
        onValidityChange(isValid());
    };

    const handleDeadlineChange = (newValue) => {
        setDeadline(newValue);
        onDataChange({deadline: newValue});
        onValidityChange(isValid());
    };

    const handleCurrencyChange = (e) => {
        let val = e.target.value;
        setCurrency(val);
        onDataChange({currency: val});
        onValidityChange(isValid());
    };

    const handleClientChange = (client) => {
        onDataChange({client: client});
    };

    const isValid = () => {
        return name.length > 0 && description.length > 0;
    };

    const errorProps = (field) => {
        return field.length === 0
            ? {error: true, helperText: t("fields.msg.required")}
            : {};
    };

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <TextField
                    label={t("fields.name.label")}
                    fullWidth
                    required
                    value={name}
                    onChange={handleNameChange}
                    {...errorProps(name)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t("fields.description.label")}
                    required
                    fullWidth
                    multiline
                    value={description}
                    minRows={3}
                    onChange={handleDescriptionChange}
                    {...errorProps(description)}
                />
            </Grid>
            <Grid item xs={12}>
                <ClientAutoCompleteMenu
                    initialValue={null}
                    onChange={handleClientChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Tooltip title={t("fields.currency.tooltip")}>
                    <FormControl fullWidth disabled={true}>
                        <InputLabel id="currency-select-label">
                            {t("fields.currency.label")}
                        </InputLabel>
                        <Select
                            labelId="currency-select-label"
                            id="currency-select"
                            value={currency}
                            label={t("fields.currency.label")}
                            onChange={handleCurrencyChange}
                        >
                            <MenuItem value={"RON"}>RON</MenuItem>
                            <MenuItem value={"EUR"}>EUR</MenuItem>
                            <MenuItem value={"USD"}>USD</MenuItem>
                        </Select>
                    </FormControl>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <LocaleAwareDatePicker
                    value={deadline}
                    onChange={handleDeadlineChange}
                    label={t("fields.deadline.label")}
                />
            </Grid>
        </Grid>
    );
}
