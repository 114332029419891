import {Divider, Grid, Typography} from "@mui/material";
import Project from "./Project";

export default function ProjectListPanelByStatus({title, projects, noTitle = false}) {
    if (!projects || projects.length === 0) {
        return <Grid item/>
    }
    ;

    return (
        <Grid item xs={12} overflow={"hidden"}>
            {noTitle ? "" :
                <Divider textAlign={"left"}><Typography variant={"h6"} color={"primary"}>{title}</Typography></Divider>}
            <Grid container maxWidth={"lg"} spacing={2} sx={{mt: 1}}>
                {projects ? projects.map(p => <Grid item key={p.id}><Project key={p.id}
                                                                             project={p}/></Grid>) : "None"}
            </Grid>

        </Grid>

    )
}