import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useTheme} from "@emotion/react";
import moment from "moment";
import LocaleAwareDatePicker from "../../mui/components/LocaleAwareDatePicker";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import {useAddExpenseMutation} from "./expenseSlice";
import {Controller, useForm} from "react-hook-form";
import SupplierAutoCompleteMenu from "../supplier/SupplierAutoCompleteMenu";

export default function AddExpenseDialog({
                                             projectId,
                                             projectCurrency,
                                             open,
                                             onAdd,
                                             onClose,
                                         }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const {t} = useTranslation(["expense", "translation", "error"]);

    const [addExpense, {isLoading}] = useAddExpenseMutation();

    const {
        handleSubmit,
        reset,
        control,
        formState: {errors, isValid},
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            spentAt: moment(),
            supplier: null,
            purpose: "",
            actualValue: 0.0,
        },
    });

    const onSubmit = async (data) => {
        await addExpense({
            projectId: projectId,
            expense: {
                projectId: projectId,
                actualValue: data.actualValue,
                supplier: data.supplier.id,
                purpose: data.purpose,
                currency: projectCurrency,
                spentAt: data.spentAt.valueOf(),
            },
        })
            .unwrap()
            .then((data) => {
                handleClose();
            })
            .catch((error) => {
                enqueueSnackbar(t(`error:${error.data.details}`), {
                    variant: "error",
                });
            });
    };

    const handleClose = (e, reason) => {
        if (!isLoading) {
            reset();
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown={isLoading}
            fullScreen={fullScreen}
            fullWidth
        >
            <DialogTitle>{t("add.dialog.title")}</DialogTitle>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Stack spacing={2}>
                        <Controller
                            name="actualValue"
                            control={control}
                            valueAsNumber={true}
                            rules={{
                                required: {
                                    value: true,
                                    message: t("error:field.error.required"),
                                },
                                pattern: {
                                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                    message: t("error:field.error.nan"),
                                },
                            }}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <TextField
                                    label={t("column.value")}
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    error={!!errors["actualValue"]}
                                    helperText={
                                        errors["actualValue"] ? errors["actualValue"].message : ""
                                    }
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography>{projectCurrency}</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <Controller
                            name="spentAt"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: t("error:field.error.required"),
                                },
                            }}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <LocaleAwareDatePicker
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    label={t("column.spent_on")}
                                    onBlur={onBlur}
                                    error={!!errors["spentAt"]}
                                    helperText={
                                        errors["spentAt"] ? errors["spentAt"].message : ""
                                    }
                                />
                            )}
                        />


                        <Controller
                            name="supplier"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: t("error:field.error.required"),
                                },
                                validate: (value) => {
                                  return !!value.name.trim() || t("error:field.error.required")
                                },
                            }}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <SupplierAutoCompleteMenu onChange={onChange} initialValue={value}
                                                          onBlur={onBlur} name={name}/>
                            )}
                        />

                        <Controller
                            name="purpose"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: t("error:field.error.required"),
                                },
                            }}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <TextField
                                    label={t("column.item")}
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    fullWidth
                                    error={!!errors["purpose"]}
                                    helperText={
                                        errors["purpose"] ? errors["purpose"].message : ""
                                    }
                                />
                            )}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isLoading}>
                        {t("translation:button.cancel")}
                    </Button>
                    <LoadingButton loading={isLoading} type="submit" disabled={!isValid}>
                        {t("translation:button.save")}
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
}
