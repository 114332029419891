import {Badge, Box, Link, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectIsAuthenticated} from "../../features/auth/authSlice";

export default function Brand() {
    const isAuthenticated = useSelector(selectIsAuthenticated);
    return (
        <Typography
            variant="h6"
            color="inherit"
            noWrap
            sx={{paddingTop: 1, mr: 2, display: "flex", overflow: "visible"}}
        >
            <Badge badgeContent={"BETA"} color="success">
                <Link color="inherit" underline="none" to={isAuthenticated ? "/home" : "/"} component={RouterLink}>
                    Foreman
                </Link>
            </Badge>
        </Typography>
    );
}
