import {ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {
    AlarmOutlined,
    NewReleasesOutlined,
    ProductionQuantityLimitsOutlined,
    RunningWithErrorsOutlined,
    WarningAmberOutlined
} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export default function Notification({notification, onClick}) {
    const {t} = useTranslation(["notifications"])
    const icons = [
        {
            type: "maintenance",
            icon: <WarningAmberOutlined color={"primary"}/>
        },
        {
            type: "release",
            icon: <NewReleasesOutlined color={"success"}/>
        },
        {
            type: "deadline",
            icon: <AlarmOutlined color={"secondary"}/>
        },

        {
            type: "overdue",
            icon: <AlarmOutlined color={"error"}/>
        },

        {
            type: "exceeded_budget",
            icon: <ProductionQuantityLimitsOutlined color={"warning"}/>
        },

        {
            type: "exceeded_time",
            icon: <RunningWithErrorsOutlined color={"error"}/>
        },

    ];

    const matchIcon = (type) => {
        const matchDef = icons.find(icon => icon.type === type);
        if (matchDef && matchDef.icon) {
            return matchDef.icon;
        } else {
            return <NewReleasesOutlined color={"success"}/>
        }
    }

    return (<ListItem>
        <ListItemButton onClick={() => {
            onClick(notification);
        }}>
            <ListItemAvatar>
                {matchIcon(notification.type.type)}
            </ListItemAvatar>

            <ListItemText primary={t(`notifications:${notification.type.type}.title`, notification.params)}
                          secondary={t(`notifications:${notification.type.type}.body`, notification.params)}
                          secondaryTypographyProps={{variant: "caption"}}/>
        </ListItemButton>
    </ListItem>);
}