import {Container, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import i18n from "../../i18n";
import {useTranslation} from "react-i18next";

export default function ReleasePageJuly() {
    const {t} = useTranslation();
    const title = {
        "en": "July 2023 Release Notes",
        "ro": "Conținut versiune Iulie 2023"
    }

    const features = {
        "en": [
            {
                id: 1,
                primary: "Romanian UI Support",
                secondary: "Added support and translations for the entire site to work in Romanian. The default language can be changed in the top bar and in the user preferences menu."
            },

            {
                id: 2,
                primary: "Notifications",
                secondary: "The notification bell helps quickly see when a project is close to the deadline, overdue or has exceeded the estimaated time or budget. It's also where system announcement will show up."
            },

            {
                id: 3,
                primary: "Permalinks for project tabs",
                secondary: "Project and account tab panels - like expenses, income or time -  now have dedicated urls, so you can bookmark and / or return to that specific information easier"
            },

        ],
        "ro": [
            {
                id: 1,
                primary: "Interfața în limba română",
                secondary: "Am adăugat suport si traduceri pentru întreaga aplicație în limba română. Limba preferată se poate schimba din meniul aplicație și din setările de utilizator."
            },
            {
                id: 2,
                primary: "Notificări",
                secondary: "Zona de notificări anunță când un proiect se apropie sau a depășit termenul de livrare, precum și când bugetul sau timpul de lucru alocat sunt depășite. Tot aici apar și anunțurile tip sistem - modificări, mentenanțe."
            },
            {
                id: 3,
                primary: "Adrese permanente pentru subdiviziuni",
                secondary: "Subdiviziunile din pagina de proiect și setări cont - cheltuieli, pontaj, preferințe - au acum adrese unice care pot fi salvate și încărcate direct."
            },
        ]
    }

    const fixes = {
        "en": [
            {
                id: 1,
                primary: "Fixed an issue with homepage losing the list of projects on user action",
                secondary: "When actions took place on the home page that required a re-render it caused the project list to appear empty."
            },
            {
                id: 2,
                primary: "Fixed an issue with dates losing the time information.",
                secondary: "When adding an expense or income the local browser timezone was not persisted on the server. This could cause an entry that is within the UTC offset to show a day earlier / later when close to midnight"
            },
            {
                id: 3,
                primary: "Performance improvements",
                secondary: "We optimized the server calls in order to make the app snappier."
            }
        ],
        "ro": [
            {
                id: "b1",
                primary: "Proiectele active de pe prima pagină dispar după o acțiune",
                secondary: "După schimbarea modului întunecat sau luminos lista de proiecte active se golește."
            },
            {
                id: "b2",
                primary: "Fusul orar se pierde la adăugarea unor date",
                secondary: "La adăugarea unei cheltuieli sau încasări informațiile despre fusul orar se pierdeau la salvare. Acest lucru poate duce la afișarea incorectă a datei cand ora este în apropiere de 00 +/- diferența de fus către GMT."
            },
            {
                id: "b3",
                primary: "Optimizări de performanță",
                secondary: "Am optimizat numărul apelurilor către server pentru a face aplicația mai rapidă."
            }
        ]
    }

    return (<Container>
        <Typography variant={"h4"}>{title[i18n.resolvedLanguage]}</Typography>
        <List>
            <ListItem>
                <ListItemIcon><AutoAwesomeIcon/></ListItemIcon>
                <ListItemText primary={
                    t("notes.section.enhancements")
                }
                              primaryTypographyProps={
                                  {
                                      variant: "h6"
                                  }
                              }
                />
            </ListItem>

            {
                features[i18n.resolvedLanguage].map(it => {
                    return (
                        <ListItem key={it.id}><ListItemText primary={it.primary} secondary={it.secondary}/> </ListItem>)
                })
            }

            <ListItem>
                <ListItemIcon><AutoAwesomeIcon/></ListItemIcon>
                <ListItemText primary={t("notes.section.bugs")} primaryTypographyProps={{variant: "h6"}}/>
            </ListItem>
            {
                fixes[i18n.resolvedLanguage].map(it => {
                    return (
                        <ListItem key={it.id}><ListItemText primary={it.primary} secondary={it.secondary}/> </ListItem>)
                })
            }
        </List>
    </Container>)
}