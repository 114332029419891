import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import i18next from "../../i18n";
import "moment/locale/ro";
import "moment/locale/en-gb";
import { useEffect, useState } from "react";
import moment from "moment";

export default function LocaleAwareDatePicker({ label, value, onChange }) {
  const [adapterLocale, setAdapterLocale] = useState("en");

  useEffect(() => {
    let lang = i18next.resolvedLanguage;
    let normalizedLang = lang === "ro" ? "ro" : "en";
    moment.locale(lang === "en" ? "en-gb" : "ro");
    setAdapterLocale(normalizedLang);
  }, []);

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={adapterLocale}
    >
      <DatePicker
        views={["year", "month", "day"]}
        label={label}
        value={value}
        onChange={onChange}
        fullWidth
      />
    </LocalizationProvider>
  );
}
