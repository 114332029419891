import {apiSlice} from "../api/apiSlice";

export const activitySlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getActivityForItem: builder.query({
            query: ({ type, itemId }) => `/activity/item/${type.toString().toUpperCase()}/${itemId}`,
            providesTags: (result, error, arg) =>  [
                {type: 'ACTIVITY', id: `ITEM-${arg.itemId}`}]
        }),
        getActivityForUser: builder.query({
            query: (nr) => `/activity?top=${nr}`,
            providesTags: [{type: 'ACTIVITY', id: 'USER'}]
        })
    })
});

export const {useGetActivityForItemQuery, useGetActivityForUserQuery} = activitySlice