import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  useDeleteProjectStatusMutation,
  useUpdateProjectStatusMutation,
} from "./projectStatusSlice";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useConfirm } from "material-ui-confirm";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";

export default function CustomStatus({ status }) {
  const { t } = useTranslation(["account", "translation", "project", "error"]);
  const confirm = useConfirm();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [deleteProjectStatus] = useDeleteProjectStatusMutation();

  const [updateProjectStatus, { isLoading: isEditLoading }] =
    useUpdateProjectStatusMutation();

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isValid, errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      label: status.label,
    },
  });

  const handleEdit = () => {
    setShowEditDialog(true);
    setValue("label", status.label);
  };

  const handleDelete = async () => {
    confirm({
      title: t("delete.dialog.title"),
      description: t("delete.dialog.body", { status: status.statusValue }),
      confirmationText: t("translation:button.yes"),
      cancellationText: t("translation:button.no"),
    })
      .then(async () => {
        await deleteProjectStatus(status.statusValue)
          .unwrap()
          .then((d) => {})
          .catch((error) => {
            enqueueSnackbar(t(`error:${error.data.message}`), {
              variant: "error",
              preventDuplicate: true,
            });
          });
      })
      .catch((e) => {});
  };
  const onClose = () => {
    setShowEditDialog(false);
    reset();
  };

  const onSubmit = async (data) => {
    const payload = {
      id: status.id,
      statusValue: status.statusValue,
      label: data.label.trim(),
    };

    await updateProjectStatus({ id: status.statusValue, status: payload })
      .unwrap()
      .then((data) => {
        onClose();
      })
      .catch((error) => {
        if (
          error.status === 400 &&
          error.data &&
          error.data.message === "INVALID_FIELD"
        ) {
          enqueueSnackbar(t(`error:${error.data.message}`), {
            variant: "error",
            preventDuplicate: true,
          });
        } else {
          enqueueSnackbar(t(`error:${error.data.details[0]}`), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      });
  };

  return (
    <>
      <Chip
        label={status.label}
        color={"secondary"}
        onClick={handleEdit}
        onDelete={handleDelete}
      />

      {showEditDialog ? (
        <Dialog open={showEditDialog} onClose={onClose} keepMounted={false}>
          <DialogTitle>{t("account:update.dialog.title")}</DialogTitle>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <DialogContentText>
                {t("account:update.dialog.body")}
              </DialogContentText>
              <Controller
                control={control}
                name={"label"}
                rules={{
                  required: {
                    value: true,
                    message: t("error:field.error.required"),
                  },
                  validate: (value) =>
                    !!value.trim() || t("error:field.error.required"),
                }}
                render={({ field: { name, value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    label={t("project:fields.status.label")}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    type="text"
                    fullWidth
                    variant="standard"
                    error={!!errors["label"]}
                    helperText={errors["label"] ? errors["label"].message : ""}
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                {t("translation:button.cancel")}
              </Button>
              <LoadingButton
                loading={isEditLoading}
                type="submit"
                disabled={!isValid}
              >
                {t("translation:button.save")}
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
}
