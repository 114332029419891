import {Box, Grid, Paper, Stack} from "@mui/material";
import NetWorth from "../components/NetWorth";
import ActiveProjectsCard from "../components/ActiveProjectsCard";
import Copyright from "../components/Copyright";
import moment from "moment";
import RecentUserActivity from "../../features/activity/RecentUserActivity";

export default function Dashboard() {
    const startDate = moment().subtract(30, "day").toDate().getTime()
    const endDate = moment().utc().toDate().getTime()

    return (<>
        <Grid container spacing={3}>
            <Grid item xs={12} md={8} sx={{borderRadius: 6}}>
                <Stack spacing={4}>
                    <Paper elevation={4}>
                        <Box>
                            <NetWorth startDate={startDate} endDate={endDate}/>
                        </Box>
                    </Paper>

                    <Paper elevation={4}>
                        <Box>
                            <ActiveProjectsCard/>
                        </Box>
                    </Paper>
                </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
                <RecentUserActivity count={5} />
            </Grid>
        </Grid>
        <Copyright sx={{m: 5}}/>

    </>)
}