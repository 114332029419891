import {Navigate, Outlet, useLocation} from "react-router-dom";
import {Container,} from "@mui/material";
import ApplicationTopBar from "../components/ApplicationTopBar";
import {selectCurrentUser, selectIsAuthenticated} from "../../features/auth/authSlice";
import {useSelector} from "react-redux";

export default function ProtectedLayout() {
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const userInfo = useSelector(selectCurrentUser);
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to={"/sign-in"} replace state={{ from: location }}/>
    } else if (!userInfo) {
    }

    return (
        <Container maxWidth="xlg">
                <ApplicationTopBar/>
                <Container component="main" maxWidth="lg" sx={{mt: 15}}>
                    <Outlet/>
                </Container>
        </Container>
    );
}
