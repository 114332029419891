import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import LocaleAwareDatePicker from "../../mui/components/LocaleAwareDatePicker";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { useAddIncomeMutation } from "./incomeSlice";
import { Controller, useForm } from "react-hook-form";
import { resetCookieConsentValue } from "react-cookie-consent";

export default function AddCollectionDialog({ open, project, onClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation(["expense", "translation", "error"]);

  const [addIncome, { isLoading }] = useAddIncomeMutation();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      amount: 0.0,
      creditedAt: moment(),
      purpose: "",
    },
  });

  let projectId = project.id;

  const handleClose = () => {
    if (!isLoading) {
      onClose();
      reset();
    }
  };

  const onSubmit = async (data) => {
    const payload = {
      projectId,
      amount: data.amount,
      creditedAt: data.creditedAt,
      purpose: data.purpose,
    };
    await addIncome(payload)
      .unwrap()
      .then((data) => {
        handleClose();
      })
      .catch((error) => {
        enqueueSnackbar(t(`error:${error.data.message}`), {
          variant: "error", preventDuplicate: true
        });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={isLoading}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle>{t("add.income.dialog.title")}</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2}>
            <Controller
              control={control}
              name="amount"
              valueAsNumber={true}
              rules={{
                required: {
                  value: true,
                  message: t("error:field.error.required"),
                },
                pattern: {
                  value: /^(0|[1-9]\d*)(\.\d+)?$/,
                  message: t("error:field.error.nan"),
                },
              }}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <TextField
                  label={t("column.value")}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  error={!!errors["amount"]}
                  helperText={errors["amount"] ? errors["amount"].message : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>{project.currency}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Controller
              name="creditedAt"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("error:field.error.required"),
                },
              }}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <LocaleAwareDatePicker
                  label={t("column.received_on")}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors["creditedAt"]}
                  helperText={
                    errors["creditedAt"] ? errors["creditedAt"].message : ""
                  }
                />
              )}
            />

            <Controller
              name="purpose"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("error:field.error.required"),
                },
                validate: (value) =>
                  !!value.trim() || t("error:field.error.required"),
              }}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <TextField
                  label={t("column.item")}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  error={!!errors["purpose"]}
                  helperText={
                    errors["purpose"] ? errors["purpose"].message : ""
                  }
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            {t("translation:button.cancel")}
          </Button>
          <LoadingButton loading={isLoading} disabled={!isValid} type="submit">
            {t("translation:button.save")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
