import {useRef, useState} from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import LocaleAwareDatePicker from "../../mui/components/LocaleAwareDatePicker";
import i18n from "i18next";
import { enqueueSnackbar } from "notistack";
import {
  useDeleteExpenseMutation,
  useUpdateExpenseMutation,
} from "./expenseSlice";
import { useConfirm } from "material-ui-confirm";
import { Controller, useForm } from "react-hook-form";
import SupplierAutoCompleteMenu from "../supplier/SupplierAutoCompleteMenu";

export default function ExpenseTableRow({ expense, projectId }) {
  const [editing, setEditing] = useState(false);
  const currency = expense.currency;

  const { t } = useTranslation("expense");
  const confirm = useConfirm();

  const [deleteExpense] = useDeleteExpenseMutation();
  const [updateExpense] = useUpdateExpenseMutation();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      spentAt: moment(expense.spentAt),
      supplier: expense.supplier,
      purpose: expense.purpose,
      actualValue: expense.actualValue,
    },
  });

  const handleEdit = async (data) => {
    console.log("Submit triggered!!!")
    console.log(data);
    let payload = {
      spentAt: data.spentAt.valueOf(),
      projectId,
      actualValue: data.actualValue,
      supplier: data.supplier ? data.supplier.id : null,
      purpose: data.purpose,
      currency,
    };
    await updateExpense({
      projectId: projectId,
      expenseId: expense.id,
      expense: payload,
    })
      .unwrap()
      .then((data) => {
        reset();
        setEditing(false);
      })
      .catch((error) => {
        enqueueSnackbar(t("error.update"), {
          variant: "error",
          preventDuplicate: true,
        });
      });
  };

  const onDelete = async () => {
    confirm({
      title: t("delete.dialog.title", {
        value: expense.actualValue,
        currency: expense.currency,
      }),
      description: t("delete.dialog.body", {
        value: expense.actualValue,
        currency: expense.currency,
        supplier: expense.supplier?.name,
      }),
      confirmationText: t("translation:button.yes"),
      cancellationText: t("translation:button.no"),
    }).then(async () => {
      await deleteExpense({ projectId: projectId, expenseId: expense.id })
        .unwrap()
        .then((data) => {
          enqueueSnackbar(
            t("msg.notif.delete", {
              value: data.actualValue,
              currency: data.currency,
            }),
            { variant: "success", preventDuplicate: true }
          );
        })
        .catch((error) => {
          enqueueSnackbar(t(`error:${error.data.message}`), {
            variant: "error",
            preventDuplicate: true,
          });
        });
    });
  };

  const editForm = (
    <TableRow>
      <TableCell colSpan={4}>
        <form key={expense.id} noValidate onSubmit={handleSubmit(handleEdit)}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ borderBottom: "none" }}>
                  <Controller
                    name="spentAt"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t("error:field.error.required"),
                      },
                    }}
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <LocaleAwareDatePicker
                        label={t("column.spent_on")}
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors["spentAt"]}
                        helperText={
                          errors["spentAt"] ? errors["spentAt"].message : ""
                        }
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }}>
                  <Controller
                    name="actualValue"
                    control={control}
                    valueAsNumber={true}
                    rules={{
                      required: {
                        value: true,
                        message: t("error:field.error.required"),
                      },
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t("error:field.error.nan"),
                      },
                    }}
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <TextField
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors["actualValue"]}
                        helperText={
                          errors["actualValue"]
                            ? errors["actualValue"].message
                            : ""
                        }
                        label={t("column.value")}
                        fullWidth
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }} width={300}>
                  <Controller
                    name="supplier"
                    control={control}
                    render={({ field: { name, value, onChange, onBlur } }) => (
                        <SupplierAutoCompleteMenu initialValue={value} onChange={onChange}/>
                    )}
                  />
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }}>
                  <Controller
                    name="purpose"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t("error:field.error.required"),
                      },
                      validate: (value) =>
                        !!value.trim() || t("error:field.error.required"),
                    }}
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <TextField
                        label={t("column.item")}
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        fullWidth
                        error={!!errors["purpose"]}
                        helperText={
                          errors["purpose"] ? errors["purpose"].message : ""
                        }
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }}>
                  <IconButton type="submit">
                    <SaveIcon />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      setEditing(false);
                      reset();
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>
      </TableCell>
    </TableRow>
  );

  return editing ? (
    editForm
  ) : (
    <TableRow>
      <TableCell>
        {moment(expense.spentAt)
          .locale(i18n.resolvedLanguage)
          .format("DD-MMM-YYYY")}
      </TableCell>
      <TableCell>
        {expense.actualValue} {expense.currency}
      </TableCell>
      <TableCell>{expense.supplier? expense.supplier.name : ""}</TableCell>
      <TableCell>{expense.purpose}</TableCell>
      <TableCell align={"center"}>
        <IconButton onClick={(e) => setEditing(true)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
