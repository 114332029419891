import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ReleasePageSeptember() {
  const { t } = useTranslation();
  const title = {
    en: "September 2023 Release Notes",
    ro: "Conținut versiune Septembrie 2023",
  };

  const features = {
    en: [
      {
        id: 1,
        primary: "Client management",
        secondary: (
          <Typography>
            {"Added support for "}
            <Link to="/clients">managing client information</Link>{" "}
            {
              "- both individual and companies, with automatic ANAF interrogation for companies"
            }
          </Typography>
        ),
      },
    ],
    ro: [
      {
        id: 1,
        primary: "Modul de gestionare a clienților",
        secondary: (
          <>
            <Typography>
              {"Am implementat "}
              <Link to="/clients">{"modulul de gestionare a clienților"}</Link>
              {
                " - persoane fizice si juridice, cu interogare ANAF dupa CUI pentru persoane juridice."
              }
            </Typography>
          </>
        ),
      },
    ],
  };

  const fixes = {
    en: [
      {
        id: 1,
        primary: "Redirect after sign-in",
        secondary:
          "Signing-in after trying to visit a protected page will now take you back to the original page instead of the homepage.",
      },
      {
        id: 2,
        primary: "Improved field validation",
        secondary:
          "When trying to add / edit an entity we now validate and show the errors before trying to talk to the server",
      },
      {
        id: 3,
        primary: "Performance and data consistency fixes",
        secondary:
          "Changing data is now properly updating the UI and the server response has been optimized for performance.",
      },
    ],
    ro: [
      {
        id: 1,
        primary: "Redirecționare dupa autentificare",
        secondary:
          "Vizitarea unei pagini protejate ne-autentificat acum pornește autentificarea și dupa success va încărca pagina originală.",
      },
      {
        id: 2,
        primary: "Mesaje de validare îmbunătățite",
        secondary:
          "Formularele de adăugare / modificare obiecte au primit validare nouă care anunță exact ce este în neregula înainte de a comunica cu serverul.",
      },
      {
        id: 3,
        primary: "Performanță și consistență",
        secondary:
          "Modificarea datelor se reflectă acum corect în toate interfețele. Comunicarea cu serverul a fost și ea optimizată pentru performanță crescută.",
      },
    ],
  };

  return (
    <Container>
      <Typography variant={"h4"}>{title[i18n.resolvedLanguage]}</Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <AutoAwesomeIcon />
          </ListItemIcon>
          <ListItemText
            primary={t("notes.section.enhancements")}
            primaryTypographyProps={{
              variant: "h6",
            }}
          />
        </ListItem>

        {features[i18n.resolvedLanguage].map((it) => {
          return (
            <ListItem key={it.id}>
              <ListItemText primary={it.primary} secondary={it.secondary} />{" "}
            </ListItem>
          );
        })}

        <ListItem>
          <ListItemIcon>
            <AutoAwesomeIcon />
          </ListItemIcon>
          <ListItemText
            primary={t("notes.section.bugs")}
            primaryTypographyProps={{ variant: "h6" }}
          />
        </ListItem>
        {fixes[i18n.resolvedLanguage].map((it) => {
          return (
            <ListItem key={it.id}>
              <ListItemText primary={it.primary} secondary={it.secondary} />{" "}
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
}
