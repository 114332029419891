import {createAsyncThunk} from "@reduxjs/toolkit";
import {BASE_URL} from "../../api/constants";
import axios from "axios";

const config = {
    headers: {
        'Content-Type': 'application/json',
    },
}

export const login = createAsyncThunk(
    '/signin',
    async ({email, password, rememberMe}, {rejectWithValue}) => {
        try {
            // configure header's Content-Type as JSON

            const {data, headers} = await axios.post(
                `${BASE_URL}/signin`,
                {email, password, rememberMe},
                config
            )
            const token = headers["authorization"];

            // store user's token in local storage
            localStorage.setItem('userToken', token);
            localStorage.setItem("user", JSON.stringify(data));

            return {data, token}
        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data) {
                return rejectWithValue(error.response)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

export const register = createAsyncThunk('/signup',
    async ({payload, inviteKey}, {rejectWithValue}) => {
        try {
            const {data, headers} = await axios.post(
                `${BASE_URL}/signup?inviteKey=${inviteKey}`,
                payload,
                config
            )
            const token = headers["authorization"];

            // store user's token in local storage
            localStorage.setItem('userToken', token);
            localStorage.setItem("user", JSON.stringify(data));

            return {data, token}

        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data) {
                return rejectWithValue(error.response)
            } else {
                return rejectWithValue(error.message)
            }
        }
    });


export const verifyEmail = createAsyncThunk('/verify', async (key, {rejectWithValue}) => {
    try {
        const {data, headers} = await axios.get(
            `${BASE_URL}/verify/${key}`,
            config
        );
    }  catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response)
        } else {
            return rejectWithValue(error.message)
        }
    }
});


export const forgotPassword = createAsyncThunk('/forgot', async ({email}, {rejectWithValue}) => {
    try {
        const {data} = await axios.post(
            `${BASE_URL}/forgot`,
            {email: email},
            config
        );
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response)
        } else {
            return rejectWithValue(error.message)
        }
    }
});

export const resetPassword = createAsyncThunk('/reset', async ({key, password}, {rejectWithValue}) => {
    try {
        const {data} = await axios.post(
            `${BASE_URL}/reset/${key}`,
            {password},
            config
        );
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response)
        } else {
            return rejectWithValue(error.message)
        }
    }
})