import {Card, CardContent, CardHeader, Skeleton} from "@mui/material";
import Chart from "react-apexcharts";
import {useTheme} from "@emotion/react";
import {green} from "@mui/material/colors";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useGetNetWorthQuery} from "../../features/aggregates/aggregateSlice";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../features/auth/authSlice";

export default function NetWorth({startDate, endDate}) {
    const user = useSelector(selectCurrentUser)
    const {t} = useTranslation();

    const theme = useTheme();
    let series = [];
    const [noDataText, setNoDataText] = useState(t('networth.nodata'));


    const {data: points = [], isLoading, isSuccess, isError, error} = useGetNetWorthQuery({
        startDate,
        endDate
    });

    if (isSuccess) {
        let raw = points.map(it => {
            return {x: it.date, y: it.netWorth.toFixed(1)}
        });

        series = [{
            name: "Net cash flow",
            data: raw
        }];
    }

    useEffect(() => {
        if (isError && error.status !== 404) {
            setNoDataText(error.data.message);
        }
    }, [isError, error])

    let options = {
        chart: {
            id: "net-worth-chart",
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false,
                autoScaleYaxis: true
            }
        },
        theme: {
            palette: 'palette1', // upto palette10
            mode: theme.palette.mode
        },
        stroke: {
            curve: 'smooth'
        },
        noData: {
            text: noDataText,
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: green,
                fontSize: '18px'
            }
        },
        tooltip: {
            enabled: true,
            enabledOnSeries: false,
            formatter: function (val) {
                return val
            },
            x: {
                show: false,
            },
            y: {
                show: false,
                formatter: function (val, opts) {
                    return `${val} ${user.currency}`;
                }
            },
            marker: {
                show: false,
            },
        },
        dataLabels: {
            enabled: true,

        },
        xaxis: {
            type: 'datetime',
            datetimeUTC: true,
            labels: {format: 'dd MMM'},
            min: startDate,
            max: endDate,
        }
    }

    return (
        <Card>
            <CardHeader title={t("home.title.cashflow")}/>
            <CardContent sx={{position: 'relative'}}>
                {isLoading ? <Skeleton variant={"rectangular"} height={200} width={"100%"}/> :
                    <Chart type={"area"} options={options} series={series} height={250}/>
                }
            </CardContent></Card>)
}