import { useState } from "react";
import {
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import LocaleAwareDatePicker from "../../mui/components/LocaleAwareDatePicker";
import { enqueueSnackbar } from "notistack";
import {
  useDeleteTimeLogMutation,
  useUpdateTimeLogMutation,
} from "./timelogSlice";
import { useConfirm } from "material-ui-confirm";
import { Controller, useForm } from "react-hook-form";

export default function TimeLogEntryTableRow({ entry, projectId }) {
  const [editing, setEditing] = useState(false);

  const { t } = useTranslation(["timelog", "translation"]);
  const confirm = useConfirm();

  const [deleteTimeLog] = useDeleteTimeLogMutation();
  const [updateTimeLog] = useUpdateTimeLogMutation();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      spentAt: moment(entry.spentAt),
      duration: entry.duration.toFixed(3),
      notes: entry.notes,
    },
  });

  const handleEdit = async (data) => {
    let payload = {
      spentAt: data.spentAt.valueOf(),
      duration: data.duration,
      notes: data.notes,
      projectId,
    };
    await updateTimeLog({
      projectId: projectId,
      id: entry.id,
      timeLog: payload,
    })
      .unwrap()
      .then((data) => {
        setEditing(false);
      })
      .catch((error) => {
        enqueueSnackbar(t("error.update"), { variant: "error" });
      });
  };

  const onDelete = () => {
    confirm({
      title: t("delete.dialog.title"),
      description: t("delete.dialog.body", {
        count: Number(entry.duration.toFixed(3)),
      }),
      confirmationText: t("translation:button.yes"),
      cancellationText: t("translation:button.no"),
    }).then(async () => {
      await deleteTimeLog({ projectId: projectId, id: entry.id })
        .unwrap()
        .then((data) => {
          enqueueSnackbar(
            t("msg.notif.deleted", {
              count: Number(data.duration.toFixed(2)),
            }),
            {
              variant: "success",
              preventDuplicate: true,
            }
          );
        })
        .catch((error) => {
          enqueueSnackbar(t(`error:${error.data.message}`), {
            variant: "error",
            preventDuplicate: true,
          });
        });
    });
  };

  const handleClose = () => {
    setEditing(false);
    reset();
  };

  const editView = (
    <TableRow>
      <TableCell colSpan={4}>
        <form noValidate onSubmit={handleSubmit(handleEdit)}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ borderBottom: "none" }}>
                  <Controller
                    name="spentAt"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t("error:field.error.required"),
                      },
                    }}
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <LocaleAwareDatePicker
                        label={t("column.spent_on")}
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors["spentAt"]}
                        helperText={
                          errors["spentAt"] ? errors["spentAt"].message : ""
                        }
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }}>
                  <Controller
                    name="duration"
                    control={control}
                    valueAsNumber={true}
                    rules={{
                      required: {
                        value: true,
                        message: t("error:field.error.required"),
                      },
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: t("error:field.error.nan"),
                      },
                    }}
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <TextField
                        label={t("column.duration")}
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors["duration"]}
                        helperText={
                          errors["duration"] ? errors["duration"].message : ""
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography>{t("translation:hours")}</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }}>
                  <Controller
                    name={"notes"}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t("error:field.error.required"),
                      },
                      validate: (value) =>
                        !!value.trim() || t("error:field.error.required"),
                    }}
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <TextField
                        label={t("column.task")}
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors["notes"]}
                        helperText={
                          errors["notes"] ? errors["notes"].message : ""
                        }
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ borderBottom: "none" }}>
                  <IconButton type="submit" disabled={!isValid}>
                    <SaveIcon />
                  </IconButton>
                  <IconButton onClick={handleClose}>
                    <CancelIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>
      </TableCell>
    </TableRow>
  );

  const readOnlyView = (
    <TableRow>
      <TableCell>{moment(entry.spentAt).format("DD-MMM-YYYY")}</TableCell>
      <TableCell>{entry.duration.toFixed(3)} h</TableCell>
      <TableCell>{entry.notes}</TableCell>
      <TableCell align={"center"}>
        <IconButton onClick={(e) => setEditing(true)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={(e) => onDelete(entry)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
  return editing ? editView : readOnlyView;
}
