import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

export default function Project({ project }) {
  const { t } = useTranslation(["project", "translation"]);

  let progressValue = 100;
  let progressBarColor = "primary";
  let completed = project.status.statusValue === "COMPLETED";

  if (completed) {
    progressBarColor = "success";
  } else {
    let timePassed = moment().diff(moment(project.createdAt), "days");
    let totalTime = moment(project.deadline).diff(
      moment(project.createdAt),
      "days"
    );
    progressValue = (timePassed / totalTime) * 100;
  }

  if (project.status.statusValue === "ON_HOLD") {
    progressBarColor = "warning";
  }

  let tooltip = `${Math.round(progressValue)}% ${t("translation:done")}`;

  if (progressValue > 100 && !completed) {
    progressValue = 100;
    progressBarColor = "error";
    tooltip = t("deadline.overdue");
  }

  let dueIn = moment(project.deadline)
    .locale(i18n.resolvedLanguage)
    .fromNow(false);

  let clientField = project.client ? (
    <Typography variant="subtitle2">
      {t("fields.client.card")}
      <Link
        component={RouterLink}
        to={`/clients/${project.client.id}`}
        color={"inherit"}
        underline="hover"
      >
        {project.client.name}
      </Link>
    </Typography>
  ) : (
    ""
  );

  return (
    <Tooltip title={tooltip}>
      <Card component={Box} maxWidth={300}>
        <CardContent>
          <Typography variant={"h6"}>
            <Link
              to={`/projects/${project.id}`}
              component={RouterLink}
              color={"inherit"}
              underline="hover"
            >
              {project.name}
            </Link>
          </Typography>
          {clientField}
          <Typography variant={"subtitle2"}>
            <Typography variant={"caption"}>
              {t("fields.status.label")}{" "}
            </Typography>{" "}
            {t(
              "project.status." + project.status.statusValue,
              project.status.label
            )}
          </Typography>
          {completed ? (
            ""
          ) : (
            <Typography variant={"subtitle2"}>
              <Typography variant={"caption"}>{t("due_in")}: </Typography>{" "}
              {dueIn}
            </Typography>
          )}
          <Box
            sx={{ mt: 1 }}
            maxHeight={70}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            <Typography variant={"inherit"} sx={{ wordWrap: "break-word" }}>
              {project.description}
            </Typography>
          </Box>
        </CardContent>
        <LinearProgress
          value={progressValue}
          variant={"determinate"}
          color={progressBarColor}
        />
      </Card>
    </Tooltip>
  );
}
