import {NavLink, Outlet} from "react-router-dom";
import {AppBar, Box, Button, Container, Stack, Toolbar} from "@mui/material";
import Brand from "../components/Brand";
import LanguageSwitcher from "../components/LanguageSwitcher";
import {useTranslation} from "react-i18next";

export default function UnprotectedLayout() {
    const {t} = useTranslation();
    const retValue = (
        <Container maxWidth="xlg" mt={12}>
            <AppBar
                color="default"
                elevation={0}
                sx={{borderBottom: (theme) => `1px solid ${theme.palette.divider}`}}
            >
                <Container maxWidth={"xl"}>
                    <Toolbar sx={{flexWrap: "wrap"}}>

                        <Box sx={{flexGrow: 1}}>
                            <Brand/>
                        </Box>
                        <Stack direction={"row"} sx={{flexGrow: 0}}>
                            <Button>
                                <LanguageSwitcher/>
                            </Button>

                            <Button
                                to="/sign-in"
                                component={NavLink}
                                variant="outlined"
                                sx={{my: 1, mx: 1.5}}
                            >
                                {t("button.signin", "Sign-in")}
                            </Button>
                        </Stack>
                    </Toolbar>
                </Container>
            </AppBar>
            <Container component="main" maxWidth="lg" sx={{mt: 15}}>
                <Outlet/>
            </Container>
        </Container>
    );

    return retValue;
}
