import {
  Grid,
  IconButton,
  ListItem, ListItemIcon, TextField,
  Typography,
} from "@mui/material";
import {useDeleteNoteMutation, useEditNoteMutation} from "./notesSlice";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import i18next from "i18next";
import Loading from "../../mui/pages/Loading";
import {Cancel, Delete, Edit, Save} from "@mui/icons-material";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";

export default function Note({ note, divider }) {
  const { t } = useTranslation();
  const [deleteNote, { isLoading }] = useDeleteNoteMutation();
  const confirm = useConfirm();
  const [editMode, setEditMode] = useState(false);
  const [editNote, {isLoading: isSaving}] = useEditNoteMutation();
  const {handleSubmit, control, reset, formState: {isValid, errors}} = useForm({
    defaultValues: {
      content: note.content
    }
  })

  const handleDelete = (e) => {
    confirm({
      title: t("note:confirm.delete.title"),
      description: t("note:confirm.delete.body"),
      confirmationText: t("button.yes"),
      cancellationText: t("button.no"),
    }).then(async (data) => {
      await deleteNote(note.id)
        .unwrap()
        .then((d) => {})
        .catch((error) =>
          enqueueSnackbar(t(`error:${error.data.message}`), {
            variant: "error",
          })
        );
    });
  };

  const persist = async (data) => {
    await editNote({id: note.id, payload: {content: data.content}}).unwrap()
        .then(d => {
          setEditMode(false);
        })
        .catch(error => {
          enqueueSnackbar(t(`error:${error.data.message}`), {variant: 'error'});
        })
  };

  if (isLoading) {
    return <Loading />;
  }

  return <ListItem divider={divider} component={"form"} noValidate onSubmit={handleSubmit(persist)}>
    <Grid container  >
      {editMode ? (
          <Grid item xs={12}>
            <Controller
                control={control}
                name={"content"}
                rules={{
                  required: {
                    value: true,
                    message: t("error:field.error.required"),
                  },
                  validate: (value) =>
                      !!value.trim() || t("error:field.error.required"),
                }}
                render={({field: {name, value, onChange, onBlur}}) => (
                    <TextField
                        autoFocus={true}
                        focused={true}
                        variant={"standard"}
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        multiline
                        fullWidth
                        rows={4}
                        error={!!errors["content"]}
                        helperText={
                          errors["content"] ? errors["content"].message : ""
                        }
                    />
                )}
            />
          </Grid>
      ) : (<>
          <Grid item xs={10}><Typography variant={"body"}>{note.content}</Typography></Grid>
        <Grid item xs={2} textAlign={"center"}>
        <Typography variant={"body2"}>{moment(note.modifiedAt)
        .utc()
        .locale(i18next.resolvedLanguage)
        .format("DD-MMM-YYYY hh:mm")}
        </Typography>
        </Grid>
          </>
        )}
    </Grid>
    {editMode  ? (
        <>
          <ListItemIcon>
            <IconButton type={"submit"} disabled={!isValid || isSaving}>
              <Save />
            </IconButton>
          </ListItemIcon>
          <ListItemIcon>
            <IconButton onClick={e => {
              setEditMode(false);
              reset();
            }}>
              <Cancel />
            </IconButton>
          </ListItemIcon>
        </>

    ) : (<>
      <ListItemIcon sx={{pl: 1, minWidth: 32}} >
      <IconButton type={"button"} onClick={(e) => {
        e.preventDefault();
        setEditMode(true);
      }}>
        <Edit />
      </IconButton>
    </ListItemIcon>
    <ListItemIcon sx={{p: 0, minWidth: 32}} >
      <IconButton onClick={handleDelete}>
        <Delete />
      </IconButton>
    </ListItemIcon>
    </>)}
  </ListItem>
}
