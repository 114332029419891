import {Box, Card, CardContent, Icon, Stack, Typography} from "@mui/material";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined';
import {useTranslation} from "react-i18next";

export default function FeatureIcon({type}) {
    const {t} = useTranslation();

    const iconsByType = {
        "clients": <ContactsOutlinedIcon fontSize={"large"} color={"secondary"}/>,
        "projects": <FeaturedPlayListOutlinedIcon fontSize={"large"} color={"warning"} />,
        "expenses": <PaymentsOutlinedIcon fontSize={"large"} color={"primary"} />,
        "income": <MonetizationOnOutlinedIcon fontSize={"large"} color={"error"} />,
        "time": <MoreTimeOutlinedIcon fontSize={"large"} color={"success"} />
    }
    return <Card>
        <CardContent sx={{pb: 0}}>
            <Stack justifyContent={"center"} textAlign={"center"} alignItems={"center"} display={"flex"} spacing={2}>
                {iconsByType[type]}
                <Typography variant={"h6"}>{t(`home.features.${type}`)}</Typography>
            </Stack>
        </CardContent>
    </Card>
}