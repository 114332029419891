import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Link as RouterLink,
  Link,
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import moment from "moment";
import EditProjectDialog from "../components/project_details/EditProjectDialog";
import Copyright from "../components/Copyright";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Loading from "./Loading";
import { enqueueSnackbar } from "notistack";
import { useGetProjectQuery } from "../../features/projects/projectSlice";
import { useGetProjectStatusesQuery } from "../../features/project-statuses/projectStatusSlice";
import DeleteProjectButton from "../../features/projects/DeleteProjectButton";

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export default function ProjectDetails() {
  const { projectId } = useParams();

  const navigate = useNavigate();
  const [showEditForm, setShowEditForm] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation(["project", "error"]);

  const tabs = [
    {
      label: t("tabs.details.label"),
      route: "/projects/:id",
      value: "details",
      path: `/projects/${projectId}`,
      disabled: false,
    },
    {
      label: t("tabs.expenses.label"),
      value: "expenses",
      path: `/projects/${projectId}/expenses`,
      route: "/projects/:id/expenses",
      disabled: false,
    },
    {
      label: t("tabs.income.label"),
      value: "income",
      path: `/projects/${projectId}/income`,
      route: "/projects/:id/income",
      disabled: false,
    },
    {
      label: t("tabs.timetrack.label"),
      value: "time",
      path: `/projects/${projectId}/time`,
      route: "/projects/:id/time",
      disabled: false,
    },
    {
      label: t("tabs.notes.label"),
      value: "notes",
      path: `/projects/${projectId}/notes`,
      route: '/projects/:id/notes',
      disabled: false
    },
    {
      label: t("client:tabs.activity.label"),
      route: "/projects/:id/activity",
      value: "activity",
      path: `/projects/${projectId}/activity`,
      disabled: false,
    },
    {
      label: t("tabs.assets.label"),
      value: "assets",
      path: `/projects/${projectId}/assets`,
      route: "/projects/:id/assets",
      disabled: true,
    },
    {
      label: t("tabs.messages.label"),
      value: "messages",
      path: `/projects/${projectId}/messages`,
      route: "/projects/:id/messages",
      disabled: true,
    },
  ];

  const routeMatch = useRouteMatch([
    "/projects/:id/activity",
    "/projects/:id/notes",
    "/projects/:id/expenses",
    "/projects/:id/income",
    "/projects/:id/time",
    "/projects/:id",
  ]);

  const { data: projectStatuses = [], isLoadingStatuses } =
    useGetProjectStatusesQuery();

  const {
    data: project = {},
    isLoading,
    isError: isFetchError,
  } = useGetProjectQuery(projectId);

  const handleClose = (e) => setShowEditForm(false);
  const handleEdit = () => {
    setShowEditForm(false);
  };

  if (isLoading) {
    return <Loading />;
  } else if (isFetchError) {
    enqueueSnackbar(t("project:error.single.404"), { variant: "error" });
    navigate("/projects");
  }

  const completed = project.status.statusValue === "COMPLETED";
  const overdue = moment().isAfter(project.deadline) && !completed;
  let dueDateText = completed
    ? t("deadline.completed")
    : overdue
    ? t("deadline.overdue")
    : t("deadline.duein", {
        time: moment(project.deadline)
          .locale(i18next.resolvedLanguage)
          .fromNow(true),
      });
  let dueColor = completed ? "green" : overdue ? "error" : "inherit";

  const currentVal = routeMatch?.pattern?.path;
  const matchingTab = tabs.find((t) => t.route === currentVal);
  const tab = matchingTab.value;

  return (
    <>
      <Grid container direction={"row"} maxWidth={"xl"} spacing={4}>
        <Grid item xs={8} md={6}>
          <Typography variant={"h4"}>{project.name}</Typography>
          <Typography variant="caption">
            <Link component={RouterLink} to="/projects">
              {t("page.projects.list.link")}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={4} md={6} textAlign={"right"}>
          <Stack direction={"row-reverse"} spacing={4} sx={{ mt: 2 }}>
            <DeleteProjectButton project={project} />
            {isMobile ? (
              <IconButton onClick={(e) => setShowEditForm(true)}>
                <EditIcon />
              </IconButton>
            ) : (
              <Button
                variant={"contained"}
                color={"primary"}
                endIcon={<EditIcon />}
                onClick={(e) => setShowEditForm(true)}
              >
                {t("button.edit")}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Box sx={{ mt: 5, borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          aria-label="project details"
          indicatorColor="primary"
          value={tab}
          variant={"scrollable"}
          scrollButtons={true}
          allowScrollButtonsMobile
        >
          {tabs.map((t) => (
            <Tab
              key={t.value}
              label={t.label}
              value={t.value}
              to={t.path}
              component={Link}
              disabled={t.disabled}
            />
          ))}
        </Tabs>
      </Box>
      <Box>
        <Outlet context={[project]} />
        {project.id && showEditForm ? (
          <EditProjectDialog
            statuses={projectStatuses}
            project={project}
            open={showEditForm}
            onSubmit={handleEdit}
            onClose={handleClose}
          />
        ) : (
          ""
        )}
      </Box>

      <Copyright sx={{ m: 5 }} />
    </>
  );
}
