import {apiSlice} from "../api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProfile: builder.query({
            query: () => "/api/users/me"
        }),
        updateProfile: builder.mutation({
            query: ({id, payload}) => ({
                url: `/users/${id}`,
                method: 'PUT',
                body: payload
            })
        }),
        updatePassword: builder.mutation({
            query: ({id, payload}) => ({
                url: `/users/${id}/pwd`,
                method: 'PUT',
                body: payload
            })
        }),
        deleteAccount: builder.mutation({
            query: () => ({
                url: '/users',
                method: 'DELETE'
            }),
            invalidatesTags: [    "CLIENT",
                "PROJECT",
                "CONTACT",
                "PROJECT_STATUS",
                "EXPENSE",
                "INCOME",
                "NOTIFICATION",
                "AGGREGATE",
                "CLIENT_PROJECTS"
            ]
        })
    })
});

export const {useGetProfileQuery, useUpdateProfileMutation, useUpdatePasswordMutation, useDeleteAccountMutation} = authApiSlice;