import {useState} from "react";
import i18n from "../../i18n";
import {Box, Button, Menu, MenuItem, Tooltip, Typography,} from "@mui/material";
import {useTranslation} from "react-i18next";

import "/node_modules/flag-icons/css/flag-icons.min.css";
import moment from "moment";


function LanguageSwitcher() {
    const [currentLanguage, setCurrentLanguage] = useState(i18n.resolvedLanguage);
    const {t} = useTranslation();

    const languages = [
        {
            code: "ro",
            label: "Română",
            css: "fi-ro"
        },
        {
            code: "en",
            label: "English",
            css: "fi-gb"
        },
    ];

    const [anchorElLang, setAnchorElLang] = useState(null);

    const handleOpenLangMenu = (event) => {
        setAnchorElLang(event.currentTarget);
    };

    const handleCloseLangMenu = () => {
        setAnchorElLang(null);
    };

    const changeLanguage = async (language) => {
        handleCloseLangMenu();
        setCurrentLanguage(language);
        await i18n.changeLanguage(language);
        moment.locale(language === "en" ? "en-gb" : "ro");

    };

    const selectedLanguageCss = languages.filter(l => l.code === i18n.resolvedLanguage)[0].css;

    return (
        <Box sx={{flexGrow: 0}}>
            <Tooltip
                title={t("language.switcher.tooltip", "Change the site language")}
            >
                <Button onClick={handleOpenLangMenu}><span className={`fi ${selectedLanguageCss}`}/></Button>
            </Tooltip>
            <Menu
                sx={{mt: "45px"}}
                id="menu-langbar"
                anchorEl={anchorElLang}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorElLang)}
                onClose={handleCloseLangMenu}
                MenuListProps={{onMouseLeave: handleCloseLangMenu}}
            >
                {languages
                    .filter((l) => l.code !== currentLanguage)
                    .map((l) => (
                        <MenuItem key={l.code} onClick={(e) => changeLanguage(l.code)}>
                            <span className={`fi ${l.css}`}></span><Typography sx={{ml: 1}}>{l.label}</Typography>
                        </MenuItem>
                    ))}
            </Menu>
        </Box>
    );
}

export default LanguageSwitcher;
