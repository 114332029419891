import {Backdrop, CircularProgress} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {verifyEmail} from "../../features/auth/authActions";

export default function VerifyEmail() {
    const {key} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation(["translation", "error"])
    const dispatch = useDispatch();
    const {pending, isSuccess, isError, error} = useSelector((state) => state.auth)

    useEffect(() => {
        if (pending) {
            dispatch(verifyEmail(key));
        } else if (isSuccess['verifyEmail']) {
            enqueueSnackbar(
                t("verify.success"), {variant: "success", preventDuplicate: true}
            );
            navigate("/");
        } else if (isError['verifyEmail']) {
            enqueueSnackbar(t(`error:${error.data.message}`), {variant: "error", preventDuplicate: true});
            navigate("/");
        }
    }, [isSuccess, isError, error]);

    return (
        <Backdrop
            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
}
