import {apiSlice} from "../api/apiSlice";

export const adminSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getWaitlistEntries: builder.query({
            query: () => "/beta/waitlist",
            providesTags: ["WAITLIST"]
        }),
        getBetaInvites: builder.query({
            query: () => "/beta/invitekeys",
            providesTags: ["INVITES"]
        }),
        addBetaInvite: builder.mutation({
            query: (invite) => ({
                url: "/beta/invitekeys",
                method: "POST",
                body: invite
            }),
            invalidatesTags: ["INVITES", "WAITLIST"]
        }),
        revokeBetaInvite: builder.mutation({
            query: (id) => ({
                url: `/beta/invitekeys/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["INVITES"]
        })
    })
});

export const {
    useGetWaitlistEntriesQuery,
    useGetBetaInvitesQuery,
    useAddBetaInviteMutation,
    useRevokeBetaInviteMutation
} = adminSlice;