import { useTheme } from "@emotion/react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useGetClientStatsQuery } from "../aggregates/aggregateSlice";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/authSlice";
import { useTranslation } from "react-i18next";

export default function ClientStatisticsCard({ clientId }) {
  const theme = useTheme();
  const user = useSelector(selectCurrentUser);
  const { t } = useTranslation();

  const { data, isLoading } = useGetClientStatsQuery(clientId);

  if (isLoading) {
    return (
      <Card
        elevation={4}
        sx={{
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderBottom: `5px solid ${theme.palette.success.main}`,
          borderRadius: "10px",
        }}
      >
        <CardHeader title={t("client:ltv.title")} />
        <CardContent>
          <Skeleton variant="text" animation="pulse" />
        </CardContent>
      </Card>
    );
  } else if (!data) {
    return <></>;
  } else {
    return (
      <Card
        elevation={4}
        sx={{
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderBottom: `5px solid ${theme.palette.success.main}`,
          borderRadius: "10px",
        }}
      >
        <CardHeader title={t("client:ltv.title")} />
        <CardContent>
          <Stack spacing={2}>
            <Box>
              <Typography variant="h6">
                {t("client:ltv.total.income")}
              </Typography>
              <Typography variant="h5" component="span">
                {data.income}{" "}
                <Typography variant="h6" component="span">
                  {user.currency}
                </Typography>
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">{t("client:ltv.total.time")}</Typography>
              <Typography variant="h5" component="span">
                {data.timeSpent.toFixed(2)} {t("hours")}
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    );
  }
}
