import {
  Container,
  List,
  ListItem,
  Typography
} from "@mui/material";
import Loading from "../../mui/pages/Loading";
import { useGetNotesQuery } from "./notesSlice";
import Note from "./Note";
import { useTranslation } from "react-i18next";
import AddNoteForm from "./AddNoteForm";

export default function NoteList({ itemType, itemId }) {
  const { t } = useTranslation();
  const {
    data: notes = [],
    isLoading,
    isSuccess,
  } = useGetNotesQuery({
    type: itemType,
    itemId: itemId,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (<Container><List dense={false}>
    <ListItem divider={false} key={"addId"}>
      <AddNoteForm itemType={itemType} itemId={itemId} />
    </ListItem>
    {isSuccess && notes.length === 0 ? (<ListItem key={"noDataId"}><Typography variant="h6">{t("note:no.data")}</Typography></ListItem>) : notes.map((note, i) => (
        <Note key={note.id} note={note} divider={i+1 < notes.length} />
    ))}
  </List>
  </Container>);
}
