import NoteList from "../notes/NoteList";
import {useOutletContext} from "react-router-dom";
import {Container} from "@mui/material";

export default function ProjectNotesPanel() {
    const [project] = useOutletContext();
    return (
        <Container>
            <NoteList itemType={"PROJECT"} itemId={project.id} />
        </Container>
    );}