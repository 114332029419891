import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteProjectMutation } from "./projectSlice";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";

export default function DeleteProjectButton({ project }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [deleteProject, { isLoading, isError, isSuccess, error }] =
    useDeleteProjectMutation();

  const onDelete = (e) => {
    confirm({
      title: t("project:delete.title"),
      description: t("project:delete.body", { name: project.name }),
      confirmationText: t("project:delete.yes"),
      cancellationText: t("project:delete.no"),
    })
      .then(async () => {
        try {
          await deleteProject(project.id).unwrap();
          navigate("/projects");
        } catch (error) {
          enqueueSnackbar(t(`error:${error.data.message}`), {
            variant: "error",
          });
        }
      })
      .catch(() => {
        // no action, user doesn't want to delete for real
      });
  };

  return isMobile ? (
    <IconButton onClick={onDelete}>
      <DeleteIcon />
    </IconButton>
  ) : (
    <Button
      variant={"contained"}
      color={"error"}
      onClick={onDelete}
      endIcon={<DeleteIcon />}
    >
      {t("project:button.delete")}
    </Button>
  );
}
