import {
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  Skeleton,
  Typography,
} from "@mui/material";
import { useGetProjectsForClientQuery } from "../projects/projectSlice";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";

export default function ClientProjectListCard({ clientId }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data: clientProjects = [], isLoading } =
    useGetProjectsForClientQuery(clientId);
  const listOfProjects = clientProjects.map((project) => (
    <ListItem key={project.id}>
      <Link
        component={RouterLink}
        to={`/projects/${project.id}`}
        color="inherit"
        underline="hover"
      >
        {project.name}
      </Link>
    </ListItem>
  ));

  if (isLoading) {
    <Card
      elevation={4}
      sx={{
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderBottom: `5px solid ${theme.palette.info.main}`,
        borderRadius: "10px",
      }}
    >
      <CardContent>
        <Typography variant={"h6"} component={"div"}>
          {"Proiecte"}
        </Typography>
        <Skeleton variant="text" />
      </CardContent>
    </Card>;
  }

  return clientProjects.length === 0 ? (
    ""
  ) : (
    <Card
      elevation={4}
      sx={{
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderBottom: `5px solid ${theme.palette.info.main}`,
        borderRadius: "10px",
      }}
    >
      <CardContent>
        <Typography variant={"h6"} component={"div"}>
          {t("client:project.card.title")}
        </Typography>
        <List>{listOfProjects}</List>
      </CardContent>
    </Card>
  );
}
