import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import DividedListItem from "../../../mui/components/DividedListItem";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  List,
  Stack,
} from "@mui/material";
import ClientContactCards from "../ClientContactCards";
import EditClientForm from "../EditClientForm";
import { useState } from "react";
import { useConfirm } from "material-ui-confirm";
import { useDeleteClientMutation } from "../clientSlice";
import ClientProjectListCard from "../ClientProjectListCard";
import ClientStatisticsCard from "../ClientStatisticsCard";
import { enqueueSnackbar } from "notistack";

export default function ClientDetailsTab() {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const onEditDialogClose = (e) => setEditDialogOpen(false);
  const confirm = useConfirm();
  const { t } = useTranslation("client");
  const [client] = useOutletContext();
  const navigate = useNavigate();
  const [deleteClient, { isError: isDeleteError, error: deleteError }] =
    useDeleteClientMutation();

  const onDelete = (id) => {
    confirm({
      title: t("client:delete.title"),
      description: t("client:delete.body", { name: client.name }),
      confirmationText: t("client:delete.yes"),
      cancellationText: t("client:delete.no"),
    })
      .then(async () => {
        await deleteClient(id)
          .unwrap()
          .then((data) => {
            navigate("/clients");
          })
          .catch((error) => {
            const errorMessage =
              error.status === 400
                ? t("error:client.delete.inuse")
                : t("error:client.delete.generic");

            enqueueSnackbar(errorMessage, {
              variant: "error",
              hideIconVariant: true,
            });
          });
      })
      .catch(() => {
        // no action, user doesn't want to delete for real
      });
  };

  let contactInfo = "";

  if (client.type === "INDIVIDUAL") {
    contactInfo = (
      <>
        <DividedListItem
          primary={t("label.email")}
          secondary={
            <Link href={`mailto:${client.contacts[0].email}`}>
              {client.contacts[0].email}
            </Link>
          }
        />
        <DividedListItem
          primary={t("label.phone")}
          secondary={
            client.contacts[0].phoneNo ? (
              <Link href={`tel:${client.contacts[0].phoneNo}`}>
                {client.contacts[0].phoneNo}
              </Link>
            ) : (
              t("value.unavailable")
            )
          }
        />
      </>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card elevation={4} sx={{ borderRadius: 6 }}>
            <CardContent sx={{ p: 0 }}>
              <List>
                <DividedListItem
                  primary={t("label.name")}
                  secondary={client.name}
                />
                <DividedListItem
                  primary={t("label.type")}
                  secondary={t(client.type)}
                />
                {client.cui ? (
                  <DividedListItem
                    primary={t("label.cui.long")}
                    secondary={client.cui}
                  />
                ) : (
                  ""
                )}
                {client.recom ? (
                  <DividedListItem
                    primary={t("label.recom")}
                    secondary={client.recom}
                  />
                ) : (
                  ""
                )}

                <DividedListItem
                  primary={t("label.address")}
                  secondary={client.address}
                />
                {contactInfo}
              </List>
            </CardContent>
            <CardActions
              disableSpacing
              sx={{
                alignSelf: "stretch",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}
            >
              <Button size="small" onClick={(e) => setEditDialogOpen(true)}>
                {t("translation:button.edit.verb")}
              </Button>
              <Button size="small" onClick={(e) => onDelete(client.id)}>
                {t("translation:button.delete.verb")}
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack spacing={4}>
            <ClientProjectListCard clientId={client.id} />
            <ClientStatisticsCard clientId={client.id} />
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ mt: 5, mb: 10 }}>
        <Stack spacing={2} direction={"row"}></Stack>

        {client.type === "INDIVIDUAL" && client.contacts.length === 1 ? (
          ""
        ) : (
          <ClientContactCards clientId={client.id} contacts={client.contacts} />
        )}
      </Box>

      {editDialogOpen ? (
        <EditClientForm
          open={editDialogOpen}
          handleClose={onEditDialogClose}
          client={client}
        />
      ) : (
        ""
      )}
    </>
  );
}
