import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Link,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import { useTheme } from "@emotion/react";
import { useEffect, useMemo, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { Link as RouterLink } from "react-router-dom";
import { blue } from "@mui/material/colors";
import { Trans, useTranslation } from "react-i18next";
import i18next from "../../../i18n";
import {
  useEndWorkSessionMutation,
  useGetWorkSessionQuery,
  useStartWorkSessionMutation,
} from "../../../features/work-sessions/workSessionSlice";
import { Controller, useForm } from "react-hook-form";

export default function WorkSessionControlCard({ projectId, onComplete }) {
  const [showCompleteDialog, setShowCompleteDialog] = useState(false);

  const theme = useTheme();
  const [error, setError] = useState(null);
  const [isCurrent, setIsCurrent] = useState(null);
  const { t } = useTranslation(["project", "translation"]);
  const [adapterLocale, setAdapterLocale] = useState("en");

  const {
    data: workSession = {},
    isLoading,
    isSuccess,
    isError,
    error: getWkSessionError,
  } = useGetWorkSessionQuery();
  const [
    startWorkSession,
    { isError: isStartError, isSuccess: isStartSuccess, error: startError },
  ] = useStartWorkSessionMutation();
  const [
    endWorkSession,
    {
      isError: isCompleteError,
      isSuccess: isCompleteSuccess,
      error: completeError,
    },
  ] = useEndWorkSessionMutation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      task: "",
    },
  });
  const onClose = () => {
    setShowCompleteDialog(false);
    reset();
  };

  useEffect(() => {
    let lang = i18next.resolvedLanguage;
    let normalizedLang = lang === "ro" ? "ro" : "en";
    setAdapterLocale(normalizedLang);
  }, []);

  useEffect(() => {
    if (isSuccess && workSession && workSession.project) {
      setIsCurrent(workSession.project.id === projectId);
    }

    if (isError) {
      const status = getWkSessionError.status;
      if (status !== 404) {
        setError(getWkSessionError.data.message);
      } else {
        setIsCurrent(false);
      }
    }

    if (isStartError) {
      setError(startError.data.message);
    } else if (isStartSuccess) {
      setIsCurrent(true);
    }

    if (isCompleteError) {
      setError(completeError.data.message);
    } else if (isCompleteSuccess) {
      onClose();
    }
  }, [
    isSuccess,
    isError,
    isStartSuccess,
    isStartError,
    isCompleteSuccess,
    isCompleteError,
    completeError,
    startError,
    getWkSessionError,
    projectId,
    workSession,
  ]);

  let borderColor = theme.palette.primary.main;

  if (error !== null) {
    borderColor = theme.palette.error.main;
  }

  const handleStart = async (e) => {
    await startWorkSession({ projectId });
  };

  const handleComplete = async (data) => {
    const id = workSession.id;
    await endWorkSession({
      id: id,
      payload: { sessionId: id, task: data.task },
    });
  };

  const errorContent = (
    <Alert severity={"error"} variant={"outlined"} title={t("error.generic")}>
      <Typography>{error}</Typography>
    </Alert>
  );

  const skeletonContent = (
    <Stack direction={"row"} spacing={4}>
      <Box>
        <Typography variant={"h6"}>
          <Skeleton variant={"text"} width={250} />
        </Typography>

        <Typography>
          <Skeleton variant={"text"} width={100} />
        </Typography>
      </Box>
      <Box>
        <Skeleton
          variant={"circular"}
          animation={"wave"}
          width={65}
          height={65}
        />
      </Box>
    </Stack>
  );

  const noWorkSessionContent = (
    <Stack direction={"row"} spacing={4}>
      <Box>
        <Typography variant={"h6"} component={"div"}>
          {t("tracker.inactive.title")}
        </Typography>
        <Typography>{t("tracker.inactive.subtitle")}</Typography>
      </Box>
      <Box>
        <IconButton onClick={handleStart}>
          <PlayArrowIcon fontSize={"large"} />
        </IconButton>
      </Box>
    </Stack>
  );

  let cardContent = useMemo(() => {
    if (isLoading === true) {
      return skeletonContent;
    } else {
      if (error !== null) {
        return errorContent;
      } else if (workSession === null || !workSession.project) {
        return noWorkSessionContent;
      } else if (workSession.project.id === projectId) {
        return (
          <Stack direction={"row"} spacing={10}>
            <Box>
              <>
                <Typography variant={"h6"}>
                  {t("tracker.working.since")}{" "}
                </Typography>
                <Typography variant={"h5"} component={"div"}>
                  <ReactTimeAgo
                    date={workSession.createdAt * 1000}
                    timeStyle="round"
                    locale={adapterLocale}
                  />
                </Typography>
              </>
            </Box>
            <Box>
              <Box sx={{ m: 1, position: "relative" }}>
                <Fab
                  aria-label="save"
                  color="light"
                  onClick={() => setShowCompleteDialog(true)}
                >
                  <StopIcon />
                </Fab>
                <CircularProgress
                  size={68}
                  sx={{
                    color: blue,
                    position: "absolute",
                    top: -6,
                    left: -6,
                    zIndex: 1,
                  }}
                />
              </Box>
            </Box>
          </Stack>
        );
      } else {
        return (
          <Box>
            <Typography variant={"subtitle1"}>
              <Trans
                t={t}
                i18nKey={"tracker.session.other"}
                values={{ name: workSession.project.name }}
              >
                Currently working on the{" "}
                <Link
                  to={`/projects/${workSession.project.id}`}
                  component={RouterLink}
                  replace
                >
                  {workSession.project.name}
                </Link>{" "}
                project
              </Trans>
            </Typography>
          </Box>
        );
      }
    }
  }, [isLoading, error, workSession, isCurrent, projectId, adapterLocale]);

  return (
    <Card
      sx={{
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderBottom: `5px solid ${borderColor}`,
        borderRadius: "10px",
      }}
    >
      <CardContent>{cardContent}</CardContent>
      <Dialog open={showCompleteDialog} onClose={onClose}>
        <DialogTitle>{t("tracker.task.title")}</DialogTitle>
        <form noValidate onSubmit={handleSubmit(handleComplete)}>
          <DialogContent>
            <Controller
              name="task"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("error:field.error.required"),
                },
                validate: (value) =>
                  !!value.trim() || t("error:field.error.required"),
              }}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <TextField
                  fullWidth
                  label={t("tracker.task.field")}
                  variant={"filled"}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors["task"]}
                  helperText={errors["task"] ? errors["task"].message : ""}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t("translation:button.cancel")}</Button>
            <Button type="submit">{t("translation:button.save")}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Card>
  );
}
