import {
  Card,
  CardContent,
  Grid,
  Link,
  List,
  Skeleton,
  Stack,
} from "@mui/material";
import DividedListItem from "../DividedListItem";
import AmountUsedCard from "../poc/AmountUsedCard";
import TabPanel from "../TabPanel";
import moment from "moment";
import WorkSessionControlCard from "../poc/WorkSessionControlCard";
import { useTranslation } from "react-i18next";
import { useOutletContext, Link as RouterLink } from "react-router-dom";

export default function BasicDetailsTabPanel() {
  const { t } = useTranslation("project");
  const [project] = useOutletContext();
  const clientItem =
    project && project.client && project.client.id ? (
      <Link
        component={RouterLink}
        to={`/clients/${project.client.id}`}
        color="inherit"
        underline="hover"
      >
        {project.client.name}
      </Link>
    ) : (
      t("fields.client.nodata")
    );

  return (
    <TabPanel value={"details"} index={"details"}>
      <Grid container maxWidth={"lg"} spacing={4}>
        <Grid item xs={12} md={7}>
          <Card elevation={4} sx={{ borderRadius: 6 }}>
            {/*<CardHeader title={t("step.label.details")} titleTypographyProps={{variant: "h6"}}/>*/}
            <CardContent sx={{ p: 0 }}>
              <List>
                <DividedListItem
                  primary={t("fields.description.label")}
                  secondary={project.description}
                />
                <DividedListItem
                  primary={t("fields.status.label")}
                  secondary={t(
                    "project.status." + project.status.statusValue,
                    project.status.label
                  )}
                />
                <DividedListItem
                  primary={t("fields.client.label")}
                  secondary={clientItem}
                />
                <DividedListItem
                  primary={t("fields.currency.label")}
                  secondary={project.currency}
                />
                <DividedListItem
                  primary={t("fields.time.label")}
                  secondary={`${project.estimatedHours} hours`}
                />
                <DividedListItem
                  primary={t("fields.price.label")}
                  secondary={`${project.priceRequested} ${project.currency}`}
                />
                <DividedListItem
                  primary={t("fields.deadline.label")}
                  secondary={moment(project.deadline).format("DD-MMM-YYYY")}
                  last
                />
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack direction={"column"} spacing={4}>
            <AmountUsedCard
              unit={project.currency}
              used={project.actualCost}
              total={project.priceRequested}
              title={t("card.title.expenses")}
              url={`/projects/${project.id}/expenses`}
            />
            <AmountUsedCard
              unit={t("hours")}
              used={project.timeSpent}
              total={project.estimatedHours}
              title={t("card.title.time")}
              url={`/projects/${project.id}/time`}
            />
            {project.id !== undefined ? (
              <WorkSessionControlCard projectId={project.id} />
            ) : (
              <Skeleton variant={"rectangular"} width={400} height={100} />
            )}
          </Stack>
        </Grid>
      </Grid>
    </TabPanel>
  );
}
