import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Add, More } from "@mui/icons-material";
import Project from "./Project";
import { useTranslation } from "react-i18next";
import { useGetProjectsQuery } from "../../features/projects/projectSlice";

export default function ActiveProjectsCard() {
  const { data: projects = [], isLoading } = useGetProjectsQuery();

  const activeProjects = !isLoading
    ? projects.filter(
        (p) =>
          p.status.statusValue !== "ON_HOLD" &&
          p.status.statusValue !== "COMPLETED"
      )
    : [];

  const { t } = useTranslation(["translation", "tooltips"]);

  const hasProjects = activeProjects && activeProjects.length > 0;

  const skeleton = (
    <Grid container maxWidth={"lg"} spacing={2} sx={{ mt: 1 }}>
      <Grid item>
        <Skeleton variant={"rectangular"} width={150} height={150} />
      </Grid>
      <Grid item>
        <Skeleton variant={"rectangular"} width={150} height={150} />
      </Grid>
      <Grid item>
        <Skeleton variant={"rectangular"} width={150} height={150} />
      </Grid>
    </Grid>
  );

  return (
    <Card>
      <CardHeader title={t("projects.title.active")} />
      <CardContent sx={{ position: "relative" }}>
        {isLoading ? (
          skeleton
        ) : (
          <Grid container maxWidth={"lg"} spacing={2} sx={{ mt: 1 }}>
            {!hasProjects ? (
              <Grid item>
                <Typography variant={"h6"}>
                  {t("projects.error.no_active")}{" "}
                  <Link to={"/projects/add"}>
                    {t("projects.actions.create")}
                  </Link>
                </Typography>
              </Grid>
            ) : (
              activeProjects.map((p) => (
                <Grid item key={p.id}>
                  <Project project={p} />{" "}
                </Grid>
              ))
            )}
          </Grid>
        )}

        <Box sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
          <SpeedDial
            direction={hasProjects ? "up" : "left"}
            ariaLabel={t("projects.dash.fab", { ns: "tooltips" })}
            sx={{ position: "absolute", bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
          >
            <SpeedDialAction
              key={"add"}
              icon={
                <Link to={"/projects/add"}>
                  <Add />
                </Link>
              }
              tooltipTitle={t("projects.add", { ns: "tooltips" })}
              sx={{ pt: 1 }}
            />

            <SpeedDialAction
              key={"more"}
              icon={
                <Link to={"/projects"}>
                  <More />
                </Link>
              }
              tooltipTitle={t("projects.show.all", { ns: "tooltips" })}
              sx={{ pt: 1 }}
            />
          </SpeedDial>
        </Box>
      </CardContent>
    </Card>
  );
}
