import ReactGA from "react-ga4";

export const initGA = () => {
    if (process.env.NODE_ENV === "production" && process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
};

export const initUserGA = (userId) => {
    if (process.env.NODE_ENV === "production" && process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
            gaOptions: {
                userId: userId
            }
        });
    }
};