import {IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import {SearchOutlined} from "@mui/icons-material";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSearchByCuiMutation} from "../anaf/anafSlice";

const normalize = (value) => {

    if (!/^\d+$/.test(value)) {
        return "";
    }
    if (value.toLowerCase().startsWith("ro")) {
        return value.substr(2);
    }

    return value;

}

export default function AnafPoweredCuiField({
                                                initialValue,
                                                onChange,
                                                onDataReceived,
                                            }) {
    const {t} = useTranslation();
    const [cui, setCui] = useState(initialValue);
    const [error, setError] = useState(false);
    const [helper, setHelper] = useState(t("anaf.search.help"))
    const [normalizedCui, setNormalizedCui] = useState("");
    const [searchByCui, {isLoading}] = useSearchByCuiMutation('searchByCui');

    const handleKeyPress = (e) => {
        if ( e.key === "Enter" && !isLoading ){
            e.preventDefault();
            performAnafLookup();
        }
    }

    const performAnafLookup = async () => {
        if (!normalizedCui || normalizedCui.length === 0) {
            return;
        }

        await searchByCui(normalizedCui).unwrap()
            .then(data => {
                setError(false);

                onDataReceived({
                    managed: true,
                    name: data.name,
                    address: data.address,
                    recom: data.regCom,
                    cui: data.cui,
                    phone: data.phone
                });
            })
            .catch(error => {
                setHelper(t("anaf.search.no_results"));
                setError(true);
                onDataReceived({
                    managed: false,
                    name: "",
                    address: "",
                    recom: "",
                    cui: cui,
                    phone: ""
                });
            })
    };

    const updateCui = (e) => {
        const val = e.target.value;
        setCui(val);
        setNormalizedCui(normalize(val));
        onChange(val);
    };

    return (
        <TextField
            fullWidth
            label={t("client:label.cui.long")}
            value={cui}
            onChange={updateCui}
            helperText={helper}
            error={error}
            onKeyDown={(e) => e.stopPropagation()}
            onKeyPress={handleKeyPress}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={performAnafLookup}
                            edge="end"
                        >
                            <SearchOutlined/>
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}
