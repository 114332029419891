import {Avatar, Box, Divider, IconButton, Menu, MenuItem, MenuList, Stack, Tooltip, Typography,} from "@mui/material";
import {NavLink} from "react-router-dom";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../features/auth/authSlice";

export default function UserMenu() {
    const user = useSelector(selectCurrentUser);
    const {t} = useTranslation();

    const settings = [
        {
            label: t("menu.user.settings"),
            url: "/account",
            icon: <PersonOutlineOutlinedIcon/>,
        },
        {
            label: t("menu.user.projects"),
            url: "/projects",
            icon: <FeaturedPlayListOutlinedIcon/>,
        },
        {
            label: t("menu.user.clients"),
            url: "/clients",
            icon: <ContactsOutlinedIcon/>,
        },
        {
            label: t("menu.user.issues"),
            url: "https://github.com/civascu/foreman_feedback/issues/new",
            icon: <FeedbackOutlinedIcon/>,
        },
        {
            label: t("menu.user.signout"),
            url: "/logout",
            icon: <LogoutOutlinedIcon/>,
        },
    ];

    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    return (
        <Box sx={{flexGrow: 0}}>
            <Tooltip title="">
                <IconButton
                    onClick={handleOpenUserMenu}
                    onMouseOver={handleOpenUserMenu}
                    sx={{p: 0}}
                >
                    <Avatar alt={`${user.firstName} ${user.lastName}`}>
                        {user.firstName[0]}
                    </Avatar>
                </IconButton>
            </Tooltip>

            <Menu
                sx={{mt: "45px"}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                MenuListProps={{onMouseLeave: handleCloseUserMenu}}
            >
                <Box sx={{padding: "12px 16px"}}>
                    <Stack>
                        <Box>
                            <Typography variant={"h6"}>
                                {user.firstName} {user.lastName}
                            </Typography>{" "}
                        </Box>
                        <Box>
                            <Typography variant={"subtitle2"}>{user.email}</Typography>
                        </Box>
                    </Stack>
                </Box>
                <Divider/>
                <MenuList>
                    {settings.map((setting) => (
                        <MenuItem
                            key={setting.label}
                            onClick={handleCloseUserMenu}
                            component={NavLink}
                            to={`${setting.url}`}
                            target={setting.url.startsWith("https") ? "_blank" : ""}
                        >
                            {setting.icon}
                            <Typography textAlign="center" sx={{ml: 1}}>
                                {setting.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    );
}
