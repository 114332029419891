import {Box, Card, CardContent, Container, Grid, Typography,} from "@mui/material";
import SetPassword from "../SetPassword";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import {useUpdatePasswordMutation} from "../../../features/auth/authApiSlice";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../features/auth/authSlice";

export default function AccountUpdatePasswordPanel() {
    const [formValid, setFormValid] = useState(false);
    const [password, setPassword] = useState("");
    const user = useSelector(selectCurrentUser);
    const {t} = useTranslation(['account', 'translation', 'error']);

    const [updatePassword, {isLoading, isSuccess, isError, error}] = useUpdatePasswordMutation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {password: password};
        await updatePassword({id: user.id, payload: payload})
    };

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar(t("msg.notif.pwd.updated"), {variant: "success"});
        }
        if (isError) {
            enqueueSnackbar(t(`error:${error.data.message}`), {variant: "error"});
        }
    }, [isSuccess, isError])

    const onPwdChange = (value, isValid) => {
        setFormValid(isValid);
        setPassword(value);
    };

    return (
        <Container>
            <Card>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <Typography component={"h1"} variant={"h6"}>
                                {t("section.update.password.title")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleSubmit}
                                sx={{mt: 3}}
                            >
                                <Grid container spacing={2}>
                                    <SetPassword onValidPassword={onPwdChange}/>
                                </Grid>
                                <Box textAlign={"right"}>
                                    <LoadingButton
                                        disabled={!formValid}
                                        type={"submit"}
                                        loading={isLoading}
                                        loadingPosition="center"
                                        variant="contained"
                                        sx={{mt: 3, mb: 2}}
                                    >
                                        {t("button.update.pwd")}
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
}
