import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  Typography,
} from "@mui/material";
import {
  useDeleteSupplierMutation,
  useGetSupplierDetailsQuery,
} from "./supplierSlice";
import Loading from "../../mui/pages/Loading";
import { useTranslation } from "react-i18next";
import DividedListItem from "../../mui/components/DividedListItem";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";

export default function SupplierDetails() {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { supplierId } = useParams();
  const [deleteSupplier, { isLoading: isDeleting }] =
    useDeleteSupplierMutation();
  const navigate = useNavigate();

  const { data: supplier = {}, isLoading } =
    useGetSupplierDetailsQuery(supplierId);

  const onDelete = (id) => {
    confirm({
      title: t("supplier:delete.title"),
      description: t("supplier:delete.body", { name: supplier.name }),
      confirmationText: t("client:delete.yes"),
      cancellationText: t("client:delete.no"),
    })
      .then(async () => {
        await deleteSupplier(id)
          .unwrap()
          .then((data) => {
            navigate("/");
          })
          .catch((err) => {
            enqueueSnackbar(t(`error:${err.data.message}`), {
              variant: "error",
              preventDuplicate: true,
            });
          });
      })
      .catch((e) => {
        //no action, user doesn't want to delete
      });
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <Box>
          <Typography variant="h4">{supplier.name}</Typography>

          <Typography variant="caption">
            {supplier.public ? (
              <Alert severity={"info"} sx={{ mt: 2 }}>
                {t("supplier:status.public")}
              </Alert>
            ) : (
              ""
            )}
          </Typography>
        </Box>

        <Box sx={{ mt: 5, borderBottom: 0, borderColor: "divider" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Card elevation={4} sx={{ borderRadius: 6 }}>
                <CardContent sx={{ p: 0 }}>
                  <List>
                    <DividedListItem
                      primary={t("client:label.name")}
                      secondary={supplier.name}
                    />
                    {supplier.cui ? (
                      <DividedListItem
                        primary={t("client:label.cui.long")}
                        secondary={supplier.cui}
                      />
                    ) : (
                      ""
                    )}
                    {supplier.recom ? (
                      <DividedListItem
                        primary={t("client:label.recom")}
                        secondary={supplier.recom}
                      />
                    ) : (
                      ""
                    )}

                    <DividedListItem
                      primary={t("client:label.address")}
                      secondary={supplier.address}
                    />
                    {supplier.email ? (
                      <DividedListItem
                        primary={t("client:label.email")}
                        secondary={supplier.email}
                      />
                    ) : (
                      ""
                    )}
                    {supplier.phone ? (
                      <DividedListItem
                        primary={t("client:label.phone")}
                        secondary={supplier.phone}
                      />
                    ) : (
                      ""
                    )}
                    {supplier.website ? (
                      <DividedListItem
                        primary={t("supplier:label.website")}
                        secondary={supplier.website}
                      />
                    ) : (
                      ""
                    )}
                  </List>
                </CardContent>
                <CardActions
                  disableSpacing
                  sx={{
                    alignSelf: "stretch",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                  }}
                >
                  {supplier.public ? (
                    ""
                  ) : (
                    <Button size="small" onClick={(e) => onDelete(supplier.id)}>
                      {t("translation:button.delete.verb")}
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}
