import {
  Box,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  MenuList,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import StopIcon from "@mui/icons-material/Stop";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { CheckCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useGetProjectsQuery } from "../../../features/projects/projectSlice";
import {
  useEndWorkSessionMutation,
  useGetWorkSessionQuery,
  useStartWorkSessionMutation,
} from "../../../features/work-sessions/workSessionSlice";
import { Controller, useForm } from "react-hook-form";

export default function WorkControl() {
  const [error, setError] = useState();

  const { data: projects = [], isLoading } = useGetProjectsQuery();
  const {
    data: workSession = {},
    isLoading: isWkSessionLoading,
    isSuccess,
    isError,
    error: getWkSessionError,
  } = useGetWorkSessionQuery();
  const [
    startWorkSession,
    { isError: isStartError, isSuccess: isStartSuccess, error: startError },
  ] = useStartWorkSessionMutation();
  const [
    endWorkSession,
    {
      isError: isCompleteError,
      isSuccess: isCompleteSuccess,
      error: completeError,
    },
  ] = useEndWorkSessionMutation();

  const [anchorElProjects, setAnchorElProjects] = useState(null);
  const [anchorElTask, setAnchorElTask] = useState(null);

  const { t } = useTranslation(["timelog"]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      task: "",
    },
  });

  const handleOpenProjectMenu = (event) => {
    setAnchorElProjects(event.currentTarget);
  };

  const handleCloseProjectMenu = () => {
    setAnchorElProjects(null);
  };

  const handleOpenTaskMenu = (event) => {
    setAnchorElTask(event.currentTarget);
  };

  const handleCloseTaskMenu = () => {
    setAnchorElTask(null);
    reset();
  };

  const handleStart = async (p) => {
    handleCloseProjectMenu();
    await startWorkSession({ projectId: p.id });
  };

  const handleComplete = async (data) => {
    handleCloseTaskMenu();
    await endWorkSession({
      id: workSession.id,
      payload: { sessionId: workSession.id, task: data.task },
    });
  };

  useEffect(() => {
    if (isError) {
      setError(getWkSessionError.data.message);
    }

    if (isStartError) {
      setError(startError.data.message);
    }

    if (isCompleteError) {
      setError(completeError.data.message);
    }
  }, [isError, isStartError]);

  if (isLoading || isWkSessionLoading) {
    return <Skeleton variant={"circular"} width={25} height={25} />;
  }

  if (error) {
    return "";
  }

  if (workSession !== null && workSession.project) {
    return (
      <Tooltip title={workSession.project.name} placement={"left"}>
        <Box
          sx={{ border: "1px solid #DDDDDD", pl: "5px" }}
          textAlign={"center"}
        >
          <ReactTimeAgo
            tooltip={false}
            date={workSession.createdAt * 1000}
            timeStyle="twitter"
            locale={i18n.resolvedLanguage}
          />
          <IconButton onClick={handleOpenTaskMenu}>
            <StopIcon />
          </IconButton>
          <Menu
            sx={{ mt: "25px" }}
            id="menu-appbar"
            anchorEl={anchorElTask}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElTask)}
            onClose={handleCloseTaskMenu}
          >
            <Box sx={{ pl: 1, pr: 1, width: 350 }}>
              <form noValidate onSubmit={handleSubmit(handleComplete)}>
                <Controller
                  name={"task"}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t("error:field.error.required"),
                    },
                    validate: (value) =>
                      !!value.trim() || t("error:field.error.required"),
                  }}
                  render={({ field: { name, value, onChange, onBlur } }) => (
                    <TextField
                      variant={"standard"}
                      label={t("field.task.label")}
                      fullWidth
                      size={"small"}
                      autoFocus={true}
                      focused={true}
                      // onKeyDown={(e) => e.stopPropagation()}
                      // onKeyPress={handleKeyPress}
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors["task"]}
                      helperText={errors["task"] ? errors["task"].message : ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="submit task"
                              edge="end"
                              type="submit"
                            >
                              <CheckCircle />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </form>
            </Box>
          </Menu>
        </Box>
      </Tooltip>
    );
  }

  if (projects.length === 0) {
    return <></>;
  }

  return (
    <Box>
      <IconButton onClick={handleOpenProjectMenu}>
        <PlayArrowIcon color={"primary"} sx={{ p: 0 }} />
      </IconButton>
      <Menu
        sx={{ mt: "25px" }}
        id="menu-appbar"
        anchorEl={anchorElProjects}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorElProjects)}
        onClose={handleCloseProjectMenu}
      >
        <MenuList>
          {projects.map((p) => (
            <MenuItem key={p.id} onClick={() => handleStart(p)}>
              {p.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
}
