import {
  Link,
  Outlet,
  Link as RouterLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import Loading from "../../mui/pages/Loading";
import { useGetClientQuery } from "./clientSlice";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Copyright from "../../mui/components/Copyright";
import { useRouteMatch } from "../../utils";

export default function ClientSingleView() {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("client");
  const {
    data: client,
    isLoading,
    isFetching,
    isError,
  } = useGetClientQuery(clientId);

  const tabs = [
    {
      label: t("project:tabs.details.label"),
      route: "/clients/:id",
      value: "details",
      path: `/clients/${clientId}`,
      disabled: false,
    },
    {
      label: t("client:tabs.notes.label"),
      route: "/clients/:id/notes",
      value: "notes",
      path: `/clients/${clientId}/notes`,
      disabled: false,
    },
    {
      label: t("client:tabs.activity.label"),
      route: "/clients/:id/activity",
      value: "activity",
      path: `/clients/${clientId}/activity`,
      disabled: false,
    },
  ];

  const routeMatch = useRouteMatch([
    "/clients/:id/activity",
    "/clients/:id/notes",
    "/clients/:id",
  ]);

  const currentVal = routeMatch?.pattern?.path;
  const matchingTab = tabs.find((t) => t.route === currentVal);
  const tab = matchingTab.value;

  if (isLoading || isFetching) {
    return <Loading />;
  } else {
    if (!client) {
      return navigate("*");
    }

    return (
      <>
        <Box>
          <Typography variant="h4">{client.name}</Typography>
          <Typography variant="caption">
            <Link component={RouterLink} to="/clients">
              {t("page.clients.list.link")}
            </Link>
          </Typography>
        </Box>
        <Box sx={{ mt: 5, borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            aria-label="client details"
            indicatorColor="primary"
            value={tab}
            variant={"scrollable"}
            scrollButtons={true}
            allowScrollButtonsMobile
          >
            {tabs.map((t) => (
              <Tab
                key={t.value}
                label={t.label}
                value={t.value}
                to={t.path}
                component={Link}
                disabled={t.disabled}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ mt: 5, mb: 10 }}>
          {!isLoading && !isError ? <Outlet context={[client]} /> : ""}
        </Box>

        <Copyright />
      </>
    );
  }
}
