import {LoadingButton} from "@mui/lab";
import {
    Box,
    Card,
    CardContent,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import StatusPreferenceSection from "../../../features/project-statuses/StatusPreferenceSection";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentUser, userInfoReceived} from "../../../features/auth/authSlice";
import {useUpdateProfileMutation} from "../../../features/auth/authApiSlice";

export default function AccountPreferencesPanel() {
    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const [updateProfile, {isLoading}] = useUpdateProfileMutation();
    const {t} = useTranslation(["account", "translation", "project"]);
    const [currency, setCurrency] = useState(user.currency);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {currency: currency};
        try {
            let result = await updateProfile({id: user.id, payload: payload})
            dispatch(userInfoReceived(result.data));
            enqueueSnackbar(t("msg.notif.pref.update"), {variant: "success"});
        } catch (error) {
            enqueueSnackbar(error.metadata.details, {variant: "error"});
        }
    };

    return (
        <Container>
            <Card>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <Typography component={"h1"} variant={"h6"}>
                                {t("section.units.title")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleSubmit}
                                sx={{mt: 3}}
                            >
                                <Stack spacing={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="currency-select-label">
                                            {t("pref.currency.label")}
                                        </InputLabel>
                                        <Select
                                            labelId="currency-select-label"
                                            id="currency-select"
                                            value={currency}
                                            label={t("pref.currency.label")}
                                            onChange={(e) => setCurrency(e.target.value)}
                                        >
                                            <MenuItem value={"RON"}>RON</MenuItem>
                                            <MenuItem value={"EUR"}>EUR</MenuItem>
                                            <MenuItem value={"USD"}>USD</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Box textAlign={"right"}>
                                        <LoadingButton
                                            type={"submit"}
                                            loading={isLoading}
                                            loadingPosition="center"
                                            variant="contained"
                                            sx={{mt: 3, mb: 2}}
                                        >
                                            Save
                                        </LoadingButton>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                        <StatusPreferenceSection/>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
}
