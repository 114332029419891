import {Box, IconButton} from "@mui/material";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import {useTheme} from "@emotion/react";
import {useContext} from "react";
import {ColorModeContext} from "../layouts/AppWrapper";

export default function DarkModeMenu() {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);

    return (
        <Box sx={{mr: 2}}>
            <IconButton
                sx={{ml: 1}}
                onClick={colorMode.toggleColorMode}
                color="inherit"
            >
                {theme.palette.mode === "dark" ? (
                    <Brightness7Icon/>
                ) : (
                    <Brightness4Icon/>
                )}
            </IconButton>
        </Box>
    );
}