import { useOutletContext } from "react-router-dom";
import NoteList from "../../notes/NoteList";
import { Container } from "@mui/material";

export default function ClientNotesTab() {
  const [client] = useOutletContext();
  return (
    <Container>
      <NoteList itemType={"CLIENT"} itemId={client.id} />
    </Container>
  );
}
