import {Box, CircularProgress, Typography} from "@mui/material";

export default function CircularProgressWithLabel(props) {
    return <Box sx={{position: 'relative', display: 'inline-flex'}}>
        <CircularProgress variant="determinate" size={"6rem"} {...props} />
        <Box
            sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="h6" component="div" color={props.color}>
                {`${Math.round(props.value)}%`}
            </Typography>
        </Box>
    </Box>
}