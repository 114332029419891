import {useEffect, useMemo, useState} from "react";
import ChartTablePanelSkeleton from "../../mui/components/project_details/ChartTablePanelSkeleton";
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Chart from "react-apexcharts";
import {groupByDate, sortByField} from "../../utils";
import CollectionTableRow from "./CollectionTableRow";
import AddCollectionDialog from "./AddCollectionDialog";
import moment from "moment";
import {useTheme} from "@emotion/react";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {useOutletContext} from "react-router-dom";
import {useGetProjectIncomeQuery} from "./incomeSlice";

export default function ProjectCollectionsPanel() {
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const theme = useTheme();
    const {t} = useTranslation(["expense", "translation"]);
    const [project] = useOutletContext();

    const {data: collections = [], isLoading} = useGetProjectIncomeQuery(project.id);

    const plottableData = useMemo(() => {
        let temp = collections.map(e => {
            return {
                date: moment(e.creditedAt).locale(i18n.resolvedLanguage).format("DD MMM"),
                value: e.amount.toFixed(2)
            }
        });
        return groupByDate(temp).sort().reverse();
    }, [collections])

    if (isLoading) {
        return <ChartTablePanelSkeleton/>
    } else {

        let chart = {
            options: {
                noData: {
                    text: t("income.nodata"),
                    style: {
                        fontSize: "18px",
                        fontFamily: "Roboto"
                    }
                },
                chart: {
                    id: "income-bar-chart"
                },
                theme: {
                    palette: 'palette1', // upto palette10
                    mode: theme.palette.mode
                },
                xaxis: {
                    categories: collections.length === 0 ? [] : plottableData.map(e => e.date),
                },
                tooltip: {
                    y: {
                        formatter: (value, opts) => {
                            return `${value} ${project.currency}`
                        }
                    }
                }

            },
            series: [{
                name: t("chart.series.name"),
                data: plottableData.length === 0 ? [] : plottableData.map(e => e.value),
            }],
        }

        return (
            <Grid container maxWidth={"xl"} spacing={4}>
                <Grid item xs={12}>
                    <Chart type={"bar"} height={"300"} options={chart.options} series={chart.series}/>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("column.received_on")}</TableCell>
                                    <TableCell>{t("column.value")}</TableCell>
                                    <TableCell>{t("column.representing")}</TableCell>
                                    <TableCell align={"center"}>
                                        <Button onClick={() => setAddDialogOpen(true)}>{t("button.add")}</Button>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {collections.length === 0 ?
                                    <TableRow><TableCell align={"center"} colSpan={4}><Typography variant={"h6"}
                                                                                                  sx={{m: 2}}>{t("income.nodata")}</Typography></TableCell></TableRow> : (
                                        sortByField("creditedAt", collections, "desc").map(c => <CollectionTableRow
                                            item={c}
                                            project={project}
                                            key={c.id}
                                        />))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <AddCollectionDialog open={addDialogOpen} project={project} onClose={() => setAddDialogOpen(false)}/>
                </Grid>
            </Grid>
        );
    }
}