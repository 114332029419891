import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton, InputAdornment, ListItem,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAddNoteMutation } from "./notesSlice";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { enqueueSnackbar } from "notistack";
import { Add } from "@mui/icons-material";

export default function AddNoteForm({ itemType, itemId, icon }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [addNote, { isLoading }] = useAddNoteMutation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: {
      content: "",
    },
  });

  const persist = async (data) => {
    await addNote({
      content: data.content,
      itemType: itemType,
      itemId: itemId,
    })
      .unwrap()
      .then((data) => {
        onClose();
      })
      .catch((error) =>
        enqueueSnackbar(t(`error:${error.data.message}`), { variant: "error" })
      );
  };

  const onClose = (e) => {
    setDialogOpen(false);
    reset();
  };

  return (
    <Box component={"form"} noValidate onSubmit={handleSubmit(persist)} width={true}>
      <Controller
          control={control}
          name={"content"}
          rules={{
            required: {
              value: true,
              message: t("error:field.error.required"),
            },
            validate: (value) =>
                !!value.trim() || t("error:field.error.required"),
          }}
          render={({ field: { name, value, onChange, onBlur } }) => (
              <TextField
                  variant={"standard"}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  multiline
                  label={t("note:add.content.label")}
                  fullWidth
                  rows={4}
                  error={!!errors["content"]}
                  helperText={
                    errors["content"] ? errors["content"].message : ""
                  }
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" sx={{alignItems: 'flex-start'}}>
                          <Button type={"submit"} disabled={!isValid}>{t("button.save")}</Button>
                        </InputAdornment>
                    ),
                  }}

              />
          )}
      />
     </Box>
)

  return (
    <>
      {icon ? (
        <IconButton
          onClick={(e) => setDialogOpen(true)}
          color="primary"
          size={"small"}
        >
          <Add />
        </IconButton>
      ) : (
        <Button onClick={(e) => setDialogOpen(true)}>
          {t("note:button.add.label")}
        </Button>
      )}

      <Dialog
        open={dialogOpen}
        onClose={onClose}
        maxWidth={"md"}
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle>{t("note:dialog.add.title")}</DialogTitle>
        <form noValidate onSubmit={handleSubmit(persist)}>
          <DialogContent>

          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t("button.cancel")}</Button>
            <Button type="submit" disabled={!isValid}>

            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
