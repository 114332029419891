import {Box, Skeleton, Typography} from "@mui/material";

export default function ChartTablePanelSkeleton() {
    return (
        <Box>
            <Skeleton variant={"rounded"} width={"90%"} animation={"wave"} height={"15rem"}/>

            <Skeleton variant={"text"} animation={"wave"} width={"90%"} sx={{mt: 3}}>
                <Typography>.</Typography>
            </Skeleton>
            <Skeleton variant={"text"} animation={"wave"} width={"90%"}>
                <Typography>.</Typography>
            </Skeleton>
            <Skeleton variant={"text"} animation={"wave"} width={"90%"}>
                <Typography>.</Typography>
            </Skeleton>
        </Box>
    )
}