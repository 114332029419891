import {useEffect, useMemo, useRef, useState} from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Chart from "react-apexcharts";
import moment from "moment";
import ExpenseTableRow from "./ExpenseTableRow";

import AddExpenseDialog from "./AddExpenseDialog";
import ChartTablePanelSkeleton from "../../mui/components/project_details/ChartTablePanelSkeleton";
import { useTheme } from "@emotion/react";
import { groupByDate, sortByField } from "../../utils";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useGetProjectExpensesQuery } from "./expenseSlice";

export default function ProjectExpensesPanel() {
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [project] = useOutletContext();
  const container = useRef(null);

  const theme = useTheme();
  const { t } = useTranslation(["expense", "translation", "error"]);

  const {
    data: expenses = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetProjectExpensesQuery(project.id);

  const plottableData = useMemo(() => {
    let temp = expenses.map((e) => {
      return {
        date: moment(e.spentAt)
          .locale(i18n.resolvedLanguage)
          .format("DD-MMM-YYYY"),
        value: e.actualValue,
      };
    });
    return groupByDate(temp).sort();
  }, [expenses]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t(`error:${error.data.message}`), {
        variant: "error",
      });
    }
  });

  // individual expense action handlers

  const onCloseAddDialog = () => {
    setAddDialogOpen(false);
  };

  let chart = {
    options: {
      chart: {
        id: "expense-bar-chart",
      },
      theme: {
        palette: "palette1", // upto palette10
        mode: theme.palette.mode,
      },
      xaxis: {
        categories:
          expenses.length === 0 ? [] : plottableData.map((e) => e.date),
      },
      tooltip: {
        y: {
          formatter: (value, opts) => {
            return `${value} ${project.currency}`;
          },
        },
      },
    },
    series: [
      {
        name: t("chart.series.name"),
        data:
          plottableData.length === 0
            ? []
            : plottableData.map((e) => e.value.toFixed(2)),
      },
    ],
  };

  const actual = isSuccess ? (
    <Grid container maxWidth={"xl"} spacing={4}>
      <Grid item xs={12}>
        <Box maxWidth={"lg"}>
          <Chart
            type={"bar"}
            height={"300"}
            options={chart.options}
            series={chart.series}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell>{t("column.spent_on")}</TableCell>
                <TableCell>{t("column.value")}</TableCell>
                <TableCell>{t("column.supplier")}</TableCell>
                <TableCell>{t("column.item")}</TableCell>
                <TableCell align={"center"}>
                  <Button onClick={() => setAddDialogOpen(true)}>
                    {t("button.add")}
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortByField("spentAt", expenses, "desc").map((expense) => (
                <ExpenseTableRow
                    dialogContainer={container}
                  expense={expense}
                  projectId={project.id}
                  key={expense.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <AddExpenseDialog
          open={addDialogOpen}
          onClose={onCloseAddDialog}
          projectCurrency={project.currency}
          projectId={project.id}
        />
      </Grid>
    </Grid>
  ) : (
    <></>
  );

  return isLoading ? <ChartTablePanelSkeleton /> : actual;
}
