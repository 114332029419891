import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../api/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL + "/api",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.userToken;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
  },
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  tagTypes: [
    "CLIENT",
    "PROJECT",
    "CONTACT",
    "PROJECT_STATUS",
    "EXPENSE",
    "INCOME",
    "NOTIFICATION",
    "AGGREGATE",
    "CLIENT_PROJECTS",
  ],
  endpoints: (builder) => ({}),
});
