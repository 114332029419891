import {
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import {enqueueSnackbar} from "notistack";
import {
    useAddBetaInviteMutation,
    useGetBetaInvitesQuery,
    useGetWaitlistEntriesQuery,
    useRevokeBetaInviteMutation
} from "../../../features/admin/adminSlice";

export default function SuperUserPanel() {
    const [recipientName, setRecipientName] = useState("");
    const [recipientEmail, setRecipientEmail] = useState("");
    const [showInviteDialog, setShowInviteDialog] = useState(false);

    const onClose = (e) => {
        setShowInviteDialog(false);
        setRecipientEmail("");
        setRecipientName("");
    }

    const openInviteSender = (email) => {
        if (email && email.length > 0) {
            setRecipientEmail(email);
        }
        setShowInviteDialog(true);
    }

    const {data: waitlist = [], isLoading: isWaitlistLoading, isError: isWaitlistError} = useGetWaitlistEntriesQuery();
    const {data: invites = [], isLoading: isBetaInvitesLoading, isError: isBetaInvitesError} = useGetBetaInvitesQuery();
    const [addBetaInvite, {isSuccess: isAddSuccess, isError: isAddError, error: addError}] = useAddBetaInviteMutation();
    const [revokeBetaInvite, {
        isSuccess: isRevokeSuccess,
        isError: isRevokeError,
        error: revokeError
    }] = useRevokeBetaInviteMutation();

    useEffect(() => {
            if (isWaitlistError) {
                enqueueSnackbar('Failed to load waitlist', {variant: "error"});
            }

            if (isBetaInvitesError) {
                enqueueSnackbar("Failed to load invites", {variant: "error"});
            }

            if (isAddError) {
                enqueueSnackbar(addError.data.message, {variant: "error"});
            } else if (isAddSuccess) {
                enqueueSnackbar('Invite created', {variant: "success"});
                setRecipientName("");
                setRecipientEmail("");
                setShowInviteDialog(false);
            }

            if (isRevokeError) {
                enqueueSnackbar(revokeError.data.message, {variant: "error"})
            }
        },
        [isBetaInvitesError, isWaitlistError, isAddSuccess, isAddError, isRevokeError])

    const generateBetaInvite = async (e) => {
        e.preventDefault();
        const payload = {
            email: recipientEmail,
            name: recipientName
        };

        await addBetaInvite(payload)

    };

    const revokeInvite = async (i) => {
        await revokeBetaInvite(i.id);
    }
    return (
        <Container>
            <Card>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <Typography component={"h1"} variant={"h6"}>
                                Unused invites
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Invite key</TableCell>
                                        <TableCell>Issued to</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell><Button onClick={e => openInviteSender("")}>Generate invite</Button></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invites.length === 0 ?
                                        <TableRow><TableCell colSpan={4} align={"center"}>No
                                            Data</TableCell></TableRow> :
                                        invites.map(i => {
                                            return (<TableRow key={i.id}>
                                                <TableCell>{i.id}</TableCell>
                                                <TableCell>{i.recipientName} - {i.recipientEmail}</TableCell>
                                                <TableCell>{i.status}</TableCell>
                                                <TableCell>
                                                    {i.status === 'CREATED' ?
                                                        <Button onClick={e => revokeInvite(i)}>Revoke</Button> : ""}
                                                </TableCell>
                                            </TableRow>)
                                        })}
                                </TableBody>
                            </Table>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Typography component={"h1"} variant={"h6"}>
                                Waitlist members
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>E-mail</TableCell>
                                        <TableCell>Invited</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {waitlist && waitlist.length === 0 ?
                                        <TableRow><TableCell colSpan={4} align={"center"}>No Data</TableCell></TableRow>
                                        : waitlist.map(e => {
                                            return (
                                                <TableRow key={e.email}>
                                                    <TableCell>{e.email}</TableCell>
                                                    <TableCell>{e.invited ? <CheckOutlinedIcon/> :
                                                        <HighlightOffOutlinedIcon/>}</TableCell>
                                                    <TableCell>{!e.invited ?
                                                        <Button
                                                            onClick={ev => openInviteSender(e.email)}>Invite</Button> : <>{""}</>}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Dialog open={showInviteDialog} onClose={onClose} maxWidth={"xl"}>
                <DialogTitle>Reset Password</DialogTitle>
                <DialogContent>
                    <Stack
                        spacing={2}
                        component="form"
                        noValidate
                        sx={{mt: 3}}
                        maxWidth={"xl"}
                        onSubmit={generateBetaInvite}
                    >

                        <TextField
                            autoComplete="given-name"
                            name="recipientName"
                            required
                            fullWidth
                            id="recipientName"
                            label="Recipient name"
                            autoFocus
                            value={recipientName}
                            onChange={(e) => setRecipientName(e.target.value)}
                        />

                        <TextField
                            type={"email"}
                            autoComplete="email"
                            name="recipientEmail"
                            required
                            fullWidth
                            id="recipientEmail"
                            label="Recipient email"
                            autoFocus
                            value={recipientEmail}
                            onChange={(e) => setRecipientEmail(e.target.value)}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={generateBetaInvite}>
                        Invite
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>
    );
}