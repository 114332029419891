import { apiSlice } from "../api/apiSlice";

export const aggregateSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNetWorth: builder.query({
      query: ({ startDate, endDate }) =>
        `/aggregates/user/networth?start=${startDate}&end=${endDate}`,
      providesTags: [{ type: "AGGREGATE", id: "NETWORTH" }],
    }),
    getClientStats: builder.query({
      query: (clientId) => `/aggregates/clients/${clientId}/ltv`,
      providesTags: (result, error, arg) => [
        { type: "AGGREGATE", id: `CLIENT-${arg}` },
      ],
    }),
    getExpensesGroupBySupplier: builder.query({
      query: () => `/aggregates/suppliers/top?period=ALL`,
      providesTags: (arg) => [{ type: "AGGREGATE", id: `SUPPLIERS-{arg}` }],
    }),
  }),
});

export const {
  useGetNetWorthQuery,
  useGetClientStatsQuery,
  useGetExpensesGroupBySupplierQuery,
} = aggregateSlice;
