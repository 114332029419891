import {useOutletContext} from "react-router-dom";
import ActivityTimeline from "../activity/ActivityTimeline";
import TabPanel from "../../mui/components/TabPanel";
import {Container} from "@mui/material";

export default function ProjectActivityPanel() {
    const [project] = useOutletContext();

    return (<TabPanel value={"activity"} index={"activity"}>
            <Container>
                <ActivityTimeline itemType={"project"} itemId={project.id}/>
            </Container>
        </TabPanel>
    )
}