import {Alert, Avatar, Box, Button, CircularProgress, Container, Grid, Typography,} from "@mui/material";
import SetPassword from "../components/SetPassword";
import {useEffect, useState} from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {green} from "@mui/material/colors";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {resetPassword} from "../../features/auth/authActions";
import {selectIsAuthenticated} from "../../features/auth/authSlice";

export default function ResetPassword() {
    const {key} = useParams();
    const [password, setPassword] = useState("");
    const [valid, setValid] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const dispatch = useDispatch();
    const {loading, isSuccess, isError, error} = useSelector(state => state.auth);

    useEffect(() => {
        if (isSuccess['resetPassword']) {
            enqueueSnackbar(
                t("msg.notif.reset_password"),
                {variant: "success"}
            );
            navigate("/sign-in");
        }
    }, [isSuccess])

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(resetPassword({key, password}));
    };

    return isAuthenticated ? <Navigate to={"/account/security"} replace /> : (
        <Container component="main" maxWidth="xs" sx={{mt: 20}}>
            {isError['resetPassword'] ? (
                <Alert severity="error" variant={"outlined"}>
                    {error}
                </Alert>
            ) : (
                ""
            )}
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t("reset_password.title")}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <Grid container spacing={2}>
                        <SetPassword
                            onValidPassword={(p, isValid) => {
                                setPassword(p);
                                setValid(isValid);
                            }}
                        />
                    </Grid>
                    <Box sx={{m: 1, position: "relative"}}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            disabled={!valid || loading}
                        >
                            {t("button.reset_password")}
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-8px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}
