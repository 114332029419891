import {Box, Button, Card, CardActions, CardContent, Grid, Step, StepLabel, Stepper, Typography,} from "@mui/material";
import {useState} from "react";
import ProjectDetailsStep from "../components/addproject/ProjectDetailsStep";
import ProjectEstimatesStep from "../components/addproject/ProjectEstimatesStep";
import SummaryReviewStep from "../components/addproject/SummaryReviewStep";
import moment from "moment";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import {useAddProjectMutation} from "../../features/projects/projectSlice";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../features/auth/authSlice";

export default function AddProject() {
    const user = useSelector(selectCurrentUser);
    const [formInvalid, setFormInvalid] = useState(true);
    const [validSteps, setValidSteps] = useState([false, true, true]);
    const [activeStep, setActiveStep] = useState(0);
    const [project, setProject] = useState({
        name: "",
        description: "",
        deadline: moment(),
        priceRequested: 0.0,
        estimatedHours: 0,
        currency: user.currency,
        client: null,
    });
    const navigate = useNavigate();

    const {t} = useTranslation(["project", "error"]);

    const [addProject, {isLoading, isError, error}] =
        useAddProjectMutation("addProject");

    const populateProjectModel = (model) => {
        let filled = {...project, ...model};
        setProject(filled);
    };

    const persistProject = async () => {
        const payload = {
            name: project.name,
            description: project.description,
            deadline: project.deadline.utc(),
            priceRequested: project.priceRequested,
            estimatedHours: project.estimatedHours,
            currency: project.currency,
            client: project.client ? project.client.id : null,
        };

        await addProject(payload).unwrap().then(d => {
            navigate("/projects");
        })
            .catch(error => {
                enqueueSnackbar(t(`error:${error.data.message}`), {
                    variant: "error", preventDuplicate: true
                });

            });
    };

    const steps = [
        {
            key: "details",
            label: t("step.label.details"),
        },
        {
            key: "estimates",
            label: t("step.label.estimates"),
        },
        {
            key: "review",
            label: t("step.label.review"),
        },
    ];

    const updateStepValidity = (index, isValid) => {
        let overallValid = !formInvalid;
        let upd = validSteps.map((val, idx) => {
            if (idx === index) {
                overallValid = overallValid && !isValid;
                return isValid;
            }
            overallValid = overallValid && !val;
            return val;
        });
        setValidSteps(upd);
        setFormInvalid(overallValid);
    };

    const isStepValid = (idx) => {
        return validSteps[idx];
    };

    const stepContent = [
        <ProjectDetailsStep
            model={project}
            onDataChange={populateProjectModel}
            onValidityChange={(isValid) => updateStepValidity(0, isValid)}
        />,
        <ProjectEstimatesStep
            project={project}
            onDataChange={populateProjectModel}
        />,
        <SummaryReviewStep project={project}/>,
    ];

    return (
        <>
            <Box>
                <Typography variant="h4">{t("page.title")}</Typography>
            </Box>
            <Box sx={{mt: 5}}>
                <Stepper activeStep={activeStep}>
                    {steps.map((step, index) => {
                        let labelProps = {};
                        return (
                            <Step index={index} key={step.key}>
                                <StepLabel {...labelProps}>{step.label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                <Box component={"div"} sx={{mt: 5}}>
                    <Card>
                        <CardContent>{stepContent[activeStep]}</CardContent>
                        <CardActions>
                            <Grid container spacing={2} maxWidth={"xl"}>
                                <Grid item xs={6} textAlign={"left"}>
                                    {activeStep > 0 ? (
                                        <Button
                                            onClick={() => setActiveStep(activeStep - 1)}
                                            color={"secondary"}
                                        >
                                            {t("button.previous")}
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </Grid>
                                <Grid item xs={6} textAlign={"right"}>
                                    {activeStep < stepContent.length - 1 ? (
                                        <Button
                                            onClick={() => setActiveStep(activeStep + 1)}
                                            disabled={!isStepValid(activeStep)}
                                        >
                                            {t("button.next")}
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                    {activeStep === stepContent.length - 1 ? (
                                        <LoadingButton
                                            loading={isLoading}
                                            variant={"contained"}
                                            color={"primary"}
                                            disabled={formInvalid && !isLoading}
                                            onClick={persistProject}
                                        >
                                            {t("button.create")}
                                        </LoadingButton>
                                    ) : (
                                        ""
                                    )}
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Box>
            </Box>
        </>
    );
}
