import { apiSlice } from "../api/apiSlice";

export const clientSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query({
      query: () => "/clients",
      providesTags: (result, error, arg) => [
        { type: "CLIENT", id: "LIST" },
        ...result.map((client) => ({ type: "CLIENT", id: client.id })),
      ],
    }),
    getClient: builder.query({
      query: (id) => ({
        url: `/clients/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        error ? {} : { type: "CLIENT", id: result.id },
      ],
    }),
    addClient: builder.mutation({
      query: (client) => ({
        url: "/clients",
        method: "POST",
        body: { ...client },
      }),
      invalidatesTags: [{ type: "CLIENT", id: "LIST" }],
    }),

    updateClient: builder.mutation({
      query: ({ clientId, payload }) => ({
        url: `/clients/${clientId}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CLIENT", id: "LIST" },
        {type: "ACTIVITY"},
        error ? {} : { type: "CLIENT", id: result.id },
      ],
    }),

    deleteClient: builder.mutation({
      query: (id) => ({
        url: `/clients/${id}`,
        method: "DELETE",
        body: {},
        extraOptions: { maxRetries: 0 },
      }),
      invalidatesTags: (result) => [
        { type: "CLIENT", id: "LIST" },
        { type: "CLIENT", id: result.id },
        {type: "ACTIVITY"},
      ],
    }),

    addContact: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/clients/${id}/contacts`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CLIENT", id: "LIST" },
        { type: "CLIENT", id: arg.id },
        {type: "ACTIVITY"},
      ],
    }),

    updateContact: builder.mutation({
      query: ({ clientId, contactId, payload }) => ({
        url: `/clients/${clientId}/contacts/${contactId}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CLIENT", id: "LIST" },
        { type: "CLIENT", id: arg.clientId },
        {type: "ACTIVITY"},
      ],
    }),

    deleteContact: builder.mutation({
      query: ({ clientId, contactId }) => ({
        url: `/clients/${clientId}/contacts/${contactId}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CLIENT", id: "LIST" },
        { type: "CLIENT", id: arg.clientId },
        {type: "ACTIVITY"},
      ],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useAddContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
} = clientSlice;
