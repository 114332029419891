import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import AppRouter from "./AppRouter";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en.json";
import ro from "javascript-time-ago/locale/ro.json";
import Maintenance from "./Maintenance";
import "./i18n";
import { Provider } from "react-redux";
import { store } from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ro);
if (process.env.REACT_APP_MAINTENANCE_MODE) {
  root.render(<Maintenance />);
} else {
  root.render(
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}
