import { Box, Typography } from "@mui/material";
import { useGetExpensesGroupBySupplierQuery } from "../aggregates/aggregateSlice";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/authSlice";

export default function SupplierReports() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { data, isLoading, isSuccess } = useGetExpensesGroupBySupplierQuery();
  const [plottableData, setPlottableData] = useState([]);
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!isLoading && isSuccess && data.length > 0) {
      setPlottableData([
        ...data
          .map((d) => {
            return { supplier: d.supplier.name, value: d.amount };
          })
          .sort((a, b) => b.value - a.value),
      ]);
    }
  }, [data, isLoading, isSuccess]);

  let chart = {
    options: {
      chart: {
        id: "expense-bar-chart",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      theme: {
        palette: "palette1", // upto palette10
        mode: theme.palette.mode,
      },
      tooltip: {
        y: {
          formatter: (value, opts) => {
            return `${value} ${user.currency}`;
          },
        },
      },
      xaxis: {
        categories: plottableData.map((d) => d.supplier),
      },
    },
    series: [
      {
        name: t("supplier:chart.series.name"),
        data:
          plottableData.length === 0
            ? []
            : plottableData.map((e) => e.value.toFixed(2)),
      },
    ],
  };
  return (
    <>
      <Box maxWidth={"lg"} sx={{ mt: 2 }}>
        <Typography variant="h6">{t("supplier:chart.series.name")}</Typography>
        <Chart
          type={"bar"}
          height={"300"}
          options={chart.options}
          series={chart.series}
        />
      </Box>
    </>
  );
}
