import {
    Alert,
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    Grid,
    Link,
    TextField,
    Typography,
} from "@mui/material";
import Copyright from "../components/Copyright";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PasswordField from "../components/PasswordField";
import {useEffect, useMemo, useState} from "react";
import {Link as RouterLink, Navigate, useLocation, useNavigate} from "react-router-dom";
import {green} from "@mui/material/colors";
import ForgotPasswordDialog from "../components/ForgotPasswordDialog";
import {useTranslation} from "react-i18next";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import {login} from "../../features/auth/authActions";
import {useDispatch, useSelector} from "react-redux";
import {selectIsAuthenticated} from "../../features/auth/authSlice";

export default function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation(['translation'], 'error');
    const { loading, userInfo, success, error } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const location = useLocation();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const [errorSnackKey, setErrorSnackKey] = useState("");

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const formValid = useMemo(() => {
        return email.length > 0 && password.length > 0;
    }, [email, password]);

    useEffect(() => {
        if (success) {
            if (errorSnackKey) {
                closeSnackbar(errorSnackKey);
                setErrorSnackKey("");
            }
            enqueueSnackbar(t('msg.notif.signin_ok', {user: userInfo.firstName}), {variant: "success"});
            if (location.state?.from) {
                navigate(location.state.from);
            } else {
                navigate("/home");
            }
        } else if (error) {
            let key = enqueueSnackbar(t(`error:${error.data.message}`), {variant: "error", preventDuplicate: true, hideIconVariant: false});
            setErrorSnackKey(key);
        }
    }, [success, error])

    const handleSubmit = async (e) => {
        e.preventDefault();
        await dispatch(login({email, password, rememberMe}));
    };

    return isAuthenticated ? <Navigate to={"/account"} replace /> :  (
        <Container component="main" maxWidth="xs" sx={{mt: 20}}>
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t("button.signin")}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t("input.email")}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <PasswordField
                        name="password"
                        label={t("input.password", "Password")}
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <FormControlLabel
                        control={<Checkbox value={rememberMe} onChange={e => {
                            setRememberMe(e.target.checked)
                        }}  color="primary"/>}
                        label={t('input.remember_me')}
                    />
                    <Box sx={{m: 1, position: "relative"}}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            disabled={loading || !formValid}
                        >
                            {t("button.signin")}
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-8px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Box>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2" onClick={handleClickOpen}>
                                {t("link.forgot_password", "Forgot password?")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/sign-up" variant="body2" component={RouterLink}>
                                {t("link.no_account_sign_up", "Don't have an account? Sign Up")}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{mt: 8, mb: 4}}/>
            {open ? (
                <ForgotPasswordDialog
                    initialEmail={email}
                    visible={open}
                    handleClose={() => setOpen(false)}
                />
            ) : (
                ""
            )}
        </Container>
    );
}
