import {useTranslation} from "react-i18next";
import {useGetAllSuppliersQuery} from "./supplierSlice";
import {Autocomplete, CircularProgress, createFilterOptions, Portal, TextField, Typography} from "@mui/material";
import {useState} from "react";
import AddSupplierDialog from "./AddSupplierDialog";
import Loading from "../../mui/pages/Loading";

export default function SupplierAutoCompleteMenu({initialValue, onChange, name, dialogContainer}) {
    const {t} = useTranslation();
    const {data: suppliers = [], isLoading} = useGetAllSuppliersQuery();
    const filter = createFilterOptions();
    const [open, setOpen] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [supplierName, setSupplierName] = useState("");
    const [value, setValue] = useState(initialValue);

    if (isLoading) {
        return <Loading />;
    }
    return (
        <>
            <Autocomplete
                name={name}
                id="supplier-selector"
                noOptionsText={t("supplier:dd.no.options.text")}
                fullWidth
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id || option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={suppliers}
                loading={isLoading}
                selectOnFocus
                clearOnBlur
                clearOnEscape
                handleHomeEndKeys
                freeSolo
                value={value}
                onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            setSupplierName(newValue.inputValue);
                            setShowDialog(true);
                        });
                    } else if (newValue && newValue.inputValue) {
                        setSupplierName(newValue.inputValue);
                        setShowDialog(true);
                    } else {
                        onChange(newValue);
                        setValue(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== "") {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: `${t("client:autocomplete.add.label")} "${
                                params.inputValue
                            }"`,
                        });
                    }

                    return filtered;
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("supplier:label", {count: 1})}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? (
                                        <CircularProgress color="inherit" size={20}/>
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            {showDialog ? (<AddSupplierDialog name={supplierName} open={showDialog}
                handleClose={(supplier) => {
                    setShowDialog(false);
                    setValue(supplier);
                    onChange(supplier);
                }}/>)
                :
                ""
            }
        </>
    );
}