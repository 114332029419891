import {ListItem, ListItemText} from "@mui/material";

export default function DividedListItem({primary, secondary, last}) {
    const hasDivider = last === undefined || last === null || last === false;
    return (
        <ListItem divider={hasDivider}>
            <ListItemText primary={primary} secondary={secondary}/>
        </ListItem>
    );
}
