import { apiSlice } from "../api/apiSlice";

export const notesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotes: builder.query({
      query: ({ type, itemId }) => `/notes?type=${type}&itemId=${itemId}`,
      providesTags: (response, error, arg) => [
        { type: "NOTES", id: `LIST-${arg.type}-${arg.itemId}` },
      ],
    }),
    addNote: builder.mutation({
      query: (payload) => ({
        url: "/notes",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "NOTES", id: `LIST-${arg.itemType}-${arg.itemId}` },
        {type: "ACTIVITY"},
      ],
    }),
    editNote: builder.mutation({
      query: ({id, payload}) => ({
        url: `/notes/${id}`,
        method: 'PUT',
        body:payload
      }),
      invalidatesTags: (result, error, arg) => [
        result
            ? { type: "NOTES", id: `LIST-${result.itemType}-${result.itemId}` }
            : {},
        {type: "ACTIVITY"}
      ],
    }),
    deleteNote: builder.mutation({
      query: (id) => ({
        url: `/notes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        result
          ? { type: "NOTES", id: `LIST-${result.itemType}-${result.itemId}` }
          : {},
        {type: "ACTIVITY"},
      ],
    }),
  }),
});

export const { useGetNotesQuery, useAddNoteMutation, useEditNoteMutation, useDeleteNoteMutation } =
  notesSlice;
