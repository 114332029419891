import DividedListItem from "../../mui/components/DividedListItem";
import {Trans, useTranslation} from "react-i18next";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Link,
    List,
    ListItem, ListItemText,
    Stack,
    Typography
} from "@mui/material";
import {useGetActivityForUserQuery} from "./activitySlice";
import {Link as RouterLink} from "react-router-dom";
import RecentUserActivityItem from "./RecentUserActivityItem";

export default function RecentUserActivity({count}) {
    const {t} = useTranslation();
    const {data: items = [], isLoading, isSuccess} = useGetActivityForUserQuery(count);
    let listItems = "";
    if (!isLoading && isSuccess) {
        listItems = items.length > 0 ?                         items.map(it => {
            return <RecentUserActivityItem item={it} key={it.id}/>
        } ) : <ListItem><ListItemText primary={t("event:home.activity.none")} /></ListItem>
    }

    const eventContent = {
        "PROJECT_ASSIGNED": (type, payload) => {
            const name = payload.name
            return (<Typography>
                <Trans t={t} i18nKey={"client:activity.project_assigned"}>
                    Proiectul <Link component={RouterLink} to={`/projects/${payload.id}`}>{{name}}</Link> a fost inceput
                </Trans>
            </Typography>)
        },
        "PROJECT_UNASSIGNED": (type, payload) => {
            const name = payload.name
            return (<Typography>
                <Trans t={t} i18nKey={"client:activity.project_unassigned"}>
                    Proiectul <Link component={RouterLink} to={`/projects/${payload.id}`}>{{name}}</Link> a fost inceput
                </Trans>
            </Typography>)
        },
        "CREATE": (type, payload) => {
            return <Typography>{t(`${type.toLowerCase()}:home.activity.create`, payload)}</Typography>
        },
        "DELETE": (type, payload) => {
            return <Typography>{t(`${type.toLowerCase()}:home.activity.delete`, payload)}</Typography>
        },
        "UPDATE": (type, payload) => {
            return (<Stack>
                <Typography>{t(`${type.toLowerCase()}:activity.update`)}</Typography>
                {payload && Object.keys(payload).length > 0 ?
                    (
                        payload.map(it => <Typography key={it.field} variant={"body2"}>
                            {t(`${type.toLowerCase()}:label.${it.field}`)} : {t('event:updatedDiff', {
                            from: it.old,
                            to: it.new
                        })}</Typography>)
                    )
                    : ""}
            </Stack>)
        }
    }
    return (
        <Card>
            <CardHeader title={t("home.activity.widget.title")}/>
            <CardContent>
                <List>
                    {listItems}
                </List>
            </CardContent>
            {/*<CardActions>*/}
            {/*    <Button>Arată tot istoricul</Button>*/}
            {/*</CardActions>*/}
        </Card>);
}