import {Navigate, useRouteError, Link as RouterLink} from 'react-router-dom';
import {Box, Container, Link, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";


export default function GenericNotFound() {
    const error = useRouteError();
    const {t} = useTranslation(["error"])
    if (error.status === 401) {
        return <Navigate to={"/"} replace/>
    }
    return <Container sx={{mt: 15}}>
        <Box textAlign={"center"}>
            <Stack spacing={5}>
                <Typography variant={"h2"}>{t("404.title")}</Typography>
                <Typography variant={"h6"}>{t("404.subtitle")}</Typography>
                <Typography variant={"h6"}><Link to={"/"} component={RouterLink}>{t("404.cta")}</Link></Typography>
            </Stack>
        </Box>
    </Container>;
}
