import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAddContactMutation } from "./clientSlice";

export default function AddContactCard({ clientId }) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [addContact, isLoading] = useAddContactMutation();

  const startEdit = () => {
    setEditMode(true);
  };

  const persist = async () => {
    await addContact({
      id: clientId,
      payload: { name, email, phoneNo },
    });
    setEditMode(false);
  };

  return (
    <Card sx={{ borderRadius: 6 }}>
      <CardContent>
        {!editMode ? (
          <Box textAlign={"center"}>
            <CardActionArea onClick={startEdit}>
              <Typography variant="h1">+</Typography>
              <Typography variant="h6">
                {t("client:cta.add.contact")}
              </Typography>
            </CardActionArea>
          </Box>
        ) : (
          <Stack spacing={2}>
            <TextField
              size="small"
              variant="filled"
              label={t("client:label.name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              size="small"
              variant="filled"
              label={t("client:label.email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              size="small"
              variant="filled"
              label={t("client:label.phone")}
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
            />
          </Stack>
        )}
      </CardContent>
      {editMode === true ? (
        <CardActions sx={{ ml: 1 }}>
          <Button size="small" onClick={(e) => setEditMode(false)}>
            {t("button.cancel")}
          </Button>
          <Button size="small" onClick={persist}>
            {t("button.save")}
          </Button>
        </CardActions>
      ) : (
        ""
      )}
    </Card>
  );
}
