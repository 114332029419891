import {
  AppBar,
  Box,
  Button,
  Container,
  Link,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import Brand from "./Brand";
import WorkControl from "./timer/WorkControl";
import LanguageSwitcher from "./LanguageSwitcher";
import NotificationMenu from "../../features/notifications/NotificationMenu";
import UserMenu from "./UserMenu";
import DarkModeMenu from "./DarkModeMenu";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export default function ApplicationTopBar() {
  const { t } = useTranslation();
  const pages = [
    {
      id: "projects",
      label: t("menu.user.projects"),
      url: "/projects",
    },
    {
      id: "clients",
      label: t("menu.user.clients"),
      url: "/clients",
    },
    {
      id: "suppliers",
      label: t("menu.user.suppliers"),
      url: "/suppliers",
    },
  ];

  return (
    <AppBar
      color="default"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Container maxWidth={"xl"}>
        <Toolbar disableGutters>
          <Brand />
          <Stack
            spacing={2}
            direction={"row"}
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              ml: 2,
              pt: 1,
            }}
          >
            {pages.map((page) => (
              <Button component={RouterLink} key={page.id} to={page.url}>
                {page.label}
              </Button>
            ))}
          </Stack>

          <LanguageSwitcher />
          <WorkControl />
          <NotificationMenu />
          <DarkModeMenu />
          <UserMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
