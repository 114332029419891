import { useTheme } from "@emotion/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAddClientMutation } from "./clientSlice";
import AnafPoweredCuiField from "./AnafPoweredCuiField";
import { enqueueSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";

export default function AddClientForm({ initialName = "", open, handleClose }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [addClient, { isLoading }] = useAddClientMutation("addClient");

  const [type, setType] = useState(null);
  const [managed, setManaged] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isDirty, isValid, errors },
  } = useForm({
    defaultValues: {
      name: initialName,
      type: "",
      address: "",
      cui: "",
      recom: "",
      contactName: "",
      email: "",
      phone: "",
    },
  });

  const onClose = (data) => {
    setType("");
    reset();
    handleClose(data);
  };

  const doSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(persist)();
  }

  const persist = async (data) => {
    if (isLoading || !isValid || !isDirty) {
      return;
    }

    const payload = {
      name: data.name,
      type: data.type,
      cui: data.cui && data.cui.length > 0 ? data.cui : null,
      recom: data.recom && data.recom.length > 0 ? data.recom : null,
      address: data.address && data.address.length > 0 ? data.address : null,
      email: data.email && data.email.length > 0 ? data.email : null,
      phoneNo: data.phone && data.phone.length > 0 ? data.phone : null,
      contactName: data.contactName.length > 0 ? data.contactName : data.name,
    };
    await addClient(payload)
      .unwrap()
      .then((data) => {
        onClose(data);
      })
      .catch((error) => {
        if (error.status === 400) {
          enqueueSnackbar(t(`error:${error.data.message}`), {
            variant: "error",
            preventDuplicate: true,
          });
        } else {
          enqueueSnackbar(t("error:data.save.generic"), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      });
  };

  const onRemoteDataReceived = (value) => {
    setValue("name", value.name, { shouldValidate: true });
    setValue("address", value.address);
    setValue("recom", value.recom);
    setValue("email", "", { shouldValidate: true });
    setManaged(value.managed);
  };

  const isEmailValid = (value, formValues) => {
    if (formValues.type === "") {
      return true;
    }
    if (value.length === 0) {
      if (formValues.type === "INDIVIDUAL") {
        return false;
      } else if (
        formValues.type === "COMPANY" &&
        formValues.contactName.length === 0
      ) {
        return true;
      }
    }
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(value);
  };

  const commonFields = (
    <>
      <Controller
        name="name"
        control={control}
        rules={{
          required: { value: true, message: t("error:field.error.required") },
          validate: (value) =>
            !!value.trim() || t("error:field.error.required"),
        }}
        render={({ field: { name, value, onChange, onBlur } }) => (
          <TextField
            fullWidth
            required
            label={t("client:label.name")}
            disabled={managed}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!errors["name"]}
            helperText={errors["name"] ? errors["name"].message : ""}
          />
        )}
      />
      <Controller
        name="address"
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <TextField
            multiline={true}
            fullWidth
            label={t("client:label.address")}
            name={name}
            value={value}
            onChange={onChange}
            disabled={managed}
          />
        )}
      />
    </>
  );

  const contactMeansFields = (
    <>
      <Controller
        name="email"
        control={control}
        rules={{
          validate: (value, formValues) =>
            isEmailValid(value, formValues) || t("error:field.error.email"),
        }}
        render={({ field: { name, value, onChange, onBlur } }) => (
          <TextField
            fullWidth
            type="email"
            label={t("client:label.email")}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!errors["email"]}
            helperText={errors["email"] ? errors["email"].message : ""}
          />
        )}
      />

      <Controller
        name="phone"
        control={control}
        render={({ field: { name, value, onChange, onBlur } }) => (
          <TextField
            fullWidth
            label={t("client:label.phone")}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!errors["phone"]}
            helperText={errors["phone"] ? errors["phone"].message : ""}
          />
        )}
      />
    </>
  );

  let body = <></>;

  if (type === "INDIVIDUAL") {
    body = (
      <>
        {commonFields}
        {contactMeansFields}
      </>
    );
  } else if (type === "COMPANY") {
    body = (
      <>
        <Controller
          name="cui"
          control={control}
          render={({ field: { name, value, onChange, onBlur } }) => (
            <AnafPoweredCuiField
              initialValue={value}
              onChange={(val) => {
                onChange(val);
              }}
              onDataReceived={(val) => onRemoteDataReceived(val)}
            />
          )}
        />
        {commonFields}
        <Controller
          name="recom"
          control={control}
          render={({ field: { name, value, onChange, onBlur } }) => (
            <TextField
              fullWidth
              label={t("client:label.recom")}
              value={value}
              name={name}
              onChange={onChange}
              disabled={managed}
            />
          )}
        />

        <Divider>{t("client:label.contact.primary")}</Divider>
        <Controller
          name="contactName"
          control={control}
          render={({ field: { name, value, onChange, onBlur } }) => (
            <TextField
              fullWidth
              label={t("client:label.contact.name")}
              name={name}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {contactMeansFields}
      </>
    );
  }

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
    >
      <DialogTitle>{t("client:dialog.add.title")}</DialogTitle>
      <form noValidate onSubmit={doSubmit}>
        <DialogContent>
          <Stack spacing={2}>
            <FormControl component="fieldset">
              <FormLabel id={"client-type-rg"} component="legend">
                {t("client:label.type")}
              </FormLabel>
              <Controller
                rules={{ required: true }}
                control={control}
                name="type"
                render={({ field: { name, value, onChange } }) => (
                  <RadioGroup
                    row={true}
                    aria-labelledby="client-type-rg"
                    name={name}
                    value={value}
                    onChange={(e) => {
                      setType(e.target.value);
                      onChange(e);
                    }}
                  >
                    <FormControlLabel
                      value="INDIVIDUAL"
                      control={<Radio />}
                      label={t("client:INDIVIDUAL")}
                    />
                    <FormControlLabel
                      value="COMPANY"
                      control={<Radio />}
                      label={t("client:COMPANY")}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
            {body}
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{t("translation:button.cancel")}</Button>
          <Button disabled={!isDirty || isLoading} type="submit">
            {t("translation:button.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
