import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import AppWrapper from "./mui/layouts/AppWrapper";
import HomePage from "./mui/pages/HomePage";
import UnprotectedLayout from "./mui/layouts/UnprotectedLayout";
import SignUp from "./mui/pages/SignUp";
import SignIn from "./mui/pages/SignIn";
import ProtectedLayout from "./mui/layouts/ProtectedLayout";
import GenericNotFound from "./mui/pages/GenericNotFound";
import Logout from "./mui/pages/Logout";
import ResetPassword from "./mui/pages/ResetPassword";
import VerifyEmail from "./mui/pages/VerifyEmail";
import Account from "./mui/pages/Account";
import AddProject from "./mui/pages/AddProject";
import ProjectDetails from "./mui/pages/ProjectDetails";
import Projects from "./mui/pages/Projects";
import Dashboard from "./mui/pages/Dashboard";
import AccountGeneralPanel from "./mui/components/account/AccountGeneralPanel";
import AccountUpdatePasswordPanel from "./mui/components/account/AccountUpdatePasswordPanel";
import AccountPreferencesPanel from "./mui/components/account/AccountPreferencesPanel";
import SuperUserPanel from "./mui/components/account/SuperUserPanel";
import BasicDetailsTabPanel from "./mui/components/project_details/BasicDetailsTabPanel";
import ProjectCollectionsPanel from "./features/income/ProjectCollectionsPanel";
import ProjectTimeLogPanel from "./features/timelog/ProjectTimeLogPanel";
import ReleasePageJuly from "./mui/pages/ReleasePageJuly";
import ClientList from "./features/clients/ClientList";
import ClientSingleView from "./features/clients/ClientSingleView";
import ProjectExpensesPanel from "./features/expenses/ProjectExpensesPanel";
import ReleasePageSeptember from "./mui/pages/ReleasePageSeptember";
import ClientDetailsTab from "./features/clients/tabs/ClientDetailsTab";
import ClientActivityTab from "./features/clients/tabs/ClientActivityTab";
import ClientNotesTab from "./features/clients/tabs/ClientNotesTab";
import ProjectNotesPanel from "./features/projects/ProjectNotesPanel";
import ProjectActivityPanel from "./features/projects/ProjectActivityPanel";
import SupplierDetails from "./features/supplier/SupplierDetails";
import SupplierList from "./features/supplier/SupplierList";
import PrivateSupplierList from "./features/supplier/PrivateSupplierList";
import SupplierReports from "./features/supplier/SupplierReports";

export default function AppRouter() {
  const appRouter = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppWrapper />} errorElement={<GenericNotFound />}>
        <Route element={<UnprotectedLayout />}>
          <Route path={"/"} element={<HomePage />} />
          <Route path={"/sign-up"} element={<SignUp />} />
          <Route path={"/sign-in"} element={<SignIn />} />
          <Route path={"/reset/:key"} element={<ResetPassword />} />
          <Route path={"/verify/:key"} element={<VerifyEmail />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path={"/home"} element={<Dashboard />} />
          <Route path={"/logout"} element={<Logout />} />
          <Route path={"/account"} element={<Account />}>
            <Route index element={<AccountGeneralPanel />} />
            <Route path={"security"} element={<AccountUpdatePasswordPanel />} />
            <Route path={"preferences"} element={<AccountPreferencesPanel />} />
            <Route path={"superuser"} element={<SuperUserPanel />} />
          </Route>
          <Route path={"/projects"} element={<Projects />} />
          <Route path={"/projects/add"} element={<AddProject />} />
          <Route path={"/projects/:projectId"} element={<ProjectDetails />}>
            <Route index element={<BasicDetailsTabPanel />} />
            <Route
              path={"/projects/:projectId/expenses"}
              element={<ProjectExpensesPanel />}
            />
            <Route
              path={"/projects/:projectId/income"}
              element={<ProjectCollectionsPanel />}
            />
            <Route
              path={"/projects/:projectId/time"}
              element={<ProjectTimeLogPanel />}
            />
            <Route
              path={"/projects/:projectId/notes"}
              element={<ProjectNotesPanel />}
            />
            <Route
              path={"/projects/:projectId/activity"}
              element={<ProjectActivityPanel />}
            />
          </Route>
          <Route path={"/clients"} element={<ClientList />} />
          <Route path={"/clients/:clientId"} element={<ClientSingleView />}>
            <Route index element={<ClientDetailsTab />} />
            <Route
              path={"/clients/:clientId/activity"}
              element={<ClientActivityTab />}
            />
            <Route
              path={"/clients/:clientId/notes"}
              element={<ClientNotesTab />}
            />
          </Route>
          <Route
            path={"/suppliers/:supplierId"}
            element={<SupplierDetails />}
          />
          <Route path={"/suppliers"} element={<SupplierList />}>
            <Route index element={<PrivateSupplierList />} />
            <Route path={"/suppliers/reports"} element={<SupplierReports />} />
          </Route>
          <Route
            path={"/releases/july-2023-multilang-notifications"}
            element={<ReleasePageJuly />}
          />
          <Route
            path={"/releases/september-2023-client-management"}
            element={<ReleasePageSeptember />}
          />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={appRouter} />;
}
