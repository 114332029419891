import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import CustomStatus from "./CustomStatus";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useAddProjectStatusMutation } from "./projectStatusSlice";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";

export default function CustomStatuses({ statuses }) {
  const { t } = useTranslation(["account", "translation", "project", "error"]);
  const [showAddDialog, setShowAddDialog] = useState(false);

  const [addProjectStatus, { isLoading }] = useAddProjectStatusMutation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      status: "",
    },
  });

  const onClose = () => {
    setShowAddDialog(false);
    reset();
  };

  const onSubmit = async (data) => {
    let statusValue = data.status.trim().replaceAll(" ", "_").toUpperCase();
    let payload = {
      statusValue: statusValue,
      label: data.status.trim(),
    };
    if (statuses.find((s) => s.statusValue === statusValue)) {
      enqueueSnackbar(t("error:DUPLICATE_STATUS"), {
        variant: "error",
        preventDuplicate: true,
      });
      return;
    }
    await addProjectStatus(payload)
      .unwrap()
      .then((data) => {
        onClose();
      })
      .catch((error) => {
        if (
          error.status === 400 &&
          error.data &&
          error.data.message === "INVALID_FIELD"
        ) {
          enqueueSnackbar(t(`error:${error.data.message}`), {
            variant: "error",
            preventDuplicate: true,
          });
        } else {
          enqueueSnackbar(t(`error:${error.data.message}`), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      });
  };

  return (
    <Stack direction={"row"} spacing={1} sx={{ mt: 1 }}>
      {statuses.map((status) => (
        <CustomStatus key={status.id} status={status} />
      ))}
      <Tooltip title={"Add a custom status"}>
        <Chip
          color={"primary"}
          icon={<AddIcon />}
          label={t("button.add")}
          onClick={(e) => setShowAddDialog(true)}
        />
      </Tooltip>
      <Dialog open={showAddDialog} onClose={onClose}>
        <DialogTitle>{t("add.dialog.title")}</DialogTitle>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>{t("add.dialog.body")}</DialogContentText>
            <Controller
              control={control}
              name="status"
              rules={{
                required: {
                  value: true,
                  message: t("error:field.error.required"),
                },
                validate: (value) =>
                  !!value.trim() || t("error:field.error.required"),
              }}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <TextField
                  autoFocus
                  margin="dense"
                  label={t("project:fields.status.label")}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  type="text"
                  fullWidth
                  variant="standard"
                  error={!!errors["status"]}
                  helperText={errors["status"] ? errors["status"].message : ""}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t("translation:button.cancel")}</Button>
            <LoadingButton
              loading={isLoading}
              disabled={!isValid}
              type="submit"
            >
              {t("translation:button.save")}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Stack>
  );
}
