import {Container, Typography} from "@mui/material";
import moment from "moment";
import {useTranslation} from "react-i18next";

export default function SummaryReviewStep({project}) {
    const {t} = useTranslation("project");

    return (
        <Container>
            <Typography variant={"h6"}>{project.name}</Typography>
            <p>
                {project.description}
            </p>
            <p>
                {project.client ? project.client.name : t("fields.client.nodata")}
            </p>
            <hr/>
            <Typography variant={"h6"}>{t("review.title.estimates")}</Typography>
            <p>{t("review.items.due")}: {moment(project.deadline).format('DD-MMM-YYYY')}</p>
            <p>{t("reviews.items.price")}: {project.priceRequested} {project.currency}</p>
            <p>{t("reviews.items.time", {count: Number(project.estimatedHours)})}</p>
        </Container>
    )

}