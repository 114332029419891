import {Badge, Box, Divider, Grid, IconButton, Menu, MenuList, Tooltip, Typography} from "@mui/material";
import {DoneAllOutlined, NotificationsNoneOutlined} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Notification from "./Notification";
import {useNavigate} from "react-router-dom";
import {enqueueSnackbar} from "notistack";
import {
    useClearNotificationMutation,
    useClearNotificationsMutation,
    useGetNotificationsQuery
} from "./notificationSlice";

export default function NotificationMenu() {
    const {t} = useTranslation(["translation", "tooltips", "error"]);

    const navigate = useNavigate();
    const {data: notifications = [], isLoading, isError} = useGetNotificationsQuery();
    const [clearNotifications, {isError: isClearAllError, error: clearAllError}] = useClearNotificationsMutation();
    const [clearNotification, {isError: isClearError}] = useClearNotificationMutation();

    // Menu options

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => {
        if (notifications.length > 0) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const markAllRead = async () => {
        handleCloseMenu();
        await clearNotifications();
    }

    const handleClick = async (notification) => {
        navigate(notification.url);
        handleCloseMenu();
        await clearNotification(notification.id)
    }

    // Initial load of notifications
    useEffect(() => {
        if (isClearAllError) {
            enqueueSnackbar(t("error:notifications.markall.failure"), {variant: "error", preventDuplicate: true});
        }

        if (isClearError) {
            enqueueSnackbar(t("error:notifications.markread.failure"), {variant: "error"})
        }
    }, [isClearAllError, isClearError]);

    return (
        <Box sx={{flexGrow: 0}}>
            <IconButton disabled={notifications.length === 0} onClick={handleOpenMenu}>
                {notifications.length === 0 ? <NotificationsNoneOutlined/> :
                    <Badge variant={"dot"} badgeContent={""} color={"primary"}><NotificationsNoneOutlined/></Badge>}
            </IconButton>
            <Menu sx={{mt: "45px"}}
                  id="menu-notifications"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  MenuListProps={{onMouseLeave: handleCloseMenu}}
            >
                <Box sx={{padding: "12px 16px"}}>
                    <Grid container spacing={4}>
                        <Grid item md={6} xs={6}>
                            <Typography variant={"h6"}>{t("notifications.title")}</Typography>
                        </Grid>
                        <Grid item md={6} xs={6} textAlign={"right"}>
                            <Tooltip title={t("tooltips:notifications.action.markallread")}>
                                <IconButton onClick={markAllRead}><DoneAllOutlined color={"primary"}
                                                                                   fontSize={"small"}/></IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <Divider/>
                <MenuList>
                    {notifications.map(n => <Notification key={n.id} notification={n} onClick={handleClick}/>)}
                </MenuList>
            </Menu>
        </Box>
    );
}