import { apiSlice } from "../api/apiSlice";

export const projectSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: () => "/projects",
      providesTags: (result, error, arg) => [
        { type: "PROJECT", id: "LIST" },
        ...result.map((prj) => ({ type: "PROJECT", id: prj.id })),
      ],
    }),
    getProjectsForClient: builder.query({
      query: (clientId) => `/projects?clientId=${clientId}`,
      providesTags: (result, error, arg) => [
        { type: "CLIENT_PROJECTS", id: arg },
      ],
    }),
    getProject: builder.query({
      query: (id) => ({
        url: `/projects/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: "PROJECT", id: result.id },
      ],
    }),
    addProject: builder.mutation({
      query: (project) => ({
        url: `/projects`,
        method: "POST",
        body: project,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "PROJECT", id: "LIST" },
        { type: "PROJECT", id: result.id },
        { type: "CLIENT_PROJECTS", id: result.client?.id },
        {type: "ACTIVITY"},
      ],
    }),
    editProject: builder.mutation({
      query: ({ id, project }) => ({
        url: `/projects/${id}`,
        method: "PUT",
        body: project,
      }),
      invalidatesTags: (result) => [
        { type: "PROJECT", id: "LIST" },
        { type: "PROJECT", id: result.id },
        { type: "CLIENT_PROJECTS", id: result.client?.id },
        { type: "AGGREGATE", id: `CLIENT-${result.client?.id}` },
        {type: "ACTIVITY"},
      ],
    }),
    deleteProject: builder.mutation({
      query: (id) => ({
        url: `/projects/${id}`,
        method: "DELETE",
        body: {},
        extraOptions: { maxRetries: 0 },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "PROJECT", id: "LIST" },
        { type: "PROJECT", id: arg.id },
        { type: "CLIENT_PROJECTS", id: result.client?.id },
        { type: "AGGREGATE", id: `CLIENT-${result.client?.id}` },
        {type: "ACTIVITY"},
      ],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectsForClientQuery,
  useGetProjectQuery,
  useAddProjectMutation,
  useEditProjectMutation,
  useDeleteProjectMutation,
} = projectSlice;
