import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useGetProjectStatusesQuery } from "./projectStatusSlice";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import CustomStatuses from "./CustomStatuses";

export default function StatusPreferenceSection() {
  const { t } = useTranslation(["account", "translation", "project"]);
  const [enableCustom, setEnableCustom] = useState(false);

  const {
    data: statuses = [],
    isLoading,
    isSuccess,
  } = useGetProjectStatusesQuery();

  const customStatuses = statuses.filter((it) => it.type === "CUSTOM");
  const hasCustom = customStatuses.length > 0;

  const toggleCustomStatusArea = () => {
    if (customStatuses.length > 0) {
      return; // don't do anything if we already have custom statuses in the list
    } else {
      setEnableCustom(!enableCustom);
    }
  };

  return (
    <>
      <Grid item xs={12} md={4}>
        <Typography component={"h1"} variant={"h6"}>
          {t("section.statuses.title")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Stack>
          <Typography variant={"h6"}>{t("statuses.default")} </Typography>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            {statuses
              .filter((s) => s.type === "DEFAULT")
              .map((s) => (
                <Grid item key={s.id}>
                  <Chip
                    label={t(
                      `project:project.status.${s.statusValue}`,
                      s.label
                    )}
                    color={"primary"}
                  />
                </Grid>
              ))}
          </Grid>
          <Box sx={{ mt: 2 }}>
            {isLoading ? (
              <Skeleton variant={"text"} />
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasCustom || enableCustom}
                    onChange={toggleCustomStatusArea}
                    disabled={hasCustom}
                  />
                }
                label={t("statuses.input.custom")}
              />
            )}
            {hasCustom || enableCustom ? (
              <CustomStatuses statuses={customStatuses} />
            ) : (
              ""
            )}
          </Box>
        </Stack>
      </Grid>
    </>
  );
}
