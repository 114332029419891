import {Grid, Link, ListItem, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ReactTimeAgo from "react-time-ago";
import i18n from "i18next";
import {Link as ReactLink} from "react-router-dom";
import moment from "moment";

export default function RecentUserActivityItem({item}) {
    const {t} = useTranslation();
    const payload = item.payload ? JSON.parse(item.payload) : {};

    const buildEventLink = (event) => {
        switch (event.itemType) {
            case "CLIENT": return `/clients/${event.itemId}/activity`
            case "PROJECT": return `/projects/${event.itemId}/activity`
            case "EXPENSE": return `/projects/${event.itemParentId}/expenses`
            case "INCOME": return `/projects/${event.itemParentId}/income`
            case "TIMELOG": return `/projects/${event.itemParentId}/time`
            case "NOTE": return `/${event.itemParentType.toLowerCase()}s/${event.itemParentId}/notes`
            case "SUPPLIER": return `/suppliers/${event.itemId}`
            default: return "/home"
        }
    }

    return (
        <ListItem divider>
            <Grid container spacing={2} columnSpacing={2}>
                <Grid item xs={9} >
                    <Stack>
                        <Typography variant={"subtitle1"}>{`${t(`event:home.${item.eventType.toString()}`)} ${t(`event:type.${item.itemType.toString()}`)}`}</Typography>
                        <Link component={ReactLink} to={buildEventLink(item)} color="inherit" underline="hover">
                            <Typography variant={"subtitle2"}>
                                {t(`event:home.activity.${item.itemType.toLowerCase()}.${item.eventType.toLowerCase()}`, payload)}
                            </Typography>
                        </Link>
                    </Stack>

                </Grid>
                <Grid item xs={3}>
                    <Typography variant={"caption"}>
                        <ReactTimeAgo date={moment.utc(item.createdAt).local().valueOf()} locale={i18n.resolvedLanguage} />
                    </Typography>
                </Grid>
            </Grid>
        </ListItem>
    )
}