import { useTheme } from "@emotion/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import AnafPoweredCuiField from "./AnafPoweredCuiField";
import { useUpdateClientMutation } from "./clientSlice";
import { enqueueSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";

export default function EditClientForm({ client, open, handleClose }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const defaultContact =
    client.type === "INDIVIDUAL"
      ? client.contacts[0]
      : { email: "", phoneNo: "" };
  const [managed, setManaged] = useState(false);

  const [updateClient, { isLoading, isError }] = useUpdateClientMutation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, errors, isDirty },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: client.name,
      cui: client.cui || "",
      recom: client.recom || "",
      address: client.address || "",
      email: defaultContact.email || "",
      phone: defaultContact.phoneNo || "",
    },
  });

  const canSave = isDirty && isValid;

  const persist = async (data) => {
    await updateClient({
      clientId: client.id,
      payload: {
        name: data.name,
        cui: data.cui && data.cui.length > 0 ? data.cui : null,
        recom: data.recom && data.recom.length > 0 ? data.recom : null,
        address: data.address && data.address.length > 0 ? data.address : null,
        email: data.email && data.email.length > 0 ? data.email : null,
        phoneNo: data.phone && data.phone.length > 0 ? data.phone : null,
      },
    })
      .unwrap()
      .then((data) => {
        handleClose();
      })
      .catch((error) => {
        if (error.status === 400) {
          enqueueSnackbar(t(`error:${error.data.message}`), {
            variant: "error",
            preventDuplicate: true,
          });
        } else {
          enqueueSnackbar(t("error:data.save.generic"), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      });
  };

  const onRemoteDataReceived = (value) => {
    setValue("name", value.name, { shouldValidate: true });
    setValue("address", value.address, { shouldValidate: true });
    setValue("recom", value.recom, { shouldValidate: true });
    setManaged(value.managed);
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        {t("client:dialog.edit.title", { name: client.name })}
      </DialogTitle>
      <form noValidate onSubmit={handleSubmit(persist)}>
        <DialogContent>
          <Stack spacing={4} sx={{ mt: 1 }}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t("error:field.error.required"),
                },
                validate: (value) =>
                  !!value.trim() || t("error:field.error.required"),
              }}
              render={({ field: { name, value, onChange, onBlur } }) => (
                <TextField
                  fullWidth
                  label={t("client:label.name")}
                  value={value}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={managed}
                  error={!!errors["name"]}
                  helperText={errors["name"] ? errors["name"].message : ""}
                />
              )}
            />

            <Controller
              name="address"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  multiline={true}
                  fullWidth
                  label={t("client:label.address")}
                  name={name}
                  value={value}
                  onChange={onChange}
                  disabled={managed}
                />
              )}
            />

            {client.type === "INDIVIDUAL" ? (
              <>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      fullWidth
                      type="email"
                      label={t("client:label.email")}
                      name={name}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      fullWidth
                      label={t("client:label.phone")}
                      name={name}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </>
            ) : (
              <>
                <Controller
                  name="cui"
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <AnafPoweredCuiField
                      initialValue={value}
                      value={value}
                      onChange={onChange}
                      onDataReceived={(val) => onRemoteDataReceived(val)}
                    />
                  )}
                />

                <Controller
                  name="recom"
                  control={control}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      fullWidth
                      label={t("client:label.recom")}
                      name={name}
                      value={value}
                      onChange={onChange}
                      disabled={managed}
                    />
                  )}
                />
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t("translation:button.cancel")}
          </Button>
          <Button disabled={!canSave || isLoading} type="submit">
            {t("translation:button.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
