import {apiSlice} from "../api/apiSlice";

export const notificationSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query({
            query: () => "/notifications",
            providesTags: [{type: "NOTIFICATION", id: "LIST"}]
        }),
        clearNotifications: builder.mutation({
            query: () => ({
                url: "/notifications",
                method: "DELETE"
            }),
            invalidatesTags: [{type: "NOTIFICATION", id: "LIST"}]
        }),
        clearNotification: builder.mutation({
                query: (id) => ({
                    url: `/notifications/${id}`,
                    method: "DELETE"
                }),
            invalidatesTags: [{type: "NOTIFICATION", id: "LIST"}]
            }
        )
    })
});

export const {
    useGetNotificationsQuery,
    useClearNotificationsMutation,
    useClearNotificationMutation
} = notificationSlice;