import {Box, Tab, Tabs, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link, Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../features/auth/authSlice";

export default function Account() {
    const user = useSelector(selectCurrentUser);
    const {t} = useTranslation(['account', 'translation']);

    const currentPath = useLocation().pathname.toLowerCase();

    let tabs = [
        {
            label: t("tab.general.label"),
            value: 0,
            path: "/account"
        },
        {
            label: t("tab.security.label"),
            value: 1,
            path: "/account/security"
        },
        {
            label: t("tab.preferences.label"),
            value: 2,
            path: "/account/preferences"
        }
    ];

    if (user.role === "ADMIN") {
        tabs.push({label: "SuperUser", value: 3, path: "/account/superuser"})
    }

    const matchingTab = tabs.find(t => t.path.toLowerCase() === currentPath)
    const currentValue = matchingTab ? matchingTab.value : 0;

    return (
        <>
            <Box>
                <Typography variant="h4">{t("settings.page.title")}</Typography>
            </Box>
            <Box sx={{mt: 5, borderBottom: 1, borderColor: "divider"}}>
                <Tabs
                    aria-label="nav tabs example"
                    indicatorColor="primary"
                    value={currentValue}
                    variant={"scrollable"}
                    scrollButtons={true}
                    allowScrollButtonsMobile
                >
                    {tabs.map(t => <Tab key={t.value} label={t.label} value={t.value} to={t.path} component={Link}/>)}
                </Tabs>
            </Box>

            <Outlet/>
        </>
    );
}
