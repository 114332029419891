import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";
import { useRouteMatch } from "../../utils";

export default function SupplierList() {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t("supplier:tab.list.label"),
      value: "list",
      route: "/suppliers",
      path: "/suppliers",
      disabled: false,
    },
    {
      label: t("supplier:tab.list.reports"),
      value: "reports",
      route: "/suppliers/reports",
      path: "/suppliers/reports",
      disabled: false,
    },
  ];

  const routeMatch = useRouteMatch(["/suppliers/reports", "/suppliers"]);
  const currentVal = routeMatch?.pattern?.path;
  const matchingTab = tabs.find((t) => t.route === currentVal);
  const tab = matchingTab.value;

  return (
    <>
      <Box>
        <Typography variant="h4">{t("supplier:page.list.title")}</Typography>
      </Box>
      <Box sx={{ mt: 5, borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          aria-label="project details"
          indicatorColor="primary"
          value={tab}
          variant={"scrollable"}
          scrollButtons={true}
          allowScrollButtonsMobile
        >
          {tabs.map((t) => (
            <Tab
              key={t.value}
              label={t.label}
              value={t.value}
              to={t.path}
              component={Link}
              disabled={t.disabled}
            />
          ))}
        </Tabs>
        <Box>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
