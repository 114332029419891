import {Link, Link as RouterLink, Navigate, useNavigate, useSearchParams} from "react-router-dom";
import Copyright from "../components/Copyright";
import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    CssBaseline,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import SetPassword from "../components/SetPassword";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {useEffect, useMemo, useState} from "react";
import {green} from "@mui/material/colors";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {register} from "../../features/auth/authActions";
import {selectIsAuthenticated} from "../../features/auth/authSlice";

export default function SignUp() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [acceptTos, setAcceptTos] = useState(false);
    const [valid, setValid] = useState(false);

    const navigate = useNavigate();
    const {t} = useTranslation(["translation", "error"]);

    const [searchParams, setSearchParams] = useSearchParams();
    const inviteKey = searchParams.get("invite");
    const dispatch = useDispatch();
    const {loading, success, error} = useSelector((state) => state.auth);
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const isFormValid = useMemo(() => {
        let isValid =
            firstName.length > 0 &&
            lastName.length > 0 &&
            email.length > 0 &&
            valid === true &&
            acceptTos === true;
        return isValid;
    }, [firstName, lastName, email, acceptTos, valid]);

    const handleSubmit = async (e) => {
        e.preventDefault();


        let payload = {
            email: email,
            firstName: firstName,
            lastName: lastName,
            password: password,
            acceptedTos: acceptTos,
            preferredLanguage: i18n.resolvedLanguage.toUpperCase()
        };

        dispatch(register({payload, inviteKey}));
    };

    useEffect(() => {
        if (success) {
            enqueueSnackbar(t('msg.notif.account_created'), {variant: "success"});
            navigate("/home");
        }
        if (error) {
            enqueueSnackbar(t(`error:${error}`), {variant: "error", preventDuplicate: true});
        }
    }, [success, error])

    return isAuthenticated ? <Navigate to={"/account"} replace /> : (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            {error ? (
                <Alert severity="error" variant={"outlined"}>
                    <AlertTitle>{error.title}</AlertTitle>
                    {error.content}
                </Alert>
            ) : (
                ""
            )}

            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{m: 1, bgcolor: "success.main"}}>
                    <LockOutlinedIcon/>
                </Avatar>

                <Typography component="h1" variant="h5">
                    {t('button.signup')}
                </Typography>

                <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label={t('input.first_name')}
                                autoFocus
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label={t('input.last_name')}
                                name="lastName"
                                autoComplete="family-name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label={t('input.email')}
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <SetPassword
                            onValidPassword={(p, isValid) => {
                                setPassword(p);
                                setValid(isValid);
                            }}
                        />
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={acceptTos}
                                        onChange={(e) => setAcceptTos(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label={t('input.accept_tos')}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{m: 1, position: "relative"}}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            disabled={!isFormValid || loading}
                        >
                            {t('button.signup')}
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-8px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Box>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link to="/sign-in" variant="body2" component={RouterLink}>
                                {t('link.have_account')}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{mt: 5}}/>
        </Container>
    );
}
