import {Box, Card, CardActionArea, CardContent, Stack, Tooltip, Typography} from "@mui/material";
import CircularProgressWithLabel from "../CircularProgressWithLabel";
import {useTheme} from "@emotion/react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function AmountUsedCard({title, unit, used, total, url}) {
    const {t} = useTranslation(["tooltips"])
    const theme = useTheme()
    let percentageUsed = (used / total) * 100;
    const happyColor = theme.palette.success.main
    // "rgb(87, 202, 34)";
    const warningColor = theme.palette.warning.main
    //"rgb(255, 163, 25)"
    const errorColor = theme.palette.error.main
    //"rgb(255, 25, 67)"

    let color = "success";
    let borderColor = happyColor
    if (percentageUsed > 50 && percentageUsed < 75) {
        color = "warning"
        borderColor = warningColor;
    } else if (percentageUsed > 75) {
        color = "error"
        borderColor = errorColor;
    }

    return (
        <Card sx={{
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            borderBottom: `5px solid ${borderColor}`,
            borderRadius: "10px",
        }}>
            <CardActionArea component={Link} to={url}>
                <CardContent>
                    <Tooltip title={`${t("tooltips:estimated.amount")}: ${total} ${unit}`}>
                        <Stack direction={"row"} spacing={4}>
                            <Box>
                                <Typography variant={"h6"} component={"div"}>{title}</Typography>
                                <Typography variant={"h5"} component={"div"}>{unit}
                                    <Typography variant={"h3"}
                                                component={"span"}> {used ? used.toFixed(2) : 0.0}</Typography>
                                </Typography>
                            </Box>
                            <Box>

                                <CircularProgressWithLabel value={percentageUsed} color={color}/>

                            </Box>
                        </Stack>
                    </Tooltip>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}