import {Visibility, VisibilityOff} from "@mui/icons-material";
import {IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import {useState} from "react";
import {useTranslation} from "react-i18next";

export default function PasswordField({id, name, label, value, onChange}) {
    const [showPassword, setShowPassword] = useState(false);
    const {t} = useTranslation(['tooltips']);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <TextField
            required
            fullWidth
            name={name}
            label={label}
            type={showPassword ? "text" : "password"}
            id={id}
            autoComplete="new-password"
            value={value}
            onChange={onChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? (<Tooltip title={t("password.hide")}><VisibilityOff/></Tooltip>) : (
                                <Tooltip title={t("password.show")}><Visibility/></Tooltip>)}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}
