import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    TextField,
    useMediaQuery
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@emotion/react";
import {Controller, useForm} from "react-hook-form";
import {useState} from "react";
import {useAddSupplierMutation} from "./supplierSlice";
import AnafPoweredCuiField from "../clients/AnafPoweredCuiField";
import {enqueueSnackbar} from "notistack";

export default function AddSupplierDialog({open, handleClose, name}) {
    const {t} = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [addSupplier, {isLoading}] = useAddSupplierMutation();
    const [managed, setManaged] = useState(false);

    const {
        handleSubmit: handleSubmitNewSupplier,
        control: newSupplierControl,
        reset,
        setValue,
        formState: {isValid, errors},
    } = useForm({
        defaultValues: {
            name: name,
            address: "",
            cui: "",
            recom: "",
            email: "",
            phone: "",
            website: ""
        },
    });

    const onRemoteDataReceived = (value) => {
        setValue("name", value.name, {shouldValidate: true});
        setValue("address", value.address);
        setValue("recom", value.recom);
        setValue("email", "", {shouldValidate: true});
        setValue("cui", value.cui, {shouldValidate: true})
        setValue("phone", value.phone, {shouldValidate: true})
        setManaged(value.managed);
    };

    const onClose = () => {
        reset();
        handleClose();
    }

    const persist = async (data) => {
        const payload = {
            name: data.name,
            type: data.cui && data.cui.trim().length > 0 ? "COMPANY" : "INDIVIDUAL",
            cui: data.cui && data.cui.length > 0 ? data.cui : null,
            recom: data.recom && data.recom.length > 0 ? data.recom : null,
            address: data.address && data.address.length > 0 ? data.address : null,
            email: data.email && data.email.length > 0 ? data.email : null,
            phone: data.phone && data.phone.length > 0 ? data.phone : null,
            website: data.website && data.website.length > 0 ? data.website : null,
        }
        await addSupplier(payload).unwrap()
            .then(supplier => {
                const ret = {id: supplier.id, name: supplier.name}
                handleClose(ret)
            })
            .catch(err => {
                enqueueSnackbar(t(`error:${err.data.message}`), {variant: "error", preventDuplicate: true});
            })
    }


    const isEmailValid = (value, formValues) => {
        if (value.length === 0) {
            return true;
        }
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(value);
    };

    const doSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmitNewSupplier(persist)();
    }

    return (<>
        <Dialog maxWidth={"md"}
                fullWidth
                open={open}
                onClose={onClose}
                fullScreen={fullScreen}>
            <DialogTitle>{t("client:dialog.add.title")}</DialogTitle>
            <form key={"supplierAddForm"} noValidate onSubmit={doSubmit}>
                <DialogContent>
                    <Stack spacing={2}>
                        {managed ? <Alert severity={"info"}>{t("supplier:public.warning")}</Alert> : ""}
                        <Controller
                            name="cui"
                            control={newSupplierControl}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <AnafPoweredCuiField
                                    initialValue={value}
                                    onChange={(val) => {
                                        onChange(val);
                                    }}
                                    onDataReceived={(val) => onRemoteDataReceived(val)}
                                />
                            )}
                        />
                        <Controller
                            name="name"
                            control={newSupplierControl}
                            rules={{
                                required: {value: true, message: t("error:field.error.required")},
                                validate: (value) =>
                                    !!value.trim() || t("error:field.error.required"),
                            }}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <TextField
                                    fullWidth
                                    required
                                    label={t("client:label.name")}
                                    disabled={managed}
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    error={!!errors["name"]}
                                    helperText={errors["name"] ? errors["name"].message : ""}
                                />
                            )}
                        />
                        <Divider title={"Optional"} variant={"middle"}>{t("supplier:optional")}</Divider>
                        <Controller
                            name="address"
                            control={newSupplierControl}
                            render={({field: {name, value, onChange}}) => (
                                <TextField
                                    multiline={true}
                                    fullWidth
                                    label={t("client:label.address")}
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    disabled={managed}
                                />
                            )}
                        />
                        <Controller
                            name="email"
                            control={newSupplierControl}
                            rules={{
                                validate: (value, formValues) =>
                                    isEmailValid(value, formValues) || t("error:field.error.email"),
                            }}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <TextField
                                    fullWidth
                                    type="email"
                                    label={t("client:label.email")}
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    error={!!errors["email"]}
                                    helperText={errors["email"] ? errors["email"].message : ""}
                                />
                            )}
                        />

                        <Controller
                            name="phone"
                            control={newSupplierControl}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <TextField
                                    fullWidth
                                    label={t("client:label.phone")}
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    error={!!errors["phone"]}
                                    helperText={errors["phone"] ? errors["phone"].message : ""}
                                />
                            )}
                        />
                        <Controller
                            name="website"
                            control={newSupplierControl}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <TextField
                                    fullWidth
                                    label={t("supplier:label.website", "Website")}
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    error={!!errors["phone"]}
                                    helperText={errors["phone"] ? errors["phone"].message : ""}
                                />
                            )}
                        />
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>{t("translation:button.cancel")}</Button>
                    <Button disabled={!isValid || isLoading} type="submit">
                        {t("translation:button.save")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog></>)
}